import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Spin, Upload, message } from "antd";
import type { RcFile, UploadChangeParam, UploadFile, UploadListType, UploadProps } from 'antd/es/upload/interface';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../../../utils/firebase";
// import { storage } from "../../../utils/firebase";

type Props = {
  name: string
  uploadText: string
  help?: string
  defaultFileList?: UploadFile,
  listType?: UploadListType,
  style?: React.CSSProperties
  className?: string
  accept?: string

  /** Route used for upload */
  uploadUrl: string
  /** Max file size in MB. */
  maxSize: number,
  storageUrl:string,
  reset:boolean,
  cover?:boolean,
  onSuccess?: () => void,
  file?:boolean
  rules?:any[]

}

export default function UploadFormImage({ accept = 'image/svg+xml,image/png,image/jpeg,image/jpg', ...props }: Props) {
  const form = Form.useFormInstance()
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState<{ visible: boolean, url: string | undefined }>({
    visible: false,
    url: undefined
  })
  const [errorMessage, setErrorMessage] = useState<string>()
  const [fileList, setFileList] = useState<UploadFile[]>(props.defaultFileList ? [props.defaultFileList] : []);

  useEffect(() => setErrorMessage(props.help), [props.help])
  useEffect(() => {
    if(props.defaultFileList){
        setPreview({visible:true, url:props.defaultFileList.url})
    }
  }, [props.defaultFileList])

  useEffect(() => {
    if(props.reset){
        setPreview({visible:false, url:''})
        setFileList([])
    }
  }, [props.reset])
  
  

  const beforeUpload = (file: RcFile) => {
    const isValidFile = accept.split(',')
      .includes(file.type)

    if (!isValidFile) {
      message.error('You can only upload JPG/PNG/SVG file!');
    }
    const isLt3M = file.size / 1024 / 1024 < props.maxSize;
    if (!isLt3M) {
      message.error(`Image must smaller than ${props.maxSize}MB!`);
    }

    if (!isValidFile || !isLt3M) {
      setFileList([])
      form.setFieldValue(props.name, null)
    }

    return isValidFile && isLt3M;
  }

  const handlePreview = async (file: UploadFile) => {
    setPreview({
      visible: true,
      url: file.url ? file.url : file.response.upload.file.url
    })
  }

  const handleRemove = (file: UploadFile<any>) => {
    setFileList([])
  }



  const customUpload = async ({ onError, onSuccess, file }: any) => {
    setPreview({visible:false, url:''})
    setLoading(true)
    const imageRef= ref(storage, props.storageUrl)
     try {
      const uploadRef = await uploadBytes(imageRef, file)
      const url =  await getDownloadURL(uploadRef.ref)
   
      setPreview({visible:false, url})
      form.setFieldValue(props.name, url)
      onSuccess(url);
      props.onSuccess && props.onSuccess()
    } catch(e) {
      console.log(e)
      setErrorMessage('something went very wrong!')
    }
    setLoading(false)
  };

 
  return (
    <Form.Item
      name={props.name}
      validateStatus={"error"}
      help={errorMessage}
      style={{
        marginBottom:0
      }}
      className={'w-full h-full'}
      rules={props.rules ? props.rules : []}
      
    >
 <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => prevValues[props.name] !== curValues[props.name]}
            >
      {({ getFieldValue, setFieldsValue }) => (
          <Upload
            name="file"
            listType={props.listType}
            fileList={[]}
            onPreview={handlePreview}
            action=''
            headers={{
              "Accept": "application/json",
            }}
            beforeUpload={beforeUpload}
            onRemove={handleRemove}
            multiple={false}
            maxCount={1}
            accept={accept}
            customRequest={customUpload}
            className="w-full h-full"
            
            
          >
           
          {!preview.url && !getFieldValue(props.name) ?
            (
              props.listType && props.listType === 'picture-card'
                ? (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>{props.uploadText}</div>
                  </div>
                )
                : <Button className="w-full bg-white rounded" icon={<UploadOutlined />}>{props.uploadText}</Button>
            ) : (
              props.file ? (
                  <div className="items-center justify-center flex text-center">
                    <p>file</p>
                  </div>
              ) : (
                <img className={`w-full h-full ${props.cover && 'object-cover'}`} src={getFieldValue(props.name)}/>

              )
            )
            
          }
          </Upload>

      )}

            </Form.Item>
      </Form.Item>
  )
}