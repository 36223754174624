import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { usePublishContext } from './context/publishContext';
import Header from './components/Header';
import { PublicationTypes } from '../../types';
import { Modal } from 'antd';

// type Props = {
//   type:PublicationTypes&{groupe:{id:string, title:{en:string, fr?:string}, iconUrl:string}}
// }
export default function PublishLayout() {
  const { setEditData, setType, setInitId, setActive, setisLoaded } = usePublishContext()
  const location = useLocation()
  const [open, setopen] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    
    setType(location.state?.type)
    setopen(true)
    setisLoaded(true)
  }, [location.state]);



  
  return (
    <Modal
      visible={open}
      footer={null}
      onCancel={() => navigate('/mydesk')}
      width={'100%'}
      style={{
        height:'100%',
        top:0
      }}
    
    >
      <div className='flex-1 flex flex-col h-full'>
          <Header/>
          <div className="flex-1">
              <Outlet/>
          </div>
      </div>

    </Modal>
  )
}
