import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Server } from '../../../../../types';
import ServerWithCheck from '../../../components/ServerWithCheck';
import { getDomainsAndFeaturedDcs } from '../../../../../services/api/request/network';
import WithDivider from '../../../../../components/shared/WithDivider';
import SingleServerLine from '../../../components/SingleServerLine';
import PublishInFooter from '../../../components/PublishInFooter';
import useNavigateTo from '../../../../hooks/useNvigateTo';
export default function PublishIn() {
    const [domains, setdomains] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
    const {goTo} = useNavigateTo()
    const params = useParams()
    const location = useLocation()
    useEffect(() => {
        setloading(true)
        fetch(getDomainsAndFeaturedDcs).then(res => res.json()).then(data => setdomains(data.data)).finally(() => setloading(false))

        
    }, [])
    const onClick = (network:Server) => {
        
        goTo(`/publish/publishin/${network.id}`, {state: {...location.state,network:network.title}})
    }
  return (
        <div className="h-full">
            <div className="grid grid-cols-5 bar-h pl-[35px]">
                <WithDivider>
                    <p>Networks</p>
                </WithDivider>
                <WithDivider>
                    <p>Servers</p>
                </WithDivider>
                <WithDivider>
                    <p>Drives</p>
                </WithDivider>
                <WithDivider>
                    <p>Directories</p>
                </WithDivider>
                <WithDivider>
                    <p>Folders</p>
                </WithDivider>
            </div>
            <div className='flex flex-col h-full'>
                <div className="pl-[35px] grid grid-cols-5 border-main border-b">
                    <Spin spinning={loading}>
                        <div className='height-publishin overflow-auto pl-3 space-y-7'>
                            {domains?.map(domain => (
                                <div key={domain.id} className='space-y-2'>
                                    <p className='text-group'>{domain.title}</p>
                                    <div className="-ml-2">
                                        {domain.children?.map(network => (
                                            <SingleServerLine item={network} key={network.id} active={network.id === params.networkId} clickFn={() => onClick(network)}/>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </Spin>
                    <div className="col-span-4">
                        <Outlet/>
                    </div>
                </div>
                <div className="flex-1">
                    <PublishInFooter/>

                </div>
            </div>
        </div>
  )
}
