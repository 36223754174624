import React, { useEffect, useState } from 'react'

export default function useUpdateTree({volumes, featurePublications, featureVolumes} : { volumes:any, featurePublications: boolean, featureVolumes: boolean}) {
    const [tree, settree] = useState<any>([]);

    useEffect(() => {
        
        settree(
            [{
                title: 'Pinned',
                iconUrl: 'Pin',
                iconType: 'icon',
                key:'/pin',
                selectable:false,
                disabled:true
            },
            {
                title: 'Shared',
                iconUrl: 'Share',
                iconType: 'icon',
                key:'/share',
                selectable:false,
                disabled:true,
                children:[
                    {
                        title: 'Maamar B',
                        iconUrl: 'Card',
                        iconType: 'icon',
                        key: '/share/mb',
                        disabled:true
                    },
                    {
                        title: 'Chahinez K',
                        iconUrl: 'Card',
                        iconType: 'icon',
                        key: '/share/ck',
                        disabled:true
                    },
                    {
                        title: 'Razane M',
                        iconUrl: 'Card',
                        iconType: 'icon',
                        key: '/share/rm',
                        disabled:true
                    },
                    {
                        title: 'Mohammed G',
                        iconUrl: 'Card',
                        iconType: 'icon',
                        key: '/share/mg',
                        disabled:true
                    },
                ]
            },
            {
                title: 'Contacts',
                iconUrl: 'MyContacts',
                iconType: 'icon',
                key:'/contacts',
                disabled:true
            },
            {
                title: 'My volumes',
                iconUrl: 'LocalTree',
                iconType: 'icon', 
                key:'/my-volumes',
                disabled: !featureVolumes,
                children: volumes,
                id:'my-volumes'
            },
            {
                title: 'Publications',
                iconUrl: 'UserFolder',
                iconType: 'icon',
                key:'/publications',
                selectable:false,
                disabled: !featurePublications,
                id:'publications',
                children: [
                    {
                        title: 'New',
                        iconUrl: 'Edit',
                        iconType: 'icon',
                        key:'',
                        disabled: !featurePublications,
                    },
                    {
                        title: 'Draft',
                        iconUrl: 'Draft',
                        iconType: 'icon',
                        key:'/publications/draft',
                        disabled: !featurePublications,
                    },
                    {
                        title: 'Published',
                        iconUrl: 'Publish',
                        iconType: 'icon',
                        key:'/publications/published',
                        disabled: !featurePublications,
                    },
                    {
                        title: 'Trash',
                        iconUrl: 'Trash',
                        iconType: 'icon',
                        key:'/publications/trash',
                        disabled: !featurePublications,
                    }
                ]
            },
            {
                title: 'My activities',
                iconUrl: 'Document',
                iconType: 'icon',
                key:'/activities',
                selectable:false,
                id:'activities',
                children: [
                    {
                    title: 'Stats',
                    iconUrl: 'Stats',
                    iconType: 'icon',
                    key:'/activities/stats',
                    },
                    {
                    title: 'Business card',
                    iconUrl: 'Card',
                    iconType: 'icon',
                    key:'/activities/card',
                    },
                    {
                    title: 'Messages',
                    iconUrl: 'Chat',
                    iconType: 'icon',
                    key:'/activities/messages',
                    }
                ]
            },
            {
                title: 'My account',
                iconUrl: 'Document',
                iconType: 'icon',
                key:'/account',
                selectable:false,
                id:'acount',
                children: [
                    {
                        title: 'Account settings',
                        iconUrl: 'Document',
                        key:'/account/settings',
                        iconType: 'icon',
                    },
                
                    {
                        title: "Organization's settings",
                        iconUrl: 'Document',
                        key:'/account/organization',
                        iconType: 'icon',
                    },
                    {
                        title: 'Subscription',
                        iconUrl: 'Document',
                        key:'/account/subscription',
                        iconType: 'icon',
                    }
                ]
            }]
        )
    }, [volumes, featurePublications, featureVolumes]);
  return {
    tree
  }
}
