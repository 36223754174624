import React, { useEffect } from 'react'
import { useMydeskContext } from './context/MydeskContext'
import { MydeskBreadcrumb, MydeskeBreadcrumbType } from '../components/shared/bars/TopBar/Pathbar/breadcrumbs'

export default function useSetBreadcrumb(props:MydeskeBreadcrumbType) {
    const { setBreadcrumb } = useMydeskContext()
    useEffect(() => {
      setBreadcrumb(MydeskBreadcrumb(props))
    }, [props.datacenter?.id, props?.type])
  return {

  }
}
 