import { createRoot } from 'react-dom/client';
import { BrowserRouter, HashRouter, Navigate, Route, Router, Routes } from 'react-router-dom';

import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import FilesNavigation from './Qfs/Routings';
import LoginRoutings from './login/Routings';
import MydeskNavigation from './C-Mydesk/Routings';
import { AppContextProvider } from './context/AppContext';
import GlobalLayout from './GlobalLayout';
import StatsNavigation from './Stats/Routings';
import { AuthContextProvider } from './context/AuthContext';
import CheckAuthRoute from './components/CheckAuth';
import PrivateRoute from './components/PrivateRoute';



declare global {
  interface Window {
    electron: any;
  }
}
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <BrowserRouter>
      <Routes>
          <Route element={<AppContextProvider>
            <AuthContextProvider>
            
              <GlobalLayout/>
            </AuthContextProvider>
            </AppContextProvider>}>
            <Route
              element={<CheckAuthRoute/>}
            >
             <Route path="/connect/*" element={<LoginRoutings/>} />
             <Route element={<PrivateRoute/>}>
              <Route path='/mydesk/*' element={<MydeskNavigation/>}/>
             </Route>
             <Route path='/stats/*' element={<StatsNavigation/>}/>
              <Route path='/*' element={<FilesNavigation/>}/>

            </Route>
          
          </Route>




      







          {/* </Route> */}





      </Routes>

    </BrowserRouter>


);
