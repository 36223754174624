import React, { useCallback, useState } from 'react'
import WithSidebar from '../../components/shared/Layouts/WithSidebar'
import { useUIContext } from '../context/UIContext'
import Sidebar from '../components/sidebar'
import { Server, VolumeSearch } from '../../types'
import useNavigateTo from '../hooks/useNavigateTo'
import { useQfsContext } from '../context/qfsContext'
import { useAppContext } from '../../context/AppContext'
import { generateSearchVolumKey } from '../../utils/utils'

type Props = {
    domain: Server|null,
    serverId: string | undefined,
    handleLocationSelection: (node:any)=> void,
    handleSearchSelection:(doc:VolumeSearch) => void,
    selectedKey: string

}
function SidebarComp({handleLocationSelection, handleSearchSelection, domain, serverId, selectedKey}: Props){
    const [tree, settree] = useState<Server[] | null>([]);
    const [serverTree, setserverTree] = useState<Server[] | null>([]);
    return(
        <Sidebar
        selectedKey={selectedKey}
        handleLocationSelection={handleLocationSelection}
        handleSearchSelection={handleSearchSelection}
        openAction={({ key, title, icon }) => {
      
        }}

        datacenter={domain}
        tree={tree}
        settree={settree}
        serverTree={serverTree}
        setserverTree={setserverTree}
        serverId={serverId} //from currentloc, move domain to app?
    />
    )
}
export default function SidebarContainer({children}:{children:JSX.Element}) {
    const { hidePanel } = useUIContext()
    const [sideBarWidth, setsideBarWidth] = useState(340);
    const { currentLocation } = useQfsContext()
    const { domain } = useAppContext()

    const {goTo} = useNavigateTo()

    const handleLocationSelection = useCallback(
        (node: Server) => {
        goTo(node.path, {});
        },
        [goTo]
    );

    const handleSearchSelection = useCallback(
        (doc:VolumeSearch) => {
            goTo(generateSearchVolumKey(doc), {});
        },
        [goTo]
    );
    
  return (
    <>
    { (domain) && (
        <WithSidebar
            setWidth={setsideBarWidth}
            sidebar={
                    <SidebarComp
                     handleLocationSelection={handleLocationSelection}
                     handleSearchSelection={handleSearchSelection}
                     selectedKey={currentLocation ? (currentLocation.isDomain ? '/' : currentLocation.id) : '/'}
                     serverId={currentLocation?.clusterId}
                     domain={domain}
                    />

            }
            hideSidebar={false}
        >
              <div className={`flex flex-col transition-all bg-sub-windows z-100 relative ${hidePanel && `shadow-sidebar-opened  `}`} style={{marginLeft: hidePanel ? -sideBarWidth+60 : 0}}>
                    {children}

              </div>
        </WithSidebar>

    )}
    
    </>
  )
}
