import React from 'react'

import Icon from '../../../../icons/Icon'
import { Close } from '../../../../icons'



type TopBarprops = {
    actions:{hide?: () => void, expand?: () => void, close: () => void},
    nodraggable?:boolean,
    extra?:JSX.Element,
    moduleDescription?:any,
    main?:boolean
  }
export default function TopBarIcons({ moduleDescription,extra, actions, main} : TopBarprops) {
  return (
        
    <div className='flex items-center  '>
      {extra && extra}
     
  
       {main && <div className='w-[1px] h-[15px] mr-4 bg-white opacity-[0.2]'></div>}
      <div className="flex items-center space-x-2">
        {main && (
        <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center disabled:opacity-[0.2]' disabled>
              <Icon name='Notification' className='icon-sm'/>
            </button>

        )}

        
        
            <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center disabled:opacity-[0.2]' disabled>
              <Icon name='Help' className='icon-sm'/>
            </button>

        
      </div>
      <div className='w-[1px] h-[15px] mx-4 bg-white opacity-[0.2]'></div>

      <div className="flex items-center space-x-2">
    
       
        
          <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center'>
          <Icon name='Reduce' className='icon-sm'/>
        </button>

    
        {!main && (
          <button className='w-[24px] h-[24px] rounded bg-hover-transparent no-dragable flex items-center justify-center'>
          <Icon name='Open' className='icon-sm'/>
        </button>

        )}
   
        <button className='cursor-pointer no-dragable hover:bg-teddra-red hover:text-white hover:fill-white w-[24px] h-[24px] border-transparent flex justify-center items-center rounded' onClick={actions.close}>
          <Close className='icon-sm ' />
        </button>

     

      </div>
     

    </div>
  )
}
