import * as React from "react"
import { IconOptions } from "../../../types"
const Pofiles = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Calque 1"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M25.61 30.64a12.37 12.37 0 0 0-9.18-11.92 7.44 7.44 0 1 0-6.44 0A12.37 12.37 0 0 0 .81 30.64v5a2.49 2.49 0 0 0 2.48 2.48h2.48v7.44A2.48 2.48 0 0 0 8.25 48h9.92a2.48 2.48 0 0 0 2.48-2.48v-7.44h2.48a2.48 2.48 0 0 0 2.48-2.48v-5ZM13.21 9.56A2.48 2.48 0 1 1 10.73 12a2.48 2.48 0 0 1 2.48-2.48Zm7.44 23.56h-2.48a2.49 2.49 0 0 0-2.48 2.48V43h-5v-7.4a2.48 2.48 0 0 0-2.48-2.48H5.77v-2.48a7.44 7.44 0 1 1 14.88 0v2.48ZM34.79 5a2.48 2.48 0 1 1-2.48 2.48A2.49 2.49 0 0 1 34.79 5Zm12.4 21v5a2.48 2.48 0 0 1-2.48 2.48h-2.48v7.44a2.48 2.48 0 0 1-2.48 2.48h-9.92a2.47 2.47 0 0 1-2.39-1.79v-3.53h2.48a2.5 2.5 0 0 0 2.39-1.79v2.15h5V31a2.48 2.48 0 0 1 2.48-2.48h2.48V26a7.44 7.44 0 0 0-13.57-4.22 12.45 12.45 0 0 0-4.15-2.68 12.54 12.54 0 0 1 7.06-5 7.44 7.44 0 1 1 6.44 0A12.38 12.38 0 0 1 47.19 26Z"
      style={{
        fillRule: "evenodd",
      }}
    />
  </svg>
)
export default Pofiles
