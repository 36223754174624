import * as React from "react"
import { IconOptions } from "../../../types"

const Preview = (props: IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
 
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 140 140"
    {...props}
  >
    <path
      d="M140 140H0V0h140z"
      style={{
        fill: "none",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M140 70c-10 0-30-40-70-40S10 70 10 70c0 10 20 40 60 40s60-30 70-40zm-70 30c-20 0-40-20-50-30 10-10 30-30 50-30 30 0 50 20 50 30s-20 30-50 30zm0-50c-10 0-20 10-20 20s10 20 20 20 20-10 20-20-10-20-20-20zm0 30c-10 0-10 0-10-10s0-10 10-10 10 0 10 10 0 10-10 10z"
      style={{
        fillRule: "nonzero",
      }}
    />
  </svg>
)
export default Preview
