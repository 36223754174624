import React from 'react'
import { useParams } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import { useSelectionHandlers } from '../../hooks/useSelectionHandler'
import { VolumeSearch } from '../../../types'
import ObjectWithDropdown from '../../../components/shared/object/objectWithIcon/Withdrpdown'
import ResourceMenu from '../../../components/shared/menu/ResourceMenu'
import { storageUrl } from '../../../services/api/constants'

type Props = {
    availableLocations: {document :VolumeSearch}[],
    setRecheckPin:() => void,
    suffix: string

}
const AvailableLocations = React.memo(({ availableLocations, setRecheckPin, suffix }: Props) => {
    const params = useParams()
    const { handleSelectLocation } = useSelectionHandlers(suffix)
  return (
    <div className="space-y-2">
    <p className="text-groupe">Recently deployed</p>
    <div className='flex flex-wrap ml-[-7px]'>
      {availableLocations.map((loc:{document:VolumeSearch}) => (
      
        <ObjectWithDropdown
        title={loc.document.title.en}
        overlay={<ResourceMenu
          id={loc.document.id}
          onSuccess={setRecheckPin}
          type='locations'
          items={{
            shortcut:true
          }}
        />}
        icon={<div className='relative w-full h-full '>
            <ReactSVG src={`${storageUrl}${loc.document.iconUrl}`} className='w-full h-full'
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
              }}
            />
            {/* {loc.ownerLogo && (
              <div className='w-full h-full flex justify-end items-end top-0 left-0 absolute '>
                <img className='w-[20px] h-[20px] border rounded-sm overflow-hidden' src={loc.ownerLogo}/>
              </div>

            )} */}
          
        </div>}
        key={loc.document.id}
        id={loc.document.id!}
        description={<p className='truncate'>{loc.document.breadcrumbs?.[0]?.en!}</p>
          
      
            
        }
        active={loc.document.id === params.volumeId}
        onSelect={() => handleSelectLocation(loc.document)}
    />
      ))}
    </div>
  </div>
  )
}
)
export default AvailableLocations