import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../context/AppContext'
import { Modal } from 'antd';
import Icon from '../../components/icons/Icon';
import { useSearchParams } from 'react-router-dom';

export default function SplashScreen() {
    const { domain } = useAppContext()
    const [open, setopen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        
        if(domain?.id && !searchParams.get('subscription')){
            setopen(true)
        }
    }, [domain?.id]);
  return (
    <Modal
        visible={open}
        onCancel={() => setopen(false)}
        destroyOnClose
        footer={null}
        width={1130}
        closeIcon={
            <button className='rounded p-1 bg-white'>
                <Icon className='icon-sm' name='Close'/>
            </button>
        }
    >
        <div className='flex space-x-8 w-full h-[700px] rounded-[15px] relative'
            style={{
                backgroundImage: `url("${domain?.image?.split('?')[0]}_800x571?alt=media")`,
                backgroundSize: "cover", // Ensures the image covers the div
                backgroundPosition: "center", // Centers the image
            }}
        >
            <div 
                className='absolute w-full h-full rounded-[15px] flex flex-col justify-center pl-[70px] text-white'
                style={{
                    clipPath: 'polygon(0px 0px, 45% 0px, 74% 100%, 0% 100%)',
                    background: 'rgba(0,0,0,0.7)'
                }}
            >
                <div className='max-w-[50%]'>
                    <p className='text-[41px] font-bold uppercase'>
                    {window.location.hostname}
                    </p>
                    <p className='text-[21px] uppercase font-light'>
                        {domain?.description}
                    </p>

                </div>
            </div>
            <div className='absolute bottom-10 text-white right-10'>
                <Icon className='w-[50px] h-[50px]' name='TeddraLogo'/>
            </div>
            
        </div>
    </Modal>
  )
}
