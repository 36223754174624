import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Server } from '../../../../../types';
import ServerWithCheck from '../../../components/ServerWithCheck';

export default function Folders() {
    const [folders, setfolders] = useState<Server[]>([]);
    const location = useLocation()
    const params = useParams()

    useEffect(() => {
        if(location.state?.folders){
            setfolders(location.state.folders)
        }
    }, [location.state]);
  return (
    <div className=''>
        <div className='height-publishin overflow-auto pl-3'>
            <div className='-ml-2'>
                {folders?.map(folder => (
                    <ServerWithCheck path={`/${location.state?.network}/${location.state?.server}/${location.state?.drive}/${location.state?.directory}/${folder.title}`} item={folder} active={folder.id === params.folderId} clickFn={() => {}}/>
                ))}
            </div>
        </div>
        <div>
            <Outlet/>
        </div>
    </div>
  )
}
