import React, { useEffect, useState } from 'react'
import { fetchInt } from '../../../utils/api/fetchInt';
import { getUserVolumes } from '../../../services/api/request';
import { useMydeskContext } from '../../context/MydeskContext';
import { DIRECTORIES_FEATURE_CODE, DRIVES_FEATURE_CODE, FILES_FEATURE_CODE, FOLDERS_FEATURE_CODE } from '../../../constants';
import ObjectMd from '../../../components/shared/object/objectMd';
import Icon from '../../../components/icons/Icon';
import { Empty } from 'antd';
import useNavigateTo from '../../hooks/useNvigateTo';

export default function Myvolumes() {
    const [volumes, setvolumes] = useState([]);
    const { userCurrentSubscription } = useMydeskContext()
    const [allowedVolumes, setallowedVolumes] = useState<{drives: any, directories:any, folders:any, files:any}>({drives: 0, directories:0, folders:0, files:0});
    const { goTo } = useNavigateTo()

    useEffect(() => {
        
        fetchInt(getUserVolumes).then(res => res.json()).then(data => console.log(data, 'volumes')).catch(err => console.log(err))
        
     }, []);
     useEffect(() => {
        
        if(userCurrentSubscription?.tier){
            const features = userCurrentSubscription?.tier.features
            const drives = features.filter(f => f.code === DRIVES_FEATURE_CODE)?.[0] ? features.filter(f => f.code === DRIVES_FEATURE_CODE)?.[0]?.value : 0
            const directories = features.filter(f => f.code === DIRECTORIES_FEATURE_CODE)?.[0] ? features.filter(f => f.code === DIRECTORIES_FEATURE_CODE)?.[0]?.value : 0
            const folders = features.filter(f => f.code === FOLDERS_FEATURE_CODE)?.[0] ? features.filter(f => f.code === FOLDERS_FEATURE_CODE)?.[0]?.value : 0
            const files = features.filter(f => f.code === FILES_FEATURE_CODE)?.[0] ? features.filter(f => f.code === FILES_FEATURE_CODE)?.[0]?.value : 0
            setallowedVolumes({drives, directories, folders, files})
        }else{
            setallowedVolumes({drives: 0, directories:0, folders:0, files:0})
        }
     }, [userCurrentSubscription]);
  return (
    <div>
        <div className='bar-h  flex items-center'>
            <h1 className='text-[20px] font-bold'>Manage your volumes</h1>
            

        </div>
        <div className=' mt-8 space-y-12'>
            <div className='space-y-5'>
                <p className="text-groupe">My volumes</p>
                <div>
                    {volumes?.length > 0 ? volumes.map(v => (
                        <div></div>
                    )) : (
                        <div className="flex space-x-4 ml-2 max-w-[280px]">
                            <Icon className='w-[80px] h-[80px]' name='Warning'/>
                            <div className="flex-1">
                                <p className="font-bold">You don't have a volume yet</p>
                                <p className='text-muted'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet molestias suscipit corporis.
                                </p>
                            </div>
                        </div>
                    )}

                </div>

            </div>
            <div className="space-y-5">
                <p className="text-groupe">Create a new volume</p>
                {allowedVolumes.folders > 0 && (
                    Array.from(Array(allowedVolumes.folders).keys()).map((v,i) => (
                        <ObjectMd
                            title={`Folder ${i + 1}`}
                            icon={<Icon name='PlusIcon' className='icon-md'/>}
                            description={'Add a new Folder'}
                            onSelect={() => goTo('/my-volumes/folder', {})}
                        
                        />
                    ))
                )}
            </div>

        </div>
    </div>
  )
}
