import React, { useEffect, useState } from 'react'
import { Spin } from 'antd';
import StepsButtons from '../../../components/stepsButtons';
import Plan from '../../../components/Plan';
import { allPlans } from '../../../../services/api/request/plans';
import { Plans } from '../../../../types';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';



export default function Profile() {
    const [plans, setplans] = useState<Plans[]>([]);
    const [loading, setloading] = useState(false);
    const { setprofile, profile, setCurrentStep } = useSignupContext()
    const navigate = useNavigate()
 
    useEffect(() => {
    
      setCurrentStep(1)
    }, []);
    
    useEffect(() => {
        fetch(allPlans).then(res => res.json()).then(data => setplans(data.data.reverse().map((p:any) => ({id:p.id,...p.planData})))).finally(() => setloading(false)).catch(err => console.log(err))
    }, []);
  return (
    <div className='space-y-2 h-full flex flex-col'>
        <Spin spinning={loading}>
          <div className='flex flex-col h-full rounded p-4'>
               <div className='border-b border-main pb-6'>
                    <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">Choose your profile
                    </p>
                   
                </div>
                <div className="flex-1 h-full flex items-center">
                      
                      <div className='flex gap-8 flex-wrap justify-center'>
                          {plans.map(plan => (
                              <Plan editPlan={() => {}} key={plan.id} plan={{...plan, description:{en:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'}}} handleNavigate={(id) => {
                                setprofile({id, name:plan.title.en})
                              }} active={profile.id === plan.id} noActions />
                          ))}
                      </div>

                </div>

          </div>
        </Spin>
        
        <StepsButtons
            nextStep={() => navigate('/connect/signup/steps/2')}
            disable={!profile.id}
        />
        
      
    </div>
  )
}
