import { Form } from 'antd'
import React, { useState } from 'react'
import SelectCity from '../SelectCity'
import SelectCountry from '../SelectCountry';
import SelectThematics from '../SelectThematics';
import SelectInterests from '../SelectInterests';

export default function IndividualForm() {
    const [selectedCountry, setselectedCountry] = useState<undefined | string>(undefined);
    const [selectedThematic, setselectedThematic] = useState<undefined | {id:string, parentId:string, featuredDomain:string}>(undefined);

  return (
    <div className='space-y-2 flex-1'>
        <div className="w-[250px]">
            <Form.Item
                name={'country'}
                rules={[{required:true, message: 'Please select a country'}]}
            >
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                        return prevValues.country !== curValues.country

                    }}

                >
                    {({ getFieldValue, setFieldsValue }) => (
        
                        <SelectCountry
                            selectedCountry={{id: selectedCountry, value: getFieldValue('country')}}
                            setselectedCountry={(id:string, value:string | null) => {
                            setselectedCountry(id)
                          
                            setFieldsValue({ country: value, city: undefined})

                            }}
                        />
                    )}

                </Form.Item>

            </Form.Item>

        </div>
        <div className="w-[250px]">
            <Form.Item
                name={'city'}
                rules={[{required:true, message: 'Please select a city'}]}
            >

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
                >
                    {({ getFieldValue, setFieldsValue }) => (
                        <SelectCity
                            selectedCountry={selectedCountry}
                            selectedRegion={getFieldValue('city')}
                            setselectedRegion={(value:string | null) => {
                                setFieldsValue({ city: value})
                            }}
                        />
                    )}

                </Form.Item>

            </Form.Item>

        </div>
        <div className="w-[250px]">
            <Form.Item
                name={'thematics'}
                rules={[{required:true}]}
            >
                <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.thematics !== curValues.thematics}
                >
                    {({ getFieldValue, setFieldsValue }) => (
                        <SelectThematics
                            value={getFieldValue('thematics')}
                            setValue={(value:string | undefined, node:any | null) => {
                                if(node && value){
                                    setselectedThematic({id:value, featuredDomain: node.featuredDomain, parentId: node.parentId})
                                    setFieldsValue({ thematics: value})

                                }else{
                                    setFieldsValue({thematics: undefined})
                                }
                            }}
                        />
                    )}

                </Form.Item>
                
            </Form.Item>   

        </div>

        <div className="w-[250px]">
            <Form.Item
                name={'interests'}
            >
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.interests !== curValues.interests}
                >
                    {({ getFieldValue, setFieldsValue }) => (
                        <SelectInterests
                            node={selectedThematic}    
                            value={getFieldValue('interests')}
                            setValue={(value:string | undefined) => {
                                setFieldsValue({ interests: value})

                            }}
                        />
                    )}

                </Form.Item>
                
            </Form.Item>              

        </div>





    </div>
  )
}
