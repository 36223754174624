import * as React from "react"
import { IconOptions } from "../../../types"
const Pages = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_1"
    viewBox="0 0 48 48"
    {...props}
  >
 
    <path
      d="M39.28 34.91H17.46V4.36h13.09v8.73h8.73v21.82ZM33.63 0H17.46c-2.41 0-4.36 1.96-4.36 4.36V34.9c0 2.4 1.96 4.36 4.36 4.36h21.82c2.41 0 4.36-1.96 4.36-4.36V10.01L33.63 0Z"
      className="cls-77"
    />
    <path
      d="M8.73 8.73H4.36v34.91c0 2.4 1.96 4.36 4.36 4.36H34.9v-4.36H8.73V8.73Z"
      className="cls-77"
    />
  </svg>
)
export default Pages
