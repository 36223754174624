import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  
    <path
      d="M16.2091 11.9991V11.9991L19.7111 15.5011C20.1021 15.8921 20.1021 16.5251 19.7111 16.9151L16.9161 19.7101C16.5251 20.1011 15.8921 20.1011 15.5021 19.7101L12.0001 16.2081L9.90405 18.3041C9.37805 18.8301 8.48305 18.6181 8.24805 17.9131L4.04805 5.31206C3.78705 4.53106 4.53105 3.78706 5.31305 4.04806L17.9141 8.24806C18.6191 8.48306 18.8311 9.37806 18.3051 9.90406L16.2091 11.9991"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
