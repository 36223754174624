import * as React from "react"
import { IconOptions } from "../../../types"
const Desq = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <defs>
      <style>{".fil15{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <g id="_2706217138976">
        <path
          d="M400 400H0V0h400z"
          style={{
            fill: "none",
            fillRule: "nonzero",
          }}
        />
        <path
          d="M280 330c-20-10-50-10-80-10s-60 0-80 10c-10 10-10 10-10 20s10 10 10 10h150c10 0 20 0 20-10s0-10-10-20zM330 30H70c-20 0-40 20-40 40v200c0 10 20 30 40 30h260c20 0 30-20 30-30V70c0-20-10-40-30-40zM70 270V70h260v200H70z"
          className="fil15"
        />
      </g>
    </g>
  </svg>
)
export default Desq
