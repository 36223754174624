import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Server } from "../types";


type States = {
    domain: Server | null,

}
type Functions = {
    setDomain:(data:Server | null) => void,


}
type Props = States & Functions
const AppContext = createContext<Props>({} as Props);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children } : { children : JSX.Element}) => {
  const params = useParams()
  const [state, setState] = useState<States>({
    domain:null,

   
  })
  

  const value = useMemo(() => {
    
    const setDomain =(data:Server | null) => {
        setState((prev) => ({
          ...prev,
          domain:data
        }))
      }
   
    return {
      ...state,
      setDomain,

     
    }
  }, [state])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};