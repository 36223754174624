import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} stroke="white" />
    <path
      d="M13.3206 16.0022H16.0017"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6612 16.0021V11L13.2006 12.3205"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1484 13.164L20.9747 8.33702C21.1838 7.11451 20.2424 5.99805 19.0019 5.99805H4.99809C3.75757 5.99805 2.81618 7.11451 3.02527 8.33602L3.85161 13.163C3.88963 13.3861 3.88963 13.6152 3.85161 13.8383L3.02527 18.6653C2.81618 19.8878 3.75757 21.0043 4.99809 21.0043H19.0029C20.2424 21.0043 21.1838 19.8878 20.9747 18.6663L20.1484 13.8393C20.1104 13.6152 20.1104 13.3871 20.1484 13.164Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0029 5.99783L19.116 5.33456C19.326 4.11305 18.3847 2.99658 17.1441 2.99658H6.85585C5.61634 2.99658 4.67494 4.11305 4.88403 5.33456L4.99708 5.99783"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99829 11.9604C8.15736 11.4102 8.64356 11 9.24481 11C9.97311 11 10.5644 11.5902 10.5644 12.3195C10.5644 12.9718 10.0351 13.501 9.38287 13.501C10.0351 13.501 10.5644 14.0303 10.5644 14.6825C10.5644 15.4108 9.97411 16.0021 9.24481 16.0021C8.64356 16.0021 8.15736 15.5919 7.99829 15.0417"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
