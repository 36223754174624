import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { Popover } from 'antd'
import { Tier } from '../../types'
import Icon from '../../components/icons/Icon'


type Props = {
    editTier:(tier:Tier) => void ,
    data: Tier,
    noActions?:boolean,
    handleSelect?:(tier:Tier) => void,
    active?:boolean
}
export const features = {
    '001': 'Create ** File',
    '002': 'Create ** Folder',
    '003': 'Create ** Directory',
    '004': 'Create ** Drive',
    '005': 'Publish ** Publications',
    '006': 'Pin ** Resources',
    '007': 'Receive basic stats',
    '008': 'Receive advanced stats',
    '009': 'Receive premium stats',
    '010': `Receive **% of your volum's revenue`,
    '011': `Integrate Qorddu's widget to your website`,
    '012': `Index up to ** pages from your website with AI`,
    '013': `Publish your "Search engine" with up to ** websites of your members using AI`
}
export default function TierComponent({data, editTier, noActions, active, handleSelect }: Props) {


  return (
    <div className='h-full'>
        <div className={` w-[250px] h-full   flex flex-col space-y-4 rounded border border-main ${active && 'bg-black text-white'} ${handleSelect && 'cursor-pointer'}`} onClick={() => handleSelect ? handleSelect(data) : {}}>
        
            <div className={`border border-gray-500 relative mx-auto flex-1 rounded p-1.5 ${active && '!bg-black text-white'} ${data.title.en === 'Standard' ? 'bg-skin-fill-inverted text-skin-inverted' : ''} flex flex-col`}>
                {data.title.en === 'Standard' && (
                    <div className='absolute top-[-20px] w-full flex justify-center '>
                        <div className='rounded-full bg-form text-skin-base font-bold px-5 py-2'>Most Popular</div>
                    </div>

                )}
                <div className=" pt-7 px-5">
                    <Icon name={'File'} className='w-[80px] h-[80px] mx-auto mb-4'/>
                    <div className={`flex space-x-1 justify-center text-center `}>
                        <div className='flex-1 space-y-1'>
                            <p className='text-[22px] font-semibold flex-1 leading-[27px]' style={{lineHeight:'24px'}}>{data.title.en}</p>
                            <p className='mb-[20px]'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>

                        </div>

                    </div>
                    <div className='h-[120px] flex items-center text-center justify-center'>
                            <div className='mb-[11px] pt-5'>
                                {data.price !== null ? (
                                    <>
                                        <p className='text-[44px] font-extrabold'>{data.price}$</p>
                                        <p>/per month</p>
                                    
                                    </>

                                ): (
                                    <div>
                                        <button className='px-5 py-2 bg-skin-fill-inverted text-skin-inverted text-[14px] font-extrabold rounded'>Contact Us</button>
                                    </div>
                                )}
                            </div>

                
                    </div> 
                  
                </div>
                <div className='space-y-5 pt-5 pl-5 pr-3 pb-5 bg-sub-windows rounded text-skin-base flex-1 flex flex-col'>
                    <div className='space-y-4'>
                        {data.features.map((ph, i) => (
                            <div className='flex space-x-3 ' key={i}>
                                {/* <CheckCircleIcon className='w-4 h-4 fill-transparent mt-.5'/> */}

                                <p className='flex-1 '>{(features as any)[ph.code].includes('**') ? (
                                    <>
                                        {(features as any)[ph.code].replace('**', ph.value)}

                                    </> 
                                ): (
                                    <>
                                        {(features as any)[ph.code]}

                                    </> 
                                )}</p>
                                 <Popover placement='right' content={
                                    <div className='bg-sub-windows rounded-lg p-3 w-[250px]'>
                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Assumenda doloribus enim itaque suscipit, neque amet deserunt atque corrupti deleniti qui rem consectetur aspernatur temporibus.!</p>
                                    </div>
                                 } title="">
                                    <QuestionMarkCircleIcon className='icon-mini fill-transparent opacity-20 hover:opacity-100 mt-1'/>
                                </Popover>
                                
                            </div>
                        

                        ))}
                        
                        
                    </div>
                    <div className="flex-1 flex items-end">
                        <button className={`w-[170px] flex justify-center items-center py-2 ${active ? 'bg-black text-white' :'bg-skin-fill-inverted text-skin-inverted'} rounded h-max`}>Choose</button>

                    </div>
                </div>
            </div>
        

        </div>

    </div>
  )
}