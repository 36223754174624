import React, { useState } from 'react'
import Icon from '../../../components/icons/Icon'
import { Drawer } from 'antd';
import Navigatelayout from './Layout';

export default function NavigateWindow() {
  const [windowOpen, setwindowOpen] = useState(false);
  return (
    <div>
      <button className="flex space-x-2 items-center" onClick={() => setwindowOpen(prev => !prev)}>
        <Icon name="TeddraLogo" className="icon-sm"/>
        <p className="font-bold">Qorddu</p>
      </button>
      <Drawer
        visible={windowOpen}
        closable={false}
        onClose={() => setwindowOpen(false)}
        footer={null} 
        width={900}
        style={{
          height: 800,
          top: 'calc(100% - 800px - 33px)'
        }}
      >
        <Navigatelayout/>
      </Drawer>

    </div>

  )
}
