import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.99 21.99" {...props}>
    <defs>
      <style>
        {
          ".popular-2{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:1.7px;fill-rule:evenodd}"
        }
      </style>
    </defs>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          d="M17.25 19.39h1.92a1.52 1.52 0 0 0 1.53-1.53V2.59a1.52 1.52 0 0 0-1.53-1.53H7.24A1.53 1.53 0 0 0 5.7 2.59V7"
          className="popular-2"
        />
        <path
          d="m7.21 10 .85 1.7H14a.64.64 0 0 1 .64.64v7.93a.64.64 0 0 1-.64.64H2a.64.64 0 0 1-.64-.64v-10A.64.64 0 0 1 2 9.63h4.64a.65.65 0 0 1 .57.37Z"
          className="popular-2"
        />
        <path
          d="M17.09 1.06h3.59v3.59h-2.74a.85.85 0 0 1-.85-.85V1.06Z"
          className="popular-3"
        />
      </g>
    </g>
  </svg>
)
export default SvgComponent
