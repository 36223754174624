import * as React from "react"
import { IconOptions } from "../../../types"
const AddPublication = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={100}
    height={100}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 1050 1050"
    {...props}
  >
    <defs>
      <style>{".fil1{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <path
        id="_1519653429504"
        d="M1050 1050H0V0h1050z"
        style={{
          fill: "none",
          fillRule: "nonzero",
        }}
      />
      <g id="_1519653427632">
        <path
          d="M90 260h790v350h90V90c0-50-40-90-90-90H90C40 0 0 40 0 90v700c0 50 40 90 90 90h520v-90H90V260zm220-80V90h130v90H310zm570 0H530V90h350v90zM220 90v90H90V90h130z"
          className="fil1"
        />
        <path
          d="M1050 830H920V700h-90v130H700v90h130v130h90V920h130z"
          className="fil1"
        />
      </g>
    </g>
  </svg>
)
export default AddPublication
