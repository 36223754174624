import { Server } from "../../../../../types"
import { Breadcrumb, routeType } from "../types"

export type ModulesBreadcrumbType = {
    datacenter: {id:string, name:string} | null, 
    server: {id:string, name:string} | null,
    moduleBreadcrumb:Breadcrumb,
    onNetworkSelct: (network:Server) => void,
    world:boolean,
    modulePath:string,
    all:string,
    disabled?:boolean

}
export type MydeskeBreadcrumbType = {
    datacenter: {id:string, name:string} | null, 
    type:'volumes' | 'publications' | null,
}
export type PublishBreadcrumbType = {
    type:'new' | 'publications' | null,
}
export type NewspapersBreadcrumbType = {
    datacenter: {id:string, name:string} | null
}
const generatePath = (routes:routeType[], datacenter:string, world:boolean,modulePath:string,  server?:string, disabled?:boolean)=> {
    return routes.map(route => (
        {
            ...route,
            path:`${world ? '/world' : ''}${route.path}`,
            disabled: (route.disabled || disabled)
        }
    ))
}
export const ModulesBreadcrumb = (props:ModulesBreadcrumbType) => {
    const routes:routeType[] = [
        {
            path: props.modulePath,
            icon:{type:'icon', src:'Globe'},
            disabled: (!props.world || props.disabled)
        },
       ...props?.datacenter? [{
        name:props?.datacenter?.name,
        disabled:(!props.world || props.disabled),
       
       }] : [{
        name: 'Select a thematic',
        
       }],
   
       ...(props.datacenter && props.server && !props.world)? [{
        name:props?.server?.name,
        path:`/LocalClusters/${props.server?.id}`,
        disabled: props.disabled
       }] : [],
       ...(props.datacenter && (props.server || props.world) && props.moduleBreadcrumb.routes.length>0)? [...generatePath(props.moduleBreadcrumb.routes, props.datacenter?.id, props.world,props.modulePath, props.server?.id, !!props.disabled)] : [],
       ...props.all ? [{name: props.all}] : []
    ]
    return routes
}


export const MydeskBreadcrumb = (props:MydeskeBreadcrumbType) => {
    const routes:routeType[] = [
        {
            path: '/mydesk',
            icon:{type:'icon', src:'Manage'}
        },
       ...props?.datacenter? [{
        name:props?.datacenter.name,
        path:`/mydesk/${props?.datacenter.id}`
       }] : [],
       ...(props?.type && props.datacenter) ? [
        {
            name: props.type === 'volumes' ? 'Volumes' : 'Publications',
            path: props.type === 'volumes' ? `/mydesk/${props.datacenter.id}/volumes/all` : `/mydesk/${props.datacenter.id}/publications/all`,

        }
       ] : []
    ]
    return routes
}



