import React, { useState } from 'react'
import { VolumeSearch } from '../../../../types'
import ReactSvg from '../../../../components/shared/ReactSvg'
import { storageUrl } from '../../../../services/api/constants'
import { Dropdown } from 'antd'
import DomainDropdown from './all/DomainDropdown'

type Props = {
    domain: VolumeSearch,
    active:boolean,
    onSelect:(domain:VolumeSearch) => void
}
export default function Domain({ domain, onSelect } :Props) {
    const [active, setactive] = useState(false);
  return (
    <Dropdown
        overlay={<div className='-mt-7 ml-5'><DomainDropdown openDomain={() => onSelect(domain)}/></div>}
        onVisibleChange={visible => setactive(visible)}
        destroyPopupOnHide
    >
        <div className={`w-[120px] h-max flex flex-col items-center px-1.5 py-3 rounded ${active ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} cursor-pointer space-y-2`}>
            <ReactSvg className='w-[50px] h-[50px]' src={`${storageUrl}${domain.iconUrl}`}/>
            <p className='text-center'>{domain.title.en}</p>
        </div>

    </Dropdown>
  )
}
