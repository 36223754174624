import React from 'react'
import ActionsBar from '../../components/shared/bars/TopBar/actionsBar';
import { PanelBar } from '../../components/shared/bars/ResourcesBar';
import { useUIContext } from '../context/UIContext';
import { useSearchContext } from '../context/searchContext';
import { useSearchParams } from 'react-router-dom';

export default function QfsActionsBar() {
    const { disabled, displayMode, setDisplayMode, barActivatedFields, setBarActivatedFields, mode, setObjectDisplayMode, hidePanel} = useUIContext()
    const { selectedPubType, setSelectedPubType, groupBy} = useSearchContext()
    const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
         <div className={`border-b border-main bg-sub-windows ${!hidePanel && 'ml-[-9px]'}`}>
            <ActionsBar
                resourcesActions={{
                    filter: disabled.filter ? undefined :{
                        selectedPubType,
                        setSelectedPubType
                    },
                    display: disabled.display ? undefined :{
                        displayMode,
                        setDisplayMode,
                        groupBy,
                        setGroupBy: (value:boolean) => {
                            searchParams.set('grouped', value ? 'true' : '');  
                            setSearchParams(searchParams);
                        }

                    },
                    bar: {
                        barActivatedFields, 
                        setBarActivatedFields
                    } 
                }}
                detailsActions={{
                    mode,
                    setObjectDisplayMode,
                    recheckPin:0, 
                    openActions: {
                        volumes:({key,volume}:any) => {
                        

                        }
                    }
                }}
            />
        </div>

        <div className="pl-[11px]">
            <PanelBar isPublication={!disabled.publicationBar} author={barActivatedFields.author} date={barActivatedFields.date}/>

        </div>
    </>
  )
}
