import React from 'react'

type Props = {
    nextStep? : () => void,
    prevStep?:() => void,
    disable: boolean,
    submit?:boolean
}
export default function StepsButtons({ nextStep, prevStep, disable , submit } : Props) {
  return (
    <div className='flex w-full justify-end space-x-3'>
        {prevStep && (
            <button className='px-3 input-h flex items-center rounded bg-sub-windows bg-hover-transparent disabled:opacity-40' onClick={prevStep}>Previous</button>
        )}
        <button className='px-3 input-h flex items-center rounded bg-sub-windows bg-hover-transparent disabled:opacity-40' type={submit ? 'submit' : 'button'} disabled={disable} onClick={nextStep ? nextStep : () => {}}>Next</button>
    </div>
  )
}
