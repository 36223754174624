import React, { useEffect, useState } from 'react'
import TopBar from '../../components/shared/bars/TopBar';
import { useSearchContext } from '../context/searchContext';
import { useUIContext } from '../context/UIContext';
import { useQfsContext } from '../context/qfsContext';
import PathBar from '../../components/shared/bars/TopBar/Pathbar';
import { useAppContext } from '../../context/AppContext';

export default function PathBarContainer() {
    const { setQuery, query } = useSearchContext()
    const { disabled, hidePanel, setHidePanel, all } = useUIContext()
    const { currentLocation } = useQfsContext()
    const { domain } = useAppContext()
   
  return (
    <PathBar
        Breadcrumb={{routes: currentLocation?.routes? 
          [
            {
              icon:{type:'icon', src:'Globe'},
              
            },
           ...domain ? [
            {
             name: domain.title 
            }
           ] : [],
           ...currentLocation.routes,
           ...all?[
            {
              name:all
            }
           ]: []
          ] : 
          [
            {
              icon:{type:'icon', src:'Globe'},
              
            },
           ...domain ? [
            {
             name: domain.title 
            }
           ] : []
          ]
        }}
        search={{value:query, onChange:(e:any) => {
          setQuery(e.target.value)
        }, disabled:disabled.query, placeholder: `Search: ${currentLocation?.title ? currentLocation.title :''}`}}
        hidePanel={{open:hidePanel, disabled: false}}
        setHidePanel= {setHidePanel}
        onCncelSearch={() => setQuery('')}
    
    />
  
  )
}
