import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react'



interface DataType {
    key: string;
    name: string;
    number: string;

  }
  
  const columns: ColumnsType<DataType> = [
    {
      title: 'Top 10',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: '%',
      dataIndex: 'number',
      key: 'number',
    },
    
   
  
   
  ];
  const data: DataType[] = [
    {
        key:'1',
        name: 'IBM france',
        number: '15.9%',
    },
    {
        key:'2',
        name: 'Microsoft france',
        number: '12%',
    },
    {
        key:'3',
        name: 'Google cloud',
        number: '8.2%',
    },
    {
        key:'4',
        name: 'Capgemenie',
        number: '5.4%',
    },
    {
        key:'5',
        name: 'Atos',
        number: '4.8%',
    },
    {
        key:'6',
        name: 'Global knowledge',
        number: '2.4%',
    },
    {
        key:'7',
        name: 'Apec',
        number: '1.3%',
    },
    {
        key:'8',
        name: 'Programmer!',
        number: '0.5%',
    },
    {
        key:'9',
        name: 'Dawan',
        number: '0.4%',
    },
    {
        key:'10',
        name: 'Epitech',
        number: '0.3%',
    }
  ]
export default function Organizations() {
  return (
    <div className='w-[200px]'>
        <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  )
}
