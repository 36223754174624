import { auth } from "../firebase";

const { fetch: originalFetch } = window;

export const fetchInt = async (...args:any) => {
    const token = await auth.currentUser?.getIdToken()
    let [resource, config ] = args;
    const response = await originalFetch(resource, {
        ...config,
        headers: {
            ...config?.headers,
            'Authorization':`Bearer ${token}`,

        }
    });
    return response;
};