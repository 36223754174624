import { Route, Routes } from 'react-router-dom'

import { StatsContextProvider } from './context/statsContext'
import StatsLayout from './Layout'
import Home from './pages'
import VolumePage from './pages/volume'



export default function StatsNavigation() {

  
  return (
    <StatsContextProvider>


        <Routes>
        

            {/** FOLDERS **/}
            <Route element={
            
                <StatsLayout/>
            
            }>
        
                <Route element={<VolumePage/>} path='/'/>
                <Route element={<VolumePage/>} path='/:collection/:id'/>
                <Route element={<VolumePage/>} path='/:serverId/:collection/:id'/>
            </Route>




        </Routes>
      

    </StatsContextProvider>
  )
}
