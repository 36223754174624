// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {  connectStorageEmulator, getStorage } from 'firebase/storage'

 
const firebaseConfig = {

  apiKey: "AIzaSyBxDWatj13rpsspLGgxc0W7hQ6K3KMDzy4",

  authDomain: "teddra-api-prod-5f1fb.firebaseapp.com",

  projectId: "teddra-api-prod-5f1fb",

  storageBucket: "teddra-api-prod-5f1fb.appspot.com",

  messagingSenderId: "934541454615",

  appId: "1:934541454615:web:45d8ef744b02af7fe05b52"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
export const auth = getAuth(app)

// if((window as any).envVars.MODE === 'dev'){
  // connectFirestoreEmulator(db, '127.0.0.1', 8080);
  // connectStorageEmulator(storage, '127.0.0.1',9199 )
  // connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })
// }



