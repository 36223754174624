import React from 'react'
import VolumesContents from './volumes'
import PublicationsContents from './publications'

export default function ContentComponent() {
  return (
    <div className='gap-[120px] flex flex-wrap'>
      <div className="w-[520px]">
        <VolumesContents/>
      </div>
      <div className="flex-1">
        <PublicationsContents/>

      </div>
    </div>
  )
}
