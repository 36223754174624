import * as React from "react"
import { IconOptions } from "../../../types"
const PublishedFolder = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"

    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 6720 6720"
    {...props}
  >
    <path
      d="M-5470-29660h187880v132860H-5470z"
      style={{
        fill: "transparent",
      }}
    />
    <path
      d="M880 1570h1800l450 610h2570l-10 3360H880z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
      }}
    />
    <path
      d="M6720 6720H0V0h6720z"
      style={{
        fill: "none",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M5600 1960H3290l-460-620c-110-140-280-220-450-220H1120c-310 0-560 250-560 560v3640c0 310 250 560 560 560h4480c310 0 560-250 560-560V2520c0-310-250-560-560-560zM1120 5320V1680h1260l460 620c110 140 270 220 450 220h2310v2800H1120z"
      style={{
        fill: 'var(--text-base)',
        fillRule: "nonzero",
      }}
    />
  </svg>
)
export default PublishedFolder
