import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import useGetPublications from '../../hooks/useGetPublications'
import LocationLayout from '../../components/locationLayout'
import { useSearchContext } from '../../context/searchContext'

export default function AllPublications({suffix, home}: {suffix:string, home?:boolean}) {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { groupBy } = useSearchContext()
    const { publications, isLoaded } = useGetPublications({id:home ? params.serverId! : params.id!, groupBy: groupBy.paidPubs})

  
    

 
  
    
     

  return (
   <LocationLayout
        layoutProps={
            {
            loading,
            selectedKey: params.id!,
            disabled:{filter:false, new:false, display:false,query:false},
            publicationBar:true,
            id: params.id!,
            collection: suffix
            }
            
        }
        groupBy={groupBy}
        allowSearch={true}
        publications={publications}
        sponsors={null}
        partners={null}
        availableLocations={null}
        suffix={`${suffix}all/`}
        home={home}
        webResources={[]}
        isLoaded={isLoaded}
   />
  )
}
