import { createContext, useContext, useMemo, useState } from "react";
import { PublicationTypes } from "../../../types";


type States = {
  type:(PublicationTypes & {groupe:{id:string, title: {en:string,fr?:string}, iconUrl:string} | null}) | null,
  format:'page' | 'link'| null,
  editData:{keywords:string[], url:string, title:string, description:string,local:string,publicationType:PublicationTypes | null, id?:string},
  active: {publish:boolean, edit:boolean},
  InitId:string | null,
  isLoaded:boolean

}
type Functions = {
  
  setType:(data:(PublicationTypes & {groupe:{id:string, title: {en:string,fr?:string}, iconUrl:string}}) | null) => void,
  setEditData:(data:{keywords:string[], url:string, title:string, description:string, local:string,publicationType:PublicationTypes | null})=> void,

  setFormat : (data:'page' | 'link') => void,
  setActive: (data: {publish?:boolean, edit?:boolean}) => void,
  setInitId: (data:string | null) => void,
  setisLoaded:(data:boolean) => void

}
type Props = States & Functions
const PublishContext = createContext<Props>({} as Props);

export const usePublishContext = () => useContext(PublishContext);

export const PublishContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    format:'link',
    type:null,
    editData:{keywords:[], description: '', url: '', title: '', local:'en',publicationType: null},

    active: {publish:false, edit:false},
    InitId:null,
    isLoaded:false
  })
  

  const value = useMemo(() => {
    

    const setType = (data:(PublicationTypes & {groupe:{id:string, title: {en:string,fr?:string}, iconUrl:string}}) | null) => {
      setState((prev) => ({
        ...prev,
        type: data,
        // active:{...prev.active, edit:true}
      }))
    }
   
    const setFormat = (data:'page' | 'link') => {
      setState((prev) => ({
        ...prev,
        format: data,
      }))
    }
    const setEditData =(data:{keywords:string[], url:string, title:string, description:string,local:string,publicationType:PublicationTypes | null})=> {
      setState((prev) => ({
        ...prev,
        editData:data
      }))
    }

    const setActive = (data: {publish?:boolean, edit?:boolean}) => {
      setState((prev) => ({
        ...prev,
        active: {...prev.active, ...data}
      }))
    }

    const setInitId= (data:string | null) => {
      setState((prev) => ({
        ...prev,
        InitId: data
      }))
    
    }

    const setisLoaded = (data:boolean) => {
      setState((prev) => ({
        ...prev,
        isLoaded: data
      }))
    }
    return {
      ...state,
      setFormat,
      setType,setEditData, setActive,setInitId,setisLoaded
    }
  }, [state])

  return <PublishContext.Provider value={value}>{children}</PublishContext.Provider>;
};