import React, { useEffect, useState } from 'react'


import { Spin } from 'antd';
import TierComponent from '../../../components/Tier';
import StepsButtons from '../../../components/stepsButtons'
import { allTries } from '../../../../services/api/request/plans';
import { Tier } from '../../../../types';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/AppContext';
import { useAuthContext } from '../../../../context/AuthContext';



export default function PlansCom() {
    const [tiers, settiers] = useState<Tier[]>([]);
    const [loading, setloading] = useState(false);
    const { planId, profile, setplanId, setaccountType, setCurrentStep, accountType} = useSignupContext()
    const navigate = useNavigate()
    const { user } = useAuthContext()
  
    const prevStep=() => navigate('/connect/signup/steps/1')
    const nextStep=() => {
        if(user && user.emailVerified){
            navigate(`/connect/signup/steps/5/${accountType === 'ORGANIZATION' ? 'organization' : 'individual'}`)

        }else if(user && !user.emailVerified){
            navigate(`/connect/signup/steps/4`)
        }else{
            navigate(`/connect/signup/steps/3`)
        }
    }
    useEffect(() => {
    
        setCurrentStep(2)
      }, []);

    useEffect(() => {
        if(profile.id){
            fetch(allTries(profile.id)).then(res => res.json()).then(data => settiers(data.data?.reverse().map((d:any) => ({id:d.id, ...d.tierData})) )).finally(() => setloading(false)).catch(err => console.log(err))

        }else{
            navigate(`/connect/signup/steps/1`)
        }
    }, [profile.id]);

 
    const handleSelect = (data:Tier) => {
        if(data.type && data.id){
            setplanId(data.id!)
            setaccountType(data.type)

        }
    }
  return (
    <div className='space-y-2 flex flex-col h-full'>
        <div className="flex-1">
            <Spin spinning={loading}>
                <div className="h-full flex flex-col rounded p-4">
                    <div className='border-b border-main pb-6'>
                        <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">{profile.name}: Choose your plan
                        </p>
                    
                    </div>
                    <div className='flex flex-wrap gap-8 justify-center flex-1 items-center'>
                        {profile.id === 'individual' && (
                            <div className='h-[603px]'>
                            <TierComponent 
                            data={{
                                title: {en:'Free'},
                                planId,
                                price:0,
                                cycleNbr:30,
                                nbrOfTestPeriodDays:0,
                                type:'INDIVIDUAL',
                                billingCycle:'MONTHLY',
                                features:[
                                    
                                ],
                                id:'free'
                            }} 
                            editTier={() => {}} 
                            noActions 
                            active={planId === 'free'} handleSelect={handleSelect}/>

                        
                        </div>
                        )}
                        {tiers?.map(tier => (
                            <div className='h-[603px]'>
                                <TierComponent key={tier.id} data={tier} editTier={() => {}} noActions active={tier.id === planId} handleSelect={handleSelect}/>

                            
                            </div>
                        ))}
                    </div>

                </div>
            </Spin>

        </div>
        <StepsButtons
            prevStep={prevStep}
            nextStep={nextStep}
            disable={!planId || !accountType}
        />
    </div>
  )
}
