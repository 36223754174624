import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ConnectLayout from './Layout'
import Login from './pages/Login'
import LoginLayout from './components/LoginLayout'
import Home from './pages'
import Subscribe from './pages/signup'
import { SignupContextProvider } from './context/SignupContext'
import Profile from './pages/signup/steps/profile'
import PlansCom from './pages/signup/steps/plans'
import CreateAccount from './pages/signup/steps/createAccount'
import ValidateEmail from '../components/ValidateEmail'
import OrganizationPage from './pages/signup/steps/organization'
import SubscribePage from './pages/signup/steps/subscribe'
import PrivateRoute from '../components/PrivateRoute'
import Pro from './pages/signup/steps/Pro'

export default function LoginRoutings() {
  return (
    <Routes>
        <Route element={<ConnectLayout/>}>


            <Route element={<SignupContextProvider><Subscribe/></SignupContextProvider>}>
              <Route element={<Profile/>} path='/signup/steps/1'/>
              <Route element={<PlansCom/>} path='/signup/steps/2'/>
              <Route element={<CreateAccount/>} path='/signup/steps/3'/>
              <Route element={<ValidateEmail/>} path='/signup/steps/4'/>
              <Route element={<PrivateRoute/>}>
                <Route element={<OrganizationPage/>} path='/signup/steps/5/organization'/>
                <Route element={<Pro/>} path='/signup/steps/5/individual'/>

                <Route element={<SubscribePage/>} path='/signup/steps/6'/>
              </Route>



            </Route>

            <Route element={<LoginLayout/>}>
                <Route element={<Home/>} path='/'/>

                <Route element={<Login/>} path='/*'/>
            
            </Route>

        </Route>
    </Routes>
  )
}
