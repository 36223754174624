import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import { usePublishContext } from '../../../../context/publishContext'
import Icon from '../../../../../../components/icons/Icon'

type Props = {
    url: string,
    setnavigationActive:any,
    setinputValue:any,
    elRef:any
}

export default function PageViewer({url, elRef, setinputValue,setnavigationActive } : Props) {

  const { type } = usePublishContext()
  const form = Form.useFormInstance()
   
   
   useEffect(() => {
    
    form.setFieldValue('url',url)
   }, [url]);
  return (
    <div className='h-full relative flex flex-col'> 
        {/* <Header elRef={elRef} inputValue={inputValue} navigationActive={navigationActive} SwitchToHome={SwitchToHome} handleInputChange={handleInputChange} handleInputClick={handleInputClick}/> */}
    
        <div className="relative flex-1">
            {/* <div className={`${}`}></div> */}
            {/* <Webpage elRef={elRef} setnavigationActive={setnavigationActive} setinputValue={setinputValue} url={url}/> */}
            {url && (

            <iframe src={url} className='w-full h-full'/>
            )}
             {!url && <div className='absolute z-10 w-full h-full top-0 left-0 flex items-center justify-center'>
                <div className='text-center opacity-[0.2]'>
                    <Icon name='Website' className='w-[90px] h-[90px] mx-auto' />
                    <p className='font-bold'>Specify the web page you want to reference an {type?.name_singular.en}</p>
                </div>
            </div>}

        </div>

    </div>
  )
}
