import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'


import LocationgGlobalLayout from '../../components/locationLayout/layout'

import useNavigateTo from '../../hooks/useNavigateTo'
import Collapse, { Panel } from '../../../components/shared/Collapse'
import ObjectWithDropdown from '../../../components/shared/object/objectWithIcon/Withdrpdown'
import ResourceMenu from '../../../components/shared/menu/ResourceMenu'
import ReactSvg from '../../../components/shared/ReactSvg'
import { storageUrl } from '../../../services/api/constants'
import useSearchLocations from '../../hooks/useSearchLocations'

export default function DatacenterLocationLayout() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { goTo } = useNavigateTo()
    const { searchResults:recentlyDeployedServers } = useSearchLocations({
      query:'*',
      filter: `&& (cfs_type:=RegionalServers || cfs_type:=ThematicServers || cfs_type:=LocalClusters) && roots:=${params.id}`,
      sort:'created_at:asc, title.en:asc',
      limit: 7
    })

   
  
    
  return (
    <>
    <LocationgGlobalLayout
          collection={'Datacenters'}
          id={params.id!}
          loading={loading}
          selectedKey={params.id!}
          disabled={{filter:false, new:false, display:false,query:false}}
          publicationBar={false}          
          
        
   >
     <div className="pl-[11px] pt-[14px] overflow-auto height-explorer" >
      
          <Collapse defaultActiveKey={['deployed', 'pubs']} className='h-full flex flex-col'>
          <>
      
       
    
          <Panel header={<p className='text-groupe'>Recently deployed</p>} key={'deployed'}>
          <div className='flex flex-wrap pl-[10px]'>
              {recentlyDeployedServers && recentlyDeployedServers.map(server => (
                  <ObjectWithDropdown
                          title={server.document.title.en}
                          overlay={<ResourceMenu 
                            id={server.document.id}
                            onSuccess={() => {}}
                            type='locations'
                            items={{
                              shortcut:true
                            }}
                          />}
                          icon={<div className='relative w-full h-full '>
                              <ReactSvg src={`${storageUrl}${server.document.iconUrl}`} className='w-full h-full'
                             
                              />
                          
                            
                          </div>}
                          key={server.document.id}
                          id={server.document.id!}
                          description={<p className='truncate'>{server.document.breadcrumbs?.[0]?.en!}</p>
                            
                        
                              
                          }
                          active={server.document.id === params.volumeId}
                          onSelect={() => goTo(`/Datacenters/${params.id}/volumes/${server.document.id}`, {state: server.document})}
                      /> 

              ))}

          </div>
          </Panel>
          {/* <Panel header={<p className='text-groupe'>Paid publications</p>} key={'pubs'}>
          </Panel> */}
          
          </>
      </Collapse>
      

      </div>
 </LocationgGlobalLayout>
    </>
  )
}
