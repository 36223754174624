import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Server } from '../../../../../types';
import ServerWithCheck from '../../../components/ServerWithCheck';
import { getDatacenterLocalServers, getTree } from '../../../../../services/api/request';
import useNavigateTo from '../../../../hooks/useNvigateTo';

export default function Servers() {
    const [servers, setservers] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
    const params = useParams()
    const location = useLocation()
    const {goTo} = useNavigateTo()
    useEffect(() => {
            setloading(true)
          
            fetch(getTree({id:params.networkId!})).then(res =>res.json()).then(data =>  setservers(data.data)).finally(() => setloading(false))
           
        
      }, [params.networkId])

    const onClick = (server:Server) => {
        goTo(`/publish/publishin/${params.networkId}/${server.id}`, {state: {...location.state, server:server.title}})
    }
  return (
    <div className='grid grid-cols-4'>
        <Spin spinning={loading}>
            <div className='overflow-auto height-publishin pl-3 space-y-7'>
                    {servers?.map(serevr => (
                        <div key={serevr.id} className='space-y-2'>
                            <p className='text-group'>{serevr.title}</p>
                            <div className="-ml-2">
                                {serevr.children?.map(server => (
                                    <ServerWithCheck path={`/${location.state?.network}/${server.title}`} item={server} key={server.id} active={server.id === params.serverId} clickFn={() => onClick(server)}/>
                                ))}
                            </div>
                        </div>
                    ))}

            </div>
        </Spin>
        <div className="col-span-3">
            <Outlet/>
        </div>
    </div>
  )
}
