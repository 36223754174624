import { createContext, useContext, useMemo, useState } from "react";
import { Server } from "../../../../../../types";

type States = {
    selectedLocations:Server[]
}
type Functions = {
  
  addSelectedLocation: (data:Server) => void;
  removeSelectedLocation: (data:Server) => void;


}
type Props = States & Functions
const PublishinContext = createContext<Props>({} as Props);

export const usePublishinContext = () => useContext(PublishinContext);

export const PublishinContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    selectedLocations:[]
  })
  

  const value = useMemo(() => {
    

  
    const addSelectedLocation = (data:Server) => {
      setState((prev) => ({
        ...prev,
        selectedLocations: [...prev.selectedLocations, data],
      }))
    }
    const removeSelectedLocation = (data:Server) => {
        setState((prev) => ({
          ...prev,
          selectedLocations: prev.selectedLocations.filter(location => location.id !== data.id),
        }))
      }
    return {
      ...state,
      removeSelectedLocation,
      addSelectedLocation
    }
  }, [state])

  return <PublishinContext.Provider value={value}>{children}</PublishinContext.Provider>;
};