import React, { useEffect, useState } from 'react'
import { searchLocations } from '../../services/search/search';
import { VolumeSearch } from '../../types';

type Props= {
    query: string,
    filter: string,
    sort?: string,
    limit?:number
}
export default function useSearchLocations({ query, filter, sort, limit } :Props) {
    const [searchResults, setsearchResults] = useState<{document:VolumeSearch}[]>([]);
    const [resultsLength, setresultsLength] = useState(0);


    useEffect(() => {
        (async()=>{
       
            const res:any = await searchLocations({q:query, limit, sort, filter})
            setsearchResults(res.hits)
            setresultsLength(res.found)
       
        })() 
      }, [query, filter, sort, limit])
  return {
    searchResults, 
    resultsLength
  }
}
