
import { useNavigate } from 'react-router-dom';

export default function Home() {
    
    const navigate = useNavigate()
    const handleSignup = () => {
        navigate('/connect/signup')
    }
  return (
   
       

        <div className='space-y-4'>
            <button className='text-[17px] opacity-70 hover:opacity-100 transition-all rounded-full py-3.5 px-4 w-full border-2 border-main bg-skin-fill-muted' onClick={handleSignup}>
                Create an account
            </button>
            <button className='text-[17px] opacity-70 hover:opacity-100 transition-all rounded-full py-3.5 px-4 w-full border-2 border-main bg-skin-fill-muted' onClick={() => navigate('/connect/login')}>
                Already have an acctount?, Login
            </button>
            <button className='text-[17px] opacity-70 hover:opacity-100 transition-all rounded-full py-3.5 px-4 w-full border-2 border-main bg-skin-fill-muted' onClick={() => navigate('/connect/login')}>
                Discover
            </button>
        </div>
     
        
  )
}
