import React, { useEffect, useState } from 'react'
import { Server, ServerWithHomeDesq } from '../../../../../types';
import { getAllDomains, getNodeDomains } from '../../../../../services/api/request';
import { Spin } from 'antd';
import WithDivider from '../../../../../components/shared/WithDivider';
import Network from './Network';
import Univers from './Univers';
import Nodes from './Nodes';
import Domains from './Domains';


type Props = {
    openDomain: (name:string) => void
}
export default function AllDomains({openDomain} : Props) {

    const [selectedUniver, setselectedUniver] = useState<Server | null>(null);
    const [selectedNode, setselectedNode] = useState<Server | null>(null);
  
  

    useEffect(() => {
        setselectedNode(null)
    }, [selectedUniver]);
    
  

 
      
  return (
    <div className='h-full w-full overflow-hidden'>

        
          
    
        <div className=' flex space-x-4'>
            <div className='w-[250px]'>
                <Univers
                    selectedUniver={selectedUniver}
                    setselectedUniver={setselectedUniver}
                />

            </div>
            <div className='w-[250px]'>
                    {selectedUniver && (
                        <Nodes
                            selectedNode={selectedNode}
                            setselectedNode={setselectedNode}
                            nodes={selectedUniver.children}
                        />
                    )}
                </div>
            <div className='w-[250px]'>
            
                <div>
                    {selectedNode && (
                        <Domains
                            selectedNode={selectedNode}
                            openDomain={openDomain}
                        />
                    )}
                </div>

            </div>

        </div>
           


    </div>
  )
}
