import React, { useRef, useState } from 'react'

type Props = {
    sidebar:JSX.Element | null,
    children:JSX.Element,
    hideSidebar:boolean,
    setWidth: (value: number) => void
}
export default function WithSidebar({sidebar, children,hideSidebar, setWidth} : Props) {
  const [sidebarWidth, setSidebarWidth] = useState(300);
  const sidebarRef = useRef<any>(null);

  const rsMouseDownHandler = (e:any) => {
    const x = e.clientX;
    const sbWidth = window.getComputedStyle(sidebarRef.current).width;
    const initialWidth = parseInt(sbWidth, 10);
    const mouseMoveHandler = (e:any) => {
      // const dx = x - e.clientX; 
      const dx = e.clientX - x; 
      const newWidth = initialWidth + dx;

      if (newWidth >= 200 && newWidth<=450) {
        setSidebarWidth(newWidth);
        setWidth(newWidth)
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mouseup', mouseUpHandler);
      document.removeEventListener('mousemove', mouseMoveHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  return (
    <div className='flex h-full overflow-hidden'>
      {sidebar && (
        <div onMouseDown={rsMouseDownHandler}
        style={{ cursor: 'ew-resize' }} className=''>

          <div className="" ref={sidebarRef} style={{ width: `${sidebarWidth}px` }}>
              {sidebar}
          </div>
        </div>

      )}
        <div className={`flex-1 flex flex-col ${hideSidebar && 'bg-sub-windows relative -ml-[260px] shadow-panel'}`}>
            {children}
        </div>
    </div>
  )
}
