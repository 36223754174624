import { message, notification, Steps } from 'antd';
import React, { useEffect, useState } from 'react'

import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { clientAccount, Organization, ProData } from '../../../types';
import { useAuthContext } from '../../../context/AuthContext';
import { useSignupContext } from '../../context/SignupContext';
const { Step } = Steps;

export default function Subscribe() {
    
    const { user } = useAuthContext()

    const {  planId, profile, setplanId, setprofile, setaccountType, currentStep} = useSignupContext()


    const [searchParams] = useSearchParams()
    useEffect(() => {
        
        if(searchParams.get('planId') && searchParams.get('tierId')){
            setplanId(searchParams.get('tierId')!)
            setaccountType(searchParams.get('type')!)
            setprofile({name:searchParams.get('profile')!, id: searchParams.get('planId')!})
            
        }

    }, []);
    

   console.log(currentStep)
  return (
    <div className='flex flex-col h-full'>
        <div className='flex-1 flex space-x-10 h-full w-full  m-auto border-t border-main'>
            <div className="pt-4 pb-2 pl-10  w-[310px] h-full space-y-14 ">
                <div>
                    <p className="text-[22px] font-semibold flex-1 leading-[27px] mt-[13px]">Your account and subscription step by step
                    </p>
                   
                </div>
                <Steps direction='vertical' current={currentStep -1}> 
                    <Step title="Choose your profile" description='' status={profile.id ? 'finish' : 'wait'} />
                    <Step title="Choose your plan" description='' status={planId ? 'finish' : 'wait'}/>
                    <Step status={user?.id ? 'finish' : 'wait'} title="Create your account" description='' disabled={!!user?.id}  />
                    
                    <Step status={user?.emailVerified ? 'finish' : 'wait'} title="Validate your account" description='' disabled={user?.emailVerified}  />

                   
                    <Step title="Complete your informations" description='' />
                    <Step title="Subscribe" description='' />
                </Steps>
            </div>
            <div className="flex-1 rounded border-l border-main h-screen overflow-auto pt-4 pb-2  pr-3">
                <Outlet/>
                {/* {(current === 2 || (current ===3 && user?.emailVerified)) && (
                    <CreateAccount profileName={profileName} nextStep={() => setCurrent(prev => prev+1)} prevStep={() => setCurrent(prev => prev-1)} url={`https://teddra-email-verification.vercel.app/verifyEmail/?planId=${planId}&tierId=${tierId}&profile=${profileName}&type=${accountType}`}/>
                )}
                {current === 3 && !user?.emailVerified && (
                    <Vlidate profileName={profileName} nextStep={() => setCurrent(prev => prev+1)} prevStep={() => setCurrent(prev => prev-1)} url={`https://teddra-email-verification.vercel.app/verifyEmail/?planId=${planId}&tierId=${tierId}&profile=${profileName}&type=${accountType}`}/>
                )}
                {current === 0 && (
                    <Profile setprofileName={setprofileName} planId={planId} setPlanId={id => setplanId(id)} nextStep={() => setCurrent(prev => prev+1)} prevStep={() => setCurrent(prev => prev-1)} />
                )}
                {current === 1 && (
                    <PlansCom profileName={profileName} setaccountType={(data:"INDIVIDUAL" | "ORGANIZATION") => setaccountType(data)} tierId={tierId} planId={planId} setTierId={id => settierId(id)} nextStep={() => setCurrent(prev => prev+1)} prevStep={() => setCurrent(prev => prev-1)}/>
                )}
                {(current === 4 && accountType ==='ORGANIZATION') && (
                    <OrganizationCom profileName={profileName}  data={organization} setData={setorganization} nextStep={() => setCurrent(prev => prev+1)} prevStep={() => setCurrent(prev => prev-1)}/>
                )}
                {(current === 4 && accountType ==='INDIVIDUAL') && (
                    <Pro profileName={profileName} data={clientData} setData={setclientData} nextStep={() => setCurrent(prev => prev+1)} prevStep={() => setCurrent(prev => prev-1)}/>
                )}
                {(current === 5 && accountType ==='ORGANIZATION' && organization) && (
                    <SubscribePage data={organization} planId={planId} tierId={tierId} prevStep={() => setCurrent(prev => prev-1)}  />
                )}  */}
            </div> 
        </div>

    </div>
  )
}
