import React, { useState } from 'react'


export default function Mydesk() {
  
  return (
    <div className='w-full'>
        <div className="px-7 flex items-center justify-between w-full py-3.5 border-b border-main">
          
            <p className='text-[20px] font-bold'>My desk</p>

        </div>
        <div className="space-y-7 mt-7 px-7">
           
            <div className='space-y-3'>
                
                
            </div>
        </div>
    </div>
  )
}
