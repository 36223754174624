import * as React from "react"
import { IconOptions } from "../../../types"

const Map = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 4010 4010"
    {...props}
  >
    <defs>
      <style>{".fil1{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <g id="_1823563442944">
        <path
          d="M4010 4010H0V0h4010z"
          style={{
            fill: "none",
            fillRule: "nonzero",
          }}
        />
        <path
          d="M3270 3320c50-30 80-90 80-140v-500h-340v410l-670 440V1510c0-60-20-100-60-140l-840-660c-20-20-60-40-100-40-30 0-70 10-90 30L240 1370c-40 30-70 80-70 140v2340c0 60 30 120 90 140 20 20 50 20 70 20 40 0 70-10 100-20l900-610 740 600c30 20 70 30 100 30 50 0 70-10 100-30l1000-660zM500 1590l670-440v1940l-670 440V1590zm1510 1910-500-400V1180l500 410v1910zM3180 2450l130-180c20-20 540-720 540-1270 0-370-300-670-670-670s-670 300-670 670c0 550 510 1250 530 1270l140 180zm0-1780c180 0 330 150 330 330 0 270-180 640-330 880-150-240-340-610-340-880 0-180 150-330 340-330z"
          className="fil1"
        />
        <path
          d="M3350 1000c0-90-80-160-170-160s-170 70-170 160c0 100 80 170 170 170s170-70 170-170z"
          className="fil1"
        />
      </g>
    </g>
  </svg>
)
export default Map
