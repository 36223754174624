import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6142 20.813C14.4341 20.9931 14.1899 21.0944 13.9352 21.0944H12V19.1592C12 18.9045 12.1013 18.6602 12.2814 18.4802L18.7823 11.9744C19.0923 11.6644 19.5128 11.4902 19.9512 11.4902C20.3895 11.4902 20.81 11.6644 21.12 11.9744V11.9744C21.4302 12.2843 21.6045 12.7047 21.6045 13.1432C21.6045 13.5817 21.4302 14.0022 21.12 14.312L14.6142 20.813Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3833 14.3799L18.7171 16.7137"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3728 15.0576L21.2371 15.922V15.922C21.6921 16.3779 21.6921 17.1161 21.2371 17.5719L19.6611 19.148"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.198 9.56907H12.9604"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.198 13.4109H11.0396"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.11885 21.0938H6.23765C4.6464 21.0938 3.35645 19.8039 3.35645 18.2126V6.68784C3.35645 5.0966 4.6464 3.80664 6.23765 3.80664H17.7624C19.3537 3.80664 20.6436 5.0966 20.6436 6.68784V8.60864"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={10} y={2} width={12} height={4} fill="white" />
    <path
      d="M13.8398 5H13.2031L12.0488 3.24023C11.9811 3.13737 11.9342 3.0599 11.9082 3.00781H11.9004C11.9108 3.10677 11.916 3.25781 11.916 3.46094V5H11.3203V2.19922H12L13.1113 3.9043C13.1621 3.98112 13.209 4.05729 13.252 4.13281H13.2598C13.2493 4.06771 13.2441 3.93945 13.2441 3.74805V2.19922H13.8398V5ZM16.1602 5H14.4805V2.19922H16.0957V2.71289H15.1113V3.33594H16.0273V3.84766H15.1113V4.48828H16.1602V5ZM20.3555 2.19922L19.6152 5H18.916L18.4512 3.20312C18.4264 3.10938 18.4115 3.00456 18.4062 2.88867H18.3984C18.3867 3.01628 18.3698 3.12109 18.3477 3.20312L17.8711 5H17.1426L16.4062 2.19922H17.0957L17.4902 4.06445C17.5072 4.14388 17.5195 4.25065 17.5273 4.38477H17.5391C17.5443 4.28451 17.5632 4.17513 17.5957 4.05664L18.1035 2.19922H18.7793L19.2383 4.08008C19.2552 4.14909 19.2702 4.24935 19.2832 4.38086H19.291C19.2962 4.27799 19.3099 4.17383 19.332 4.06836L19.7188 2.19922H20.3555Z"
      fill="#F11515"
    />
  </svg>
)
export default SvgComponent
