import React from 'react'
import AllPublications from '../../publications/all'
import { useParams } from 'react-router-dom'

export default function AllPublicationsPublicDrive() {
    const params = useParams()
  return (
    <AllPublications suffix={`specializeds/${params.coll}/${params.id}/node/`}/>
  )
}
