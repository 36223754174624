import * as React from "react"
import { IconOptions } from "../../../types"
const Folder = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Calque 1"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M4.8 39.6V8.4h10.8l4 5.28a4.83 4.83 0 0 0 3.8 1.92h19.8v24Zm38.4-28.8H23.4l-4-5.28a4.83 4.83 0 0 0-3.8-1.92H4.8A4.8 4.8 0 0 0 0 8.4v31.2a4.8 4.8 0 0 0 4.8 4.8h38.4a4.8 4.8 0 0 0 4.8-4.8v-24a4.81 4.81 0 0 0-4.8-4.8Z"
     
    />
  </svg>
)
export default Folder
