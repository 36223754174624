import React from 'react'

export default function Settings() {
    // const { user } = useSelector((state:any) => state.auth)
  return (
    <div className='p-5 height-with-1bars overflow-auto'>
        <p className="font-extrabold text-[20px]">Account settings</p>
        <div className='space-y-10 mt-5'>
            <div>
                <div className='space-y-2'>
                    <p className="font-bold">Email adresse</p>
                    {/* <input type="text" className='rounded w-[200px] ' value={user.email} /> */}
                </div>
                <button className='mt-4 px-4 py-1.5 rounded bg-skin-fill-muted'>Change</button>
            </div>
            <div className=''>
                <div className='space-y-2'>
                    <p className="font-bold">Password</p>
                    <div className='flex space-x-3'>
                        <input type="password" placeholder='Password' className='rounded w-[200px] '/>
                        <input type="password" placeholder='Confirm password' className='rounded w-[200px] block'/>

                    </div>
                </div>
                <button className='mt-4 px-4 py-1.5 rounded bg-skin-fill-muted '>Change</button>
            </div>
            <div>
                <div className="space-y-2 max-w-[400px]">
                    <p className="font-bold">Delete account</p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum, perspiciatis maxime. Ipsa sed nulla nihil nobis iusto, voluptas totam quam, nam, aperiam fugiat distinctio ab ipsam facere magnam ex veniam?
                    </p>
                </div>
                <button className='mt-4 px-4 py-1.5 rounded text-red-600 !border-red-600 border'>Delete</button>
            </div>
        </div>
    </div>
  )
}
