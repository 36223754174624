import { useEffect, useState } from "react";
import { getVolum } from "../services/api/request";

const useFetchVolum = (
    volumId: string | undefined,
    volumColl: string,
    domainId?:string
  
  ) => {
    const [volume, setvolume] = useState<any>(null);
    const [loading, setloading] = useState(false);
    useEffect(() => {
      const fetchVolum = async () => {
        if (!volumId || !volumColl) {
          return;
        }
        setloading(true);
        try {
          const response = await fetch(getVolum({ volumId, volumColl, domainId }));
          const data = await response.json();
          setvolume({ ...data.data, title: data.data.title.en });
      
        } catch (error) {
          console.error(`Failed to fetch ${volumColl}:`, error);
          // Optionally set error state here
        } finally {
          setloading(false);
        }
      };
  
      fetchVolum();
    }, [volumId, volumColl, domainId]);

    return {
      volume,
      loading
    }

 
  };

  export default useFetchVolum