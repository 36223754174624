import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Server } from '../../../../../types';
import ServerWithCheck from '../../../components/ServerWithCheck';
import useNavigateTo from '../../../../hooks/useNvigateTo';


export default function Directories() {
    const [directories, setdirectories] = useState<Server[]>([]);
    const location = useLocation()
    const params = useParams()
    const {goTo} = useNavigateTo()

    useEffect(() => {
        if(location.state?.directories){
            setdirectories(location.state.directories)
        }
    }, [location.state]);
  return (
    <div className='grid grid-cols-2'>
        <div className='height-publishin overflow-auto pl-3'>
            <div className='-ml-2'>
                {directories?.map(directory => (
                    <ServerWithCheck path={`/${location.state?.network}/${location.state?.server}/${location.state?.drive}/${directory.title}`} item={directory} active={directory.id === params.directoryId} clickFn={() => goTo(`/publish/publishin/${params.networkId}/${params.serverId}/${params.driveId}/${directory.id}`, {state: {...location.state, directory:directory.title ,folders:directory.children}})}/>
                ))}
            </div>
        </div>
        <div>
            <Outlet/>
        </div>
    </div>
  )
}
