import React from 'react'
import { ReactSVG } from 'react-svg'
import { Server } from '../../../../../types'
import OneLine from '../../../../../components/shared/object/oneLine'
import ResourceMenu from '../../../../../components/shared/menu/ResourceMenu'


type Props = { 
    setRevalidate : () => void,
    network: {server: Server, color:string, textColor: string, border?:boolean},
    onSelect: (network:Server) => void, 
    active: boolean,
    withDropdown: boolean,
    
}
export default function Network({ setRevalidate, network, onSelect, active, withDropdown } : Props) {
    
  return (
    <>
    

            <OneLine
                dropdownProps={withDropdown ? {
                    overlay:<ResourceMenu
                    id={network.server.id}
                    onSuccess={setRevalidate}
                    type='datacenters'
                    items={{}}
                    
                    
                
                    />
                } : null}
                disabled={network.server.status === 'coming_soon'}
                active={active}
                onClick={() => network.server.status === 'coming_soon' ? {} : onSelect(network.server)}
            
            >
              
                <div className='flex items-center space-x-2'>
                    <div className={`p-1.5 rounded ${network.border && 'border border-main'}`} style={{background: network.color, color: network.textColor}}>
                        <ReactSVG
                            src={network.server.iconUrl} 
                            className='icon-sm'
                            beforeInjection={(svg) => {
                                svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
                            }}
                            />

                    </div>
                <p className='truncate'>{network.server.title}</p>
            </div>
            </OneLine>
  
    
    </>
  )
}
