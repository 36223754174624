import React from 'react'
import AllPublications from '../../publications/all'
import { useParams } from 'react-router-dom'

export default function AllPublicationsPrivateVolume() {
    const params = useParams()
  return (
    <AllPublications suffix={`${params.coll}/${params.id}/`}/>
  )
}
