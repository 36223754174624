import React, { useRef } from 'react'

import CardWithWebsiteLayout from '../WithWebsite/Layout'
import Description from '../WithWebsite/Description'

type PartnerType = {
  partner: any,
  location?:any,
  imgHeight?:string,
  active?:boolean,
  small?:boolean, 
  country?:string
}
export default function PartnerWithWebsite({ partner, imgHeight,active, small, country } : PartnerType) {

 
  return (
    <div className={` ${active && 'bg-skin-fill-inverted text-skin-inverted'} rounded flex space-x-3`}>
      <CardWithWebsiteLayout
        item={{
          title: partner.name,
          // website:  <Website url={partner.website}/>
          image: partner.logo,
          cover: partner.coverUrl
        }}
        small={small}
        imgClassName={`h-[190px] w-full ${imgHeight && imgHeight}`}
        border
        active={active}
        country={country}
      />
      {
        !small && (

        <Description
          title={partner.name}
          description={partner.description.en}
          website={partner.webResources.website}
          type='Partner'
          country={country}
          active={!!active}
        />
        )
      }
    
    </div>
  )
}
