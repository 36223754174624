import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Popover, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { Tier } from '../../../../types';
import { showTier, subscrib } from '../../../../services/api/request/plans';
import { fetchInt } from '../../../../utils/api/fetchInt';
import StepsButtons from '../../../components/stepsButtons';
import Icon from '../../../../components/icons/Icon';
import { features } from '../../../components/Tier';
import { useAuthContext } from '../../../../context/AuthContext';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';


export default function SubscribePage() {
    const [autoRenewale, setautoRenewale] = useState(true);
    const [loading, setloading] = useState(false);
    const [tier, settier] = useState<Tier | null>(null);
    const { user } = useAuthContext()
    const { profile, planId, organizationData, setCurrentStep, accountType } = useSignupContext()
    const navigate = useNavigate()

    useEffect(() => {
    
        setCurrentStep(6)
      }, []);
    useEffect(() => {
        setloading(true)
        fetch(showTier(profile.id, planId)).then(res => res.json()).then(data => settier({...data.data.tierData})).catch(err => console.log(err)).finally(() => setloading(false))
    }, [planId, profile.id]);
    const prevStep = () => {
        navigate(`/connect/signup/steps/5/${accountType === 'INDIVIDUAL' ? '/individual' : 'organization'}`)
    }
    const submit = async () => {
        const submittedData = {
            ...organizationData,
            adminIntrests : organizationData?.adminIntrests? organizationData.adminIntrests.map((i:any) => i.label) : [],
            planId: profile.id, 
            tierId:planId,
            isAutoRenewal: autoRenewale
        }

        const req = await fetchInt(subscrib, {
            method: 'POST', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(submittedData)
        })
        const res = await req.json()
        if(res.data){
            // setpaymetUrl(res.data.paymentUrl)
            // openPageViewer({type: 'payment', data:res.data.paymentUrl, title: 'Payment', icon:{src: 's', type: 'icon'}})
            window.open(res.data.paymentUrl, '_blank')

        }
        console.log(res)
    }
  return (
    <div className='space-y-2 h-full flex flex-col relative'>
        <div className="flex-1">
            <div className='border-b border-main pb-6'>
                <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">   
                    Order summury
                </p>
            
            </div>
            <div className='w-max space-y-3'>
                <div className="flex space-x-14 mt-5 ml-8 w-max">
                    {tier && (
                        <div className=" w-[300px]">

                            <div className={`relative mx-auto flex-1 rounded p-1.5  border border-gray-500`}>
                            
                                <div className=" pt-7 px-5">
                                <Icon name={'File'} className='w-[80px] h-[80px] mx-auto mb-5'/>
                                    <div className={`flex space-x-1 text-center`}>
                                        <div className='flex-1 space-y-1'>
                                            <p className='text-[22px] font-semibold flex-1 leading-[27px]' style={{lineHeight:'24px'}}>{tier.title.en}</p>
                                            <p className='mb-[20px]'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                
                                        </div>
                                       
                
                                    </div>
                                    <div className='h-[120px] flex items-center justify-center text-center'>
                                            <div className='mb-[11px] pt-5'>
                                                {tier.price !== null ? (
                                                    <>
                                                        <p className='text-[44px] font-extrabold'>{tier.price}$</p>
                                                        <p>/per month</p>
                                                    
                                                    </>
                
                                                ): (
                                                    <div>
                                                        <button className='px-5 py-2 bg-skin-fill-inverted text-skin-inverted text-[14px] font-extrabold rounded'>Contact Us</button>
                                                    </div>
                                                )}
                                            </div>
                
                                
                                    </div> 
                                    
                                </div>
                                <div className='space-y-5 pt-5 pl-5 pr-3 pb-5 bg-sub-windows rounded text-skin-base'>
                                    <div className='space-y-4'>
                                        {tier.features.map((ph, i) => (
                                            <div className='flex space-x-3 ' key={i}>
                                                {/* <CheckCircleIcon className='w-4 h-4 fill-transparent mt-.5'/> */}
                
                                                <p className='flex-1 '>{(features as any)[ph.code].includes('**') ? (
                                                    <>
                                                        {(features as any)[ph.code].replace('**', ph.value)}
                
                                                    </> 
                                                ): (
                                                    <>
                                                        {(features as any)[ph.code]}
                
                                                    </> 
                                                )}</p>
                                                <Popover placement='right' content={
                                                    <div className='bg-sub-windows rounded-lg p-3 w-[250px]'>
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Assumenda doloribus enim itaque suscipit, neque amet deserunt atque corrupti deleniti qui rem consectetur aspernatur temporibus.!</p>
                                                    </div>
                                                } title="">
                                                    <QuestionMarkCircleIcon className='icon-mini fill-transparent opacity-20 hover:opacity-100 mt-1'/>
                                                </Popover>
                                                
                                            </div>
                                        
                
                                        ))}
                                        
                                        
                                    </div>
                                
                                
                                </div>
                            </div>
                        </div>

                    )}
                    <div className='space-y-5 border border-gray-500 rounded w-[300px] p-5'>
                        <div className='space-y-3'>
                            <p className='font-bold'>Auto renewale</p>
                            <Switch checked={autoRenewale} onChange={v => setautoRenewale(v)} />
                        </div>
                        <div className='space-y-3'>
                            <p className="font-bold">Client infos</p>
                            <div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Name:</p>
                                    <p>{user?.name}</p>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Email:</p>
                                    <p>{user?.email}</p>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Country:</p>
                                    <p>{organizationData?.adminCountry}</p>
                                </div>
                                {/* <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">City:</p>
                                    <p>{user?.adminCity}</p>
                                </div> */}
                            </div>
                        </div>
                        <div className='space-y-8'>
                            <p className="font-bold">Organization infos</p>
                            <div className='space-y-3'>
                                 <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Logo:</p>
                                    <img src={organizationData?.organizationLogo} alt="" className='w-[100px] h-[100px] rounded'/>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Name:</p>
                                    <p>{organizationData?.organizationName}</p>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Activity:</p>
                                    <p>{organizationData?.organizationActivity}</p>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Email:</p>
                                    <p>{organizationData?.organizationEmail}</p>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Phone:</p>
                                    <p>{organizationData?.organizationPhone}</p>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <p className="font-bold w-[100px] text-end">Website:</p>
                                    <p>{organizationData?.organizationWebsite}</p>
                                </div>
                            </div>
                        </div>
                  

                    </div>
                </div>
                <div className='w-max ml-auto'>
                    <Spin spinning={loading}>
                        <button
                            disabled={loading}
                            onClick={submit}
                            className='px-7 py-2 rounded bg-skin-fill-inverted text-skin-inverted'
                        >
                            PAY 
                        </button>

                    </Spin>

                </div>

            </div>

        </div>
        {/* <Payment
            open={!!paymentUrl}
            onClose={() => setpaymetUrl('')}
            url={paymentUrl}
        /> */}
        <StepsButtons
            prevStep={prevStep}
            disable={false}
        
        />
    </div>
  )
}
