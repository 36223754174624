import { ReactSVG } from 'react-svg'
import { Highlight, Publication } from '../../../../types'
import Icon from '../../../icons/Icon'
import { storageUrl } from '../../../../services/api/constants'


type Props = {
    item: {document:Publication, highlight:Highlight} ,
    active:boolean,
    renderDate :(date:number) => string,
    handlePublicationSelection: (item:{document:Publication, highlight:Highlight}) => void
}
const convertToInlineElements = (html: string) => 
    html.replace(/<(div|p|h\d)[^>]*>/g, '<span>').replace(/<\/(div|p|h\d)>/g, '</span>');
export default function Blocks({item, active, renderDate, handlePublicationSelection} : Props) {
    
  return (
    <div className={`w-full border border-main rounded overflow-hidden cursor-pointer ${active ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'}`} onClick={() => handlePublicationSelection(item)}>
        {item.document?.thumbnail && (
            <div className='w-full h-auto relative'>
                <img src={item.document?.thumbnail} className='w-full h-full object-cover' alt="" />
                {item.document?.media && (
                    <div className='absolute right-0 bottom-0 px-3 py-1.5 bg-white bg-opacity-90 flex space-x-2 items-center'>
                        <p className='!text-black'>{item.document?.media.length}</p>
                        <Icon name='Image' className='icon-sm'/>
                    </div>

                )}
            </div>

        )}
        <div className='p-3 flex space-x-2 w-full'>
            <div className="relative icon-sm">
                <ReactSVG src={`${storageUrl}${item.document?.publicationType?.iconUrl}`} className='w-full h-full' />
            </div> 
            <div className='flex-1 '>
                <div className={`flex space-x-2 items-center ${active ? 'text-small' : 'text-muted'}`}>
                    <p>
                        {renderDate(item.document?.created_at)}
                    </p>
                    {/* <p>
                        {item.document?.organization.name}
                    </p> */}

                </div>
                    {item.highlight?.title?.[item.document?.local] ? <p className='text-title ellipsis-2 text-start' dangerouslySetInnerHTML={{__html: item.highlight?.title?.[item.document?.local]?.snippet!}}></p> : (
                    <p className='text-title ellipsis-2 text-start'>{item.document?.title[item.document?.local]}</p>)}
                    
                <p className={`ellipsis-6 description-max-h ${active ? 'text-small' : 'text-muted'} text-start`} 
                // dangerouslySetInnerHTML={{__html: item.document?.description[item.document?.local] ? item.document?.description[item.document?.local]! : 'No description'}}
                dangerouslySetInnerHTML={{
                    __html: item.document?.description[item.document?.local] 
                      ? convertToInlineElements(item.document?.description[item.document?.local]!) 
                      : 'No description'
                  }}
                ></p>
                          

            </div>
        </div>
     

    </div>

  )
}
