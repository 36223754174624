import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'


export default function useNavigateTo() {
    const navigate  = useNavigate()
    const [searchParams] = useSearchParams()

    const goTo = (to:string,props:any, noQuery?:boolean) =>{
      const query = searchParams.toString();
   
     
          navigate(`/stats${to}${!noQuery ? `?${query}` : ''}`, props )

        
      
      }
  return {
    goTo
  }
}
