import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MydeskContextProvider } from './context/MydeskContext'
import MydeskLayout from './Layout'
import MydeskHome from './pages/home'

import New from './pages/publications/New'
import Settings from './pages/account/Settings'
import PublishNavigation from './D-Publish/Routings'
import Myvolumes from './pages/volumes'
import CreateFolder from './pages/volumes/create/Folder'


export default function MydeskNavigation() {
 
  return (
    <MydeskContextProvider>
      <Routes>
        {/* <Route element={<PrivateRoute />}> */}
          <Route element={<MydeskLayout/>}>
              <Route path='/account/settings' element={<Settings/>}/>
              <Route path='/my-volumes' element={<Myvolumes/>}/>
              <Route path='/my-volumes/folder' element={<CreateFolder/>}/>



              
              {/* <Route element={<PinnedContextProvider><PinnedLayout/></PinnedContextProvider>}>
                <Route element={<PublicationsPage/>}>
                  <Route element={<></>} path='/pinned/:dcId/publications/all' />
                  <Route element={<PublicationDetailsPage/>} path='/pinned/:dcId/publications/all/:publicationId' />
                </Route>
                <Route element={<VolumesPage/>}>
                  <Route element={<></>} path='/pinned/:dcId/volumes/all' />
                  <Route element={<VolumesDetailsPage/>} path='/pinned/:dcId/volumes/all/:volumeId' />
                </Route>
                <Route element={<PinnedResources/>}>
                    <Route path='/pinned/:dcId' element={<></>}></Route>
                    <Route element={<PublicationDetailsPage/>} path='/pinned/:dcId/publications/:publicationId' />
                    <Route element={<VolumesDetailsPage/>} path='/pinned/:dcId/volumes/:volumeId' />
                </Route>
              </Route> */}

              <Route element={<New/>} path='/'/>
              <Route element={<PublishNavigation/>} path='/publish/*'/>
              {/* <Route element={<Ed/>} path='/publications/new/:publicationType'/> */}


              <Route path='/*' element={<></>}></Route>

          </Route>

        {/* </Route> */}

      </Routes>

    </MydeskContextProvider>
  )
}
