import { ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import React from 'react'
import Icon from '../../../../../../components/icons/Icon'
import Input from '../../../../../../components/shared/input'


type Props = {
    elRef: any,
    navigationActive: {prev:boolean, next:boolean}
    inputValue : string,
    handleInputChange: (e:any) => void,
    handleInputClick:(e:any) => void
}
export default function Header({elRef, navigationActive, inputValue, handleInputChange,handleInputClick} : Props) {
  return (
    <div className='flex pr-7 pl-[43px] border-b border-main h-[44px] bg-sub-windows items-center'>
        <div className='flex items-center space-x-3 flex-1 '>
            <div className='flex space-x-3 items-center'>
                <button>
                    <Icon className='icon-sm' name='Links'/>
                </button>
                <button onClick={() => elRef?.current?.goBack()} disabled={!navigationActive.prev} className='disabled:text-skin-muted' >
                    <ArrowSmLeftIcon className='icon-sm fill-transparent'  />
                </button>
                <button onClick={() => elRef?.current?.goForward()} disabled={!navigationActive.next} className='disabled:text-skin-muted' >
                    <ArrowSmRightIcon className='icon-sm fill-transparent'  />
                </button>
                <button onClick={() => elRef?.current?.reload()} className='disabled:text-skin-muted'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon-sm fill-transparent">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>

                </button>
            </div>
            <div className='min-h-[35px] w-full' >              
                <div className=" relative space-x-2 flex border border-main rounded px-2 w-full flex-1 outline-none bg-white items-center">     
                    <Icon name='Document' className='icon-sm' />
                    <Input 
                        value={inputValue}
                        onChange={handleInputChange}
                        className='py-1 min-h-[35px] w-full flex-1 !border-none' 
                        onKeyPress={handleInputClick}
                        placeholder='Insert an Url'
                    />
                
                </div>
                
                
            </div>
                
        </div>
        <div className="w-[50%] pl-5">
            <button className='flex space-x-2 px-4 py-1.5 bg-skin-fill-inverted text-skin-inverted items-center rounded-full disabled:opacity-20' disabled={!inputValue}>
                <Icon name='AI' className='icon-sm'/>
                <p>Generate with AI</p>
            </button>
        </div>

    </div>
  )
}
