


import { PathProps } from '../types';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbComp } from '../../../breadcrumb';
import Icon from '../../../../icons/Icon';
import { Search } from '../../../input/Search';
import { XCircleIcon } from '@heroicons/react/solid';



export default function PathBar(props: PathProps) {
  const navigate = useNavigate() 
  return (
    <div className=''>

      <div className="bg-topbar flex space-x-3">
       
          <div className="flex flex-1 space-x-2 bar-h menubar items-center pl-[29px] pr-2">
                <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${props.hidePanel.open ? ' rotate-180' :''}`} onClick={() => props.setHidePanel(!props.hidePanel.open)} disabled={props.hidePanel.disabled}>
                    <Icon name='PanelOpen' className='icon-sm'/>
                 </button>
                 <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' onClick={() => navigate(-1)} disabled={!(window as any).navigation?.canGoBack}>
                    <ArrowLeftIcon className='icon-sm'/>
                 </button>
                 <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' disabled={!(window as any).navigation?.canGoForward} onClick={() => navigate(1)}>
                    <ArrowRightIcon className='icon-sm'/>
                 </button>
                  <div className="flex-1">
                    {props.Breadcrumb && (
                        <BreadcrumbComp
                          className='flex-1'
                          {...props.Breadcrumb}
                          disabled={!!props.hidePanel.disabled}
                        
                        />

                    )}

                  </div>
                
                
                {props.extra && (
                  <div className="rounded  mx-2">
                    {props.extra}
                  </div>

                )}
                

              {props.search && (
                <div className="no-dragable relative">
                  <Search
                    placeholder='Search'
                    {...props.search}
                    className='bg-input rounded min-w-[370px] border border-fill-inverted'
                  />
                  <button className='absolute top-[8px] right-[8px] opacity-50 hover:opacity-100' onClick={props.onCncelSearch}>
                    <XCircleIcon className='icon-sm'/>
                  </button>

                </div>

               )} 
          
            
            </div>

        </div>
    </div>
  )
}
