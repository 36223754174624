
import React, { useEffect, useRef, useState } from 'react'



import { Form } from 'antd';
import Header from './pageViewer/Header';
import PageViewer from './pageViewer';
import EditForm from './EditForm';
import { usePublishContext } from '../../../context/publishContext';

export default function EditLink() {
    const [url, seturl] = useState('')
    const elRef = useRef<any>(null)
    const [inputValue, setinputValue] = useState('')
    const [navigationActive, setnavigationActive] = useState({prev: false, next:false})
    const { setFormat, setEditData,setActive, editData, type, isLoaded } = usePublishContext()
    const [errors, seterrors] = useState<any>({})

    useEffect(() => {
      setFormat('link')
      setActive({edit:true})
     
    }, []);
   

   
   const handleInputChange = (e:any) => {
    setinputValue(e.target.value)
   }
   const handleInputClick = (e:any) => {
    if(e.key === 'Enter'){
        seturl(inputValue)
    }
   }
   
   const handleValuesChange = (changedValues:any,allValues:any) => {
    const { title, keywords, description, url} = allValues
    setEditData(allValues)
    seterrors((prev:any) => {
        const state = {...prev}
        state[Object.keys(changedValues)[0]] = undefined
        return state
    })
    if(title && keywords && url && description){
        seterrors({})
        setActive({publish:true})
    }else{
        setActive({publish:false})
    }
   }
  
  return ( 
    <>
    {(type && isLoaded) && (
        <Form 
        className='h-full flex flex-col'
        onValuesChange={handleValuesChange}
        initialValues={editData}
        
         >
              <Header elRef={elRef} inputValue={inputValue} navigationActive={navigationActive} handleInputChange={handleInputChange} handleInputClick={handleInputClick} />
            <div className='flex h-full flex-1 bg-sub-windows' >
                <div className='flex-1 h-full'>
                
                    <PageViewer url={url ? url : editData?.url} elRef={elRef} setinputValue={setinputValue} setnavigationActive={setnavigationActive}   />
                </div>
                <div className="w-[50%] bg-sub-windows height-with-2bars overflow-auto pb-4">
                    <div className="pt-5 " >
                        <EditForm errors={errors} seterrors={seterrors} edit={false} url={url} initDescription={editData?.description} />

                    </div>
                </div>
            </div>
        </Form>

    )}
    
    </>
    
                    
    

  )
}

