import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import useNavigateTo from '../../hooks/useNavigateTo'

import { getDomainCollaborators } from '../../../services/api/request'
import { PartnerType } from '../../../types'
import Collapse, { Panel } from '../../../components/shared/Collapse'


export default function WorldPartners() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const [partners, setpartners] = useState<null | {title:string, id:string, children: PartnerType[]}[]>(null)
  
    useEffect(() => {
      const id = params.desqId
      if( id){
          setloading(true)
          fetch(getDomainCollaborators({domainId: id})).then((res) => res.json()).then(data => {
              setpartners(data.data)
              console.log(data.data)
          }).catch(err => console.log(err)).finally(() => setloading(false))

      }
  }, [params.desqId])

  return (


     <div className="pl-[11px] pt-[14px] overflow-auto height-explorer" >
      
          <Collapse defaultActiveKey={['0', '1', '2']} className='h-full flex flex-col'>
          <>
          {partners && partners.length > 0 && (
            partners.map((continent, i) => (

              <Panel header={<p className='text-groupe'>{continent.title}</p>} key={`${i}`}>
                <div className='flex mt-[10px] flex-wrap pl-[17px] gap-5 pb-10'>
                  {continent.children.map(part => (
                    <div className="w-[130px] py-2 flex flex-col space-y-2 items-center bg-hover-transparent cursor-pointer rounded" key={part.id} onClick={() => {}}>
                        <img src={part.logo} className='w-[60px] h-[60px] rounded' alt="" />
                        <div>
                            <p className='text-center ellipsis-1 px-1'>{part.name}</p>
                            <p className='text-center ellipsis-2'>({part.volums?.map((v,i) => `${i > 0 ? ' / ' : ''}${v.volum_name.en}`)})</p>
 
                        </div>
                    </div>
                  ))}
                  

            
                  
                </div>

              </Panel>
            ))

          )}
       
          
          </>
      </Collapse>
      

      </div>
  )
}
