import React from 'react'
import Icon from '../../../../../components/icons/Icon'
import { VolumeSearch } from '../../../../../types'

type Props = {
  openDomain : () => void
}
export default function DomainDropdown({ openDomain } : Props) {
  return (
    <div className='w-[170px] bg-white rounded shadow'>
        <button className='flex space-x-2 items-center bar-h px-3 bg-hover-transparent w-full' onClick={openDomain}>
            <Icon className='icon-sm' name='Open'/>
            <p>Open</p>
        </button>
        
        <button className='flex space-x-2 items-center bar-h px-3 bg-hover-transparent w-full'>
            <Icon className='icon-sm' name='Pin'/>
            <p>Pin</p>
        </button>
    </div>
  )
}
