import * as React from "react"
import { IconOptions } from "../../../types"
const OpenPage = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
   
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 1650 1650"
    {...props}
  >
    <defs>
      <style>{".fil19{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <path
        id="_2746379813744"
        d="M1650 1650H0V0h1650z"
        style={{
          fill: "none",
          fillRule: "nonzero",
        }}
      />
      <g id="_2746379814608">
        <path
          d="M830 760V620H280v140zM280 1030h410V900H280z"
          className="fil19"
        />
        <path
          d="M140 140h690v270h270v280h140V320L920 0H140C60 0 0 60 0 140v1240c0 70 60 140 140 140h550v-140H140V140z"
          className="fil19"
        />
        <path
          d="M1650 1120h-290l-120-290-120 290H830l250 220-140 310 300-190 300 190-140-310z"
          className="fil19"
        />
      </g>
    </g>
  </svg>
)
export default OpenPage
