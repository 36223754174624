import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Spin, Tooltip, message, notification } from 'antd'
import { DetailsActions } from '../../../types'
import { generateSearchVolumKey } from '../../../../../../../utils/utils'
import { getPublication, getVolume, getWebPage } from '../../../../../../../services/search/search'
import { ButtonSm } from '../../../../../Buttons/Small'
import Divider from '../../../../../Divider'
import { storageUrl } from '../../../../../../../services/api/constants'


type Props = DetailsActions
export default function DetailsActionsComp(props : any) {
    const params = useParams()
    const location = useLocation()
    const [url, seturl] = useState('');
    const [loading, setloading] = useState(false);

    useEffect(() => {
      
     console.log(location.state)
    }, [location.state]);
 
    useEffect(() => {
      if(location.state){
        seturl(location.state.url)

      }else{
        if(params.publicationId || params.websitePublicationId){
          setloading(true)
          getPublication({id:params.publicationId??params.websitePublicationId!}).then((res:any) => {
            seturl(res.url)
            setloading(false)
          })

        }else if(params.webpageId){
          setloading(true)
          getWebPage({id:params.webpageId}).then((res:any) => {
            seturl(res.url)
            setloading(false)
          })
        }else{
          seturl('')
        }
      }
    },[location.state, params.publicationId, params.webpageId, params.websitePublicationId])


   
  return (
   <div className='flex items-center'>

         {(params.publicationId || params.webpageId || params.websitePublicationId) && (
                <>
                  <Tooltip title='Object'>
                    <ButtonSm icon='Document' active={props?.mode === 'object'} onClick={() => props?.setObjectDisplayMode('object')} iconSize='mini'/>
                  </Tooltip>
                  <Tooltip title='Preview'>
                    <ButtonSm icon='ModeUrl' active={props?.mode === 'url'} onClick={() => props?.setObjectDisplayMode('url')} iconSize='mini'/>
                  </Tooltip>
                
                </>
            )}
            <Spin spinning={loading}>
              <Tooltip title='Open'>
                <a href={url} className='!text-inherit' target="_blank" rel="noopener noreferrer" aria-disabled={loading || !url}>
                  <ButtonSm icon='OpenWindow' disabled={((!url) || loading)} iconSize='mini' className={'disabled:fill-skin-muted bg-hover-transparent p-1.5 rounded disabled:opacity-50'}/>

                </a>
              </Tooltip>

            </Spin>
            <div className='h-full flex items-center mx-2'>
              <Divider />

            </div>
        <Tooltip title='Share'>
          <ButtonSm icon='Share' iconSize='mini' />
        </Tooltip>

        <Spin spinning={false}>
          <Tooltip title={'Pin'}>
            <ButtonSm icon={'Pin'} iconSize='mini' />
          </Tooltip>
        </Spin>

          <Spin spinning={false}>
            <Tooltip title={'Shortcut'}>
              <ButtonSm icon={'Shortcut'}  iconSize='mini'/>
            </Tooltip>
          </Spin>

            <div className='h-full flex items-center mx-2'>
              <Divider />

            </div>
            <ButtonSm icon='Chat'  iconSize='mini'/>
            <ButtonSm icon='Card'  iconSize='mini'/>
            <ButtonSm icon='Phone'  iconSize='mini'/>
            <div className='h-full flex items-center mx-2'>
              <Divider />

            </div>
            <ButtonSm icon='DandB' disabled iconSize='mini'/>


          
   </div>
  )
}
 