import { ButtonSm } from '../../../components/shared/Buttons/Small'


type Headerprops = {
    mode: 'search' | 'globalTree' | 'localTree',
    setmode: any,
    serverId:undefined|string

}
export default function Header({mode, setmode, serverId}: Headerprops) {
  return (
    <div className='border-b border-main pl-[38px] border-r'>
        
        <div className='flex items-center bar-h -ml-1.5 space-x-1'>
          
           <ButtonSm icon='Globe' iconSize='sm' onClick={() => setmode('globalTree')} active={mode === 'globalTree'}/>
           <ButtonSm icon='LocalTree' iconSize='sm' onClick={() => setmode('localTree')} active={mode === 'localTree'} disabled={!serverId}/>
           <ButtonSm icon='Search' iconSize='sm' onClick={() => setmode('search')} active={mode === 'search'}/>


        </div>

    </div>
  )
}
