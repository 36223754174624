import React, { useState } from 'react'
import Icon from '../../../components/icons/Icon';
import Domains from './domains';
import { Tabs } from 'antd';
import Mydesk from './mydesk';

export default function Navigatelayout() {
    const [mode, setmode] = useState<'domains' | 'mydesk' | 'about'>('domains');
  return (
    <div className='flex bg-sub-windows h-full'>
   
        <Tabs tabPosition='left' tabBarGutter={5} tabBarStyle={{marginTop: 40}}>
            <Tabs.TabPane tab={
                 <button className={`p-1.5 rounded ${mode === 'domains' ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'}`} onClick={() => setmode('domains')}>
                 <Icon className='icon-md' name='Globe'/>
             </button>
            } key="item-1">
                <div className="border-l border-main w-full">
                    <Domains/>

                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={
                <button className={`p-1.5 rounded ${mode === 'mydesk' ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'}`} onClick={() => setmode('mydesk')}>
                <Icon className='icon-md' name='UserIcon'/>
            </button>
            } key="item-2">
                <div className="border-l border-main w-full">
                    <Mydesk/>

                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={
                 <button className={`p-1.5 rounded ${mode === 'about' ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'}`} onClick={() => setmode('about')}>
                 <Icon className='icon-md' name='TeddraLogo'/>
             </button>
            } key="item-3">
                Content 2
            </Tabs.TabPane>
        </Tabs>
      
    </div>
  )
}
