import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Spin, Tree } from 'antd'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'

import { ReactSVG } from 'react-svg'
import { useMydeskContext } from '../../context/MydeskContext'
import { Server } from '../../../types'
import OneLine from '../../../components/shared/object/oneLine'
import Icon from '../../../components/icons/Icon'
import { fetchInt } from '../../../utils/api/fetchInt'
import { getUserVolumes } from '../../../services/api/request'
import useUpdateTree from '../../hooks/useUpdateTree'


type Props = {
    handleLocationSelection: (node:any) => void, 
    containerHeight?:string
}

const getByID = (tree:(Server), key:string,) => {
    let result:Server | null = null

    if (key === tree.key) {
        return tree
    } else {
        if(tree.children){
            tree.children.some(node => result = getByID(node, key));
        }
        return result;
    }
  }

const codes = {
    '001': 'publications',
    '002': 'folder',
    '003':'directory',
    '004':'drive',
    '005':'file',
    '006':'indexation'
}
export default function Sidebar({handleLocationSelection, containerHeight}: Props) {
    const containerRef = useRef<any>()
    const location = useLocation()
    const ref = useRef<any>()
    const { setBreadcrumb, userCurrentSubscription } = useMydeskContext()
    const [All, setAll] = useState(false);
    const [selectedKey, setselectedKey] = useState('');
    const [featureVolumes, setfeatureVolumes] = useState(false);
    const [featurePublications, setfeaturePublications] = useState(false);
    const [volumes, setvolumes] = useState([]);
    const { tree } = useUpdateTree({ volumes, featurePublications, featureVolumes})
    
 
    useEffect(() => {
        
       if(userCurrentSubscription?.tier){
            const features = userCurrentSubscription.tier.features
            features.map(f => {
                if((codes as any)[f.code] === 'publications'){
                    setfeaturePublications(true)
                }
                if((codes as any)[f.code] === 'folder' || (codes as any)[f.code] === 'directory' || (codes as any)[f.code] === 'drive' || (codes as any)[f.code] === 'file'){
                    setfeatureVolumes(true)
                }
            })
       }
    }, [userCurrentSubscription]);

    useEffect(() => {
        
       if(featureVolumes){
        fetchInt(getUserVolumes).then(res => res.json()).then(data => console.log(data, 'volumes')).catch(err => console.log(err))
       }
    }, [featureVolumes]);

   
    useEffect(() => { 
        if(selectedKey){
         let item:any
         tree.some((t:any) => item = getByID(t, selectedKey))
         setBreadcrumb( item?.parents ? [...item.parents, {name:item.title,path:item.path, disabled: !item.path}, ...All ? [{name:'All'}] : []] : [...All ? [{name:'All'}] : []])
         
        }
       }, [selectedKey, All])
       useEffect(() => {
         
        setAll(location.pathname.includes('all'))
       }, [location.pathname]);
     
  return (
    <div className='modules h-full border-r border-main border-t'>
        <div className={`space-y-4 overflow-hidden ${containerHeight ? containerHeight : 'height-sidebar'}  pb-layout`} ref={containerRef}>
         
          <div className="pl-[8px]">
           
          <Spin
            spinning={false}
        >
    

            <Tree
                ref={ref}
                // selectedKeys={[location.state?.key]}
                    treeData={tree}
                    defaultExpandAll
                    selectedKeys={[selectedKey]}
                    autoExpandParent={false}
                    // expandedKeys={expandedKeys}
                 
                    height={
                        containerRef?.current
                        ? containerRef.current.clientHeight
                        : 900
                    }
                        className='sidebar bg-transparent w-full pb-5'
                    switcherIcon={({ expanded } : any) =>
                        expanded ? (
                        <ChevronDownIcon className="w-3.5 h-3.5" />
                        ) : (
                        <ChevronRightIcon className="w-3.5 h-3.5" />
                        )
                    }
                    titleRender={(location: any) => (
                            
                        <OneLine
                            noHover
                            draggable
                            onDragStart={e => e.dataTransfer.setData('item', JSON.stringify({title: location.title,icon: location.iconModule ? {src: location.iconModule , type:'icon'}:{src: location.iconUrl, type:'image'} ,id:location.id, docType:'volume'}))}
                            active={selectedKey===location.key}
                            dropdownProps={null}
                            className='!p-0 !bg-transparent hover:!bg-transparent transition-none '
                        >
                            <div className='flex justify-between items-center w-full' id={location.id}>
                            
                                <div className={`flex space-x-2.5 items-center  ` }>
                                    

                                            <div className='relative w-[20px] h-[20px]'>
                                                {location.iconType === 'icon' ? (
                                                    <Icon name={location.iconUrl} className='w-full h-full'/>
                                                ) : (
                                                    <ReactSVG 
                                                    src={location.iconUrl} 
                                                    className='w-full h-full'
                                                    beforeInjection={(svg) => {
                                                        svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
                                                    }}
                                                    />

                                                )}
                                            </div>
                                    
                                
                                    <p className={`truncate`}>{location.title}</p>

                                </div>
                            
                            </div>
                            

                        </OneLine>
                    

                )}
                onSelect={(node,e) => {
                    console.log(node)
                    handleLocationSelection(e.node)
                    setselectedKey(e.node.key)
                }}
                
            />
                
              

        </Spin>
          

          </div>
        

        </div>
      
    </div>
  )
}
