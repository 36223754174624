import React from 'react'
import ObjectMd from '../ObjectMd'
import Arrows from '../Arrows'
import Keywords from './keywords'
import MapComp from './Map'

export default function Traffic() {
  return (
    <div className='space-y-12'>
        <div className="gap-[120px] flex flex-wrap">
            <div className="flex space-x-16 w-[520px]">
                <ObjectMd
                    icon={{type:'icon', src:'SearchIcon'}}
                    title={'12,3M'}
                    description={'Request'}
                />
                <Arrows
                    title='28%'
                    description='lorem isum'
                    direction='up'
                />
            </div>
            <div className="flex space-x-16 flex-1">
                <ObjectMd
                    icon={{type:'icon', src:'UserIcon'}}
                    title={'805K'}
                    description={'Unique visitors'}
                />
                <Arrows
                    title='5%'
                    description='lorem isum'
                    direction='down'
                />
            </div>
        </div>
        <div className="space-y-4">
            <p className='font-bold text-[16px]'>From</p>
            <div className='max-w-[1600px] w-full h-[1150px] pr-8'>
                <MapComp/>
 
            </div>
            {/* <div className="flex-1">
                <PieChart/>

            </div> */}


        </div>
        {/* <div className="space-y-4">
            <p className='font-bold text-[16px]'>Keywords</p>
            <div className="max-w-[800px] w-full">
                <Keywords/>

            </div>
        </div> */}
    </div>
  )
}
