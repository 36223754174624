import { Dropdown, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Icon from '../../../../components/icons/Icon'
import { PublicationGroup, PublicationTypes } from '../../../../types'
import useGetPubsTypes from '../../../../hooks/useGetPubsTypes'
import Collapse, { Panel } from '../../../../components/shared/Collapse'
import { storageUrl } from '../../../../services/api/constants'
import ReactSvg from '../../../../components/shared/ReactSvg'


type Props = {
    onClick: (type:"link" | "page") => void
}
function TypeMenu({onClick} : Props) {
  return (
    <div className='w-[270px] teddra-rounded teddra-shadow bg-white p-2 -mt-[30px] ml-[20px]'>
        <button className='flex space-x-2 text-start bg-hover-transparent w-full p-1.5 rounded' onClick={() => onClick("link")}>
            <Icon className='icon-md' name='Link'/>
            <div className='flex-1'>
                <p className="text-title">Edit a link</p>
                <p className='text-muted'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
        </button>
        <button className='flex space-x-2 text-start bg-hover-transparent w-full p-1.5 rounded disabled:text-skin-muted' disabled onClick={() => onClick("link")}>
            <Icon className='icon-md' name='Document'/>
            <div className='flex-1'>
                <p className="text-title">Edit a page</p>
                <p className='text-muted'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
        </button>
    </div>
  )
}

export default function New() {
    const [selectedGroup, setselectedGroup] = useState<null | {group: PublicationGroup, children:PublicationTypes[]}>(null)
    const navigate = useNavigate()
    const [dropdownOpen, setdropdownOpen] = useState<null | string>(null) 

    const [type, setType] = useState<any>();
    const { loading, publicationsTypes } = useGetPubsTypes()
    
  
    const renderGroup = (group:PublicationGroup, type:PublicationTypes, format:"link" | "page") => {
        let selectedType 
        if(group.id !== 'popular'){
          selectedType = {...type, groupe:{title:group.title, iconUrl:group.icon.src, id:group.id}}
        }else{
            const originalGroup = publicationsTypes.filter((ty:PublicationTypes) => ty.id === type.group )
            selectedType = {...type, groupe:{title:originalGroup[0].title, iconUrl:originalGroup[0].icon.src, id:originalGroup[0].id}}
        }
        return selectedType
        // navigate(`/publish/new/edit/${format}`)
        
    }
 
    useEffect(() => {
        if(publicationsTypes && publicationsTypes.length > 0){
            const type = publicationsTypes.filter((gr:PublicationGroup) => gr.id === 'popular')
    
            setselectedGroup({group: type[0], children:type[0].children})

        }
    }, [publicationsTypes])
    
    const handleSelect = (group:any, child :any,type:any) => {
      const selectedType = renderGroup(group, child, type)
      navigate('/mydesk/publish/edit/link', {state:{type:selectedType}})
    //   openPublish({title: 'Publish', icon: {type: 'icon', src:'Edit'}, data: selectedType })
    }
    // const type = publicationsTypes.filter((gr:PublicationGroup) => gr.id === e.node.id)
    // setselectedGroup({group:e.node, children:type[0].children})
  return (
    <div className=' rightpage scrollbar-thin'>
       
        <Spin spinning={loading}>
              
                <div className='flex-1  flex flex-col'>
                    
                
                    <div className="pb-7 pt-[14px] overflow-auto height-screen" >
                      <Collapse defaultActiveKey={['0']}>
                        <>
                            {publicationsTypes?.map((group:any,i:number) => (
                              <Panel key={`${i}`} header={<p className='text-groupe'>{group.title.en}</p>}>

                                <div className='flex flex-wrap gap-y-8 gap-x-3 pl-5'>

                                    {group.children?.length > 0 && group.children.map((child:PublicationTypes) => (
                                        <Dropdown key={child.id} overlay={<TypeMenu onClick={(type) =>handleSelect(group, child, type)}/>} trigger={['click']} onVisibleChange={visible => visible ? setdropdownOpen(child.id) : setdropdownOpen(null)}>
                                            <div key={child.id} className={`${((type?.id === child.id) || (dropdownOpen === child.id)) ? "bg-skin-fill-inverted  " : "bg-hover-transparent"}   space-y-2 px-4 py-2 rounded cursor-pointer hover-icon group`} >
                                            
                                                <div className='relative flex justify-center'>
                                                    <Icon name='Document' className={`w-[50px] h-[50px] ${((type?.id === child.id) || (dropdownOpen === child.id)) && 'text-white'} icon`} />
                                                    <div className='bg-white rounded-full h-[35px] w-[35px] flex items-center justify-center absolute bottom-[-12px] right-[5px]'>
                                                        <div className='relative w-[20px] h-[20px]'>
                                                            <ReactSvg src={`${storageUrl}${child.icon.src}`} className='w-full h-full'/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className={`text-center w-[84px] min-h-[42px] mx-auto ${((type?.id === child.id) || (dropdownOpen === child.id)) && 'text-white'}`}>{child.name_singular.en}</p>
                                            </div>

                                        </Dropdown>

                                    ))}
                                        
                                </div>
                              </Panel>
                            ))}
                        
                        </>
                      </Collapse>
                        
                        </div>
                        <div className="border-t border-main">
                            <div className="bar-h-mini"></div>
                        </div>
                </div>


        </Spin>

    </div>

  )
}
