import { ReactSVG } from 'react-svg'
import { Highlight, Publication, TypeMenuItem } from '../../../../types'
import ObjectWithDropdown from '../../object/objectWithIcon/Withdrpdown'
import ResourceMenu from '../../menu/ResourceMenu'
import { storageUrl } from '../../../../services/api/constants'


type Props = {
    item: {document:Publication, highlight:Highlight} ,
    active:boolean,
    renderDate :(date:number) => string,
    handlePublicationSelection: (item:{document:Publication, highlight:Highlight}) => void,
    setRecheckPin: ()=> void,
    extraActionsItems? : (item:Publication) => TypeMenuItem[]

}
export default function TwoLineItem({item, active, setRecheckPin, handlePublicationSelection, extraActionsItems} : Props) {
    
  return (

    <ObjectWithDropdown
    active={active}
    title={item.highlight?.title?.[item.document?.local] ? <p className='truncate max-w-full flex-1 text-start' dangerouslySetInnerHTML={{__html: item.highlight?.title?.[item.document?.local]?.snippet!}}></p> : (
        <p className='truncate max-w-full flex-1 text-start'>{item.document?.title[item.document?.local]}</p>

      )}
    overlay={<ResourceMenu
      id={item.document?.id}
      onSuccess={setRecheckPin}
      type='publications_link'
      items={{shortcut:true}}
      extraItems={[
        ...extraActionsItems ? extraActionsItems(item.document) : [],
        {
          type:'divider'
        },
        {
          label:'Report',
          icon:{src:'FlagIcon', type:'icon'},
          key:'report',

        }
      ]}
    />}
    icon={<div className='relative w-full h-full '>
       
        <ReactSVG src={`${storageUrl}${item.document?.publicationType?.iconUrl}`} className='w-full h-full' beforeInjection={(svg) => {
            svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
          }}/>
      
    </div>}
    id={item.document?.id}
    description={
        item.highlight?.description?.[item.document?.local] ? <p className='ellipsis-2 max-w-full flex-1 text-start' dangerouslySetInnerHTML={{__html: item.highlight?.description?.[item.document?.local]?.snippet!}}></p> : (
                    <p className='ellipsis-2 max-w-full flex-1 text-start' dangerouslySetInnerHTML={{__html: item.document?.description[item.document?.local] ? item.document?.description[item.document?.local]! : 'No description'}}></p>
            
                  )
  
        
    }
    onSelect={() => handlePublicationSelection(item)}
/>


   
   

  )
}
