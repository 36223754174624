import * as React from "react"
import { IconOptions } from "../../../types"
const MyResources = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 410 410"
    {...props}
  >
    <defs>
      <style>{".fil1{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <path
        id="_2358157240048"
        d="M410 410H0V0h410z"
        style={{
          fill: "none",
          fillRule: "nonzero",
        }}
      />
      <g id="_2358157241248">
        <path
          d="M130 190H30v190h230V220H150l-20-30zm90 150H70V220h40l20 40h90v80z"
        
        />
        <path
          d="M320 30H120c-20 0-30 20-30 40v80h30V70h170v50h50v220h-50v40h50c20 0 40-20 40-40V100l-60-70z"
        
        />
      </g>
    </g>
  </svg>
)
export default MyResources
