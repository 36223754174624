import React from 'react'
import ResourcesActions from './actions/resources'
import { ActionsProps } from '../types'
import DetailsActions from './actions/details'

export default function ActionsBar(props:ActionsProps) {
  return (
    <div className='bar-h flex justify-between space-between items-center pl-[25px] pr-2'>
        <ResourcesActions {...props.resourcesActions}/>
        <DetailsActions {...props.detailsActions}/>

    </div>
  )
}
