import React from 'react'
import Types from './types'

import Keywords from './keywords'



export default function Tendance() {
  return (
    <div className='flex space-x-16'>
        <div className='space-y-3 flex-1'>
            <div className="flex space-x-3 items-center">
                <p className="font-bold">Publications</p>
                <div className="w-[250px]">
                    <Types/>

                </div>
            </div>
            <div className="flex-1 w-full pr-3">
                <Keywords/>

            </div>
            {/* <div className="flex space-x-16">
                <ObjectMd
                    icon={{type:'icon', src:''}}
                    title={'12,3M'}
                    description={'Request'}
                />
                <Arrows
                    title='28%'
                    description='lorem isum'
                    direction='up'
                />
            </div> */}
        </div>

    </div>
  )
}
