import { IconOptions } from "../../../types"
export default function ViewAsLargeListIcon({ className }:IconOptions) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        fill="currentColor"
        className={className}
      >
        <path
          fillRule="evenodd"
          d="M16.8,21.6H4.8A4.8,4.8,0,0,1,0,16.8V4.8A4.8,4.8,0,0,1,4.8,0h12a4.8,4.8,0,0,1,4.8,4.8v12a4.8,4.8,0,0,1-4.8,4.8ZM4.8,4.8h0v12h12V4.8ZM16.8,48H4.8A4.8,4.8,0,0,1,0,43.2v-12a4.8,4.8,0,0,1,4.8-4.8h12a4.8,4.8,0,0,1,4.8,4.8v12A4.8,4.8,0,0,1,16.8,48ZM4.8,31.2h0v12h12v-12ZM48,43.2H24V38.4H48v4.8ZM48,36H24V31.2H48V36Zm0-19.2H24V12H48v4.8Zm0-7.2H24V4.8H48Z"
        />
      </svg>
    );
  }
  