import { apiEndPoint } from "../constants"

export const getActiveGroupsWithChilds = `${apiEndPoint}/publications-types/all`
export const createPublication = `${apiEndPoint}/publications/testers/create`
export const updatePublication = (id:string) =>  `${apiEndPoint}/publications/testers/update/${id}`
export const deletePublication = (id:string) => `${apiEndPoint}/publications/testers/delete/${id}`
export const movetotrash = (id:string) => `${apiEndPoint}/publications/testers/trash/${id}`



