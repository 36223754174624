// import { getAuth, sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import { Form, Input, Modal, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
// import 'react-phone-input-2/lib/style.css'

import StepsButtons from "../../../components/stepsButtons";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { signup } from "../../../../services/api/request/user";
import { auth } from "../../../../utils/firebase";
import Icon from "../../../../components/icons/Icon";
import { getAuth, sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import { useAuthContext } from "../../../../context/AuthContext";
import { useSignupContext } from "../../../context/SignupContext";
import { useNavigate } from "react-router-dom";



type Props = {
    nextStep:() => void,
    prevStep : () => void,
    profileName:string,
    url:string

}
export const emailUrl = (profileId:string, profileName:string, planId:string, accountType:any) => `https://teddra-email-verification.vercel.app/verifyEmail/?planId=${profileId}&tierId=${planId}&profile=${profileName}&type=${accountType}`
export default function CreateAccount() {
  const {user} = useAuthContext()
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
        title: '',
        text: '',
        showMessage: false, 
        icon: ''
    });
  const [errors, setErrors] = useState<{message:string, errors:any}>({errors:null, message:''});
  const { profile, planId, accountType, setCurrentStep } = useSignupContext()
  const navigate = useNavigate()
  
  useEffect(() => {
    
    setCurrentStep(3)
  }, []);
  const submit = async (e:any) => {
    console.log('submit')
    try{
      const { email, password, confirmPassword, name } = await form.validateFields();
      console.log(email, password, confirmPassword, name)
    
      if (password !== confirmPassword) {
        setErrors({errors: { password: ["Passwords do not match"] }, message: 'Validation error'});
        return;
      }
      setLoading(true)
      setErrors({message:'', errors:null})
      const req = await fetch(signup, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          method: 'POST',
          body:JSON.stringify({displayName:name, email, password, passwordConfirmation: confirmPassword})
      })
      const res = await req.json()
      if(res.success){
          await signInWithCustomToken(auth, res.token)
          const authUser = getAuth().currentUser
          if(authUser){
            const actionCodeSettings = {
              url: emailUrl(profile.id, profile.name, planId, accountType),
              handleCodeInApp: false, // Whether the link should be handled in the web app
            };
            sendEmailVerification(authUser, actionCodeSettings).then(() => {
              // setMessage({
              //   title: 'Verify Email',
              //   text: 'Check your email to activate your account',
              //   showMessage: true,
              //   icon: 'EmailSent'
              // }); 
              nextStep()
    
            }).catch(() => {
              notification.open({
                  message: `Something went very wrong`,
                  type:'error'
                })
          }) 
          }
  
      }else{
          setErrors({errors:res.error?.errors, message: res.message})
      
  
      }

    }catch(err){
      console.log(err)
    }finally{
      setLoading(false)

    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  const nextStep = () => {
    navigate('/connect/signup/steps/4')
  }
  return (
    <div className="space-y-2 h-full flex flex-col relative">
        <div className="relative overflow-hidden flex-1 flex flex-col  rounded p-4">
          <div className="absolute bottom-[-80px] left-[-60px] ">
            <Icon name="Password" className="w-[300px] h-[300px] opacity-5 -rotate-45"/>
          </div>
            <div>
                <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">{profile.name}: Create your account
                </p>
                
            </div>
            <div className="flex-1 flex items-center">
              <div className="flex-1 flex">
                <div className="w-[223px]">
                  <div className=" ml-[41px]">
            
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque atque tempore animi at soluta corporis quia dicta aperiam similique qui nihil.
                    </p>
                  </div>
                </div>
                <Form 
                  className="space-y-3 flex-1 max-w-[300px] " 
                  onChange={() => setErrors({errors:null, message: ''})}
                  form={form}
                  onFinishFailed={onFinishFailed}
                  onFinish={submit}
                  onValuesChange={() => setErrors({errors:null, message: ''})}
                  initialValues={!user ? undefined : {
                    name: user.name,
                    email: user.email
                  }}
                >
                  
                    <Form.Item
                      // validateStatus={errors?.name?.length > 0 ? "error" : ''}
                      // help={errors?.name?.length > 0 && errors?.name[0]}
                      className="group flex-1"
                      style={{ marginBottom: "0" }}
                      name={'name'}
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" className=" border !border-gray-500" />
                      {/* <input
                        type="text"
                        placeholder="Name"
                        className="rounded py-2 px-3 block w-full border border-main"
                      /> */}
                    </Form.Item>
                    <Form.Item
                      // validateStatus={errors?.email?.length > 0 ? "error" : ''}
                      help={errors.errors?.email?.length > 0 && errors.errors?.email[0]}
                      className="group flex-1"
                      name="email"
                      rules={[{ required: true, message: 'Email is required' }, {  type: 'email', message: 'Email not valid' }]}
                    >
                   
                      <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        type="email"
                        placeholder="Email"
                         className=" border !border-gray-500"
                      />
                    </Form.Item>
                    <Form.Item
                      // validateStatus={errors?.password?.length > 0 ? "error" : ''}
                      help={errors.errors?.password?.length > 0 && errors.errors?.password[0]}
                      className="group flex-1"
                      name="password"
                      rules={[{ required: true, message: 'Password is required' }]}
                    >
                     <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password"
                         className=" border !border-gray-500"
                      />
                    </Form.Item>
                    <Form.Item
                      // validateStatus={errors?.password?.length > 0 ? "error" : ''}
                      help={errors.errors?.passwordConfirmation?.length > 0 && errors.errors?.passwordConfirmation[0]}
                      className="group flex-1"
                      name="confirmPassword"
                      rules={[{ required: true, message: 'Please confirm your password' }]}
                    >
                  
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Confirm password"
                        aria-label="Confirm Password"
                         className=" border !border-gray-500"
                      />
                    </Form.Item>
                    {errors?.message && <p className="text-red-600">{errors.message}</p>}
                    <Form.Item>
                      <button type="submit" disabled={loading || !!user?.id} className="px-4 py-2 rounded bg-skin-fill-inverted text-skin-inverted disabled:opacity-20">Create Account</button>
                    </Form.Item>
              

               
                  
                </Form>
                <div className="w-[170px]"></div>

              </div>

            </div>

        </div>
      {/* <NotificationModal visible={message.showMessage} onClose={onClose} text={message.text} icon={message.icon} title={message.title} action={{title: 'Resend email', handler: resendEmail}}/> */}
      <StepsButtons
            nextStep={nextStep}
            prevStep={() => navigate('/connect/signup/steps/2')}
            // disable={!data?.name || !data.raisonSocial || !data.duns || !data.activity || !data.activityCode || !data.webResources?.website || !data.address || !data.address.city || !data.address.country || !data.address.zipcode || !data.address.line1 || !data.email}
            disable={loading}
            submit
        />
    </div>
  );
}