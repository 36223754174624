

import TopBarIcons from './TopBarIcons'
import TopBarTitle from './TopbarTitle'
import { TitleProps } from '../types';
import { useEffect, useRef, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, XIcon } from '@heroicons/react/solid';
import Icon from '../../../../icons/Icon';


export default function TitleBar(props: TitleProps ) {
  const containerRef = useRef<any>()
  const innerRef = useRef<any>()

  const [ShowArrows, setShowArrows] = useState(false);
  const [ArrowDisabled, setArrowDisabled] = useState({left:false, right:false});
  const [translate, settranslate] = useState(0);
  useEffect(() => {
    
    if(containerRef.current?.clientWidth > innerRef.current?.clientWidth){
      setShowArrows(false)
      settranslate(0)
    }else{
      setShowArrows(true)
    }

    
  }, [props.tabLists?.length]);
  const handleHorizantalScroll = (step:number) => {
   settranslate(prev => prev +step)
   
  };
  useEffect(() => {
    if (translate >= 0) {
      setArrowDisabled(prev => ({...prev, left:true}));
    } else {
      setArrowDisabled(prev => ({...prev, left:false}));
    }
    
    if(translate + innerRef.current?.clientWidth + 40 > containerRef.current?.clientWidth){
      setArrowDisabled(prev => ({...prev, right:false}));

    }else{
      setArrowDisabled(prev => ({...prev, right:true})); 

    }
  }, [translate,props.tabLists?.length]);
     
  return (
    <div className='bg-mainbar '>

      <div className="bg-bars flex justify-between menubar" >
        <div className={`flex flex-1 overflow-hidden relative pr-[20px] space-x-2 ${!props.NonewWin && 'pt-[3px]'}` } ref={containerRef}>
          {props.topbarTitle && (
            <div className='flex flex-1 space-x-[2px] ml-[21px] scroll-hor' ref={innerRef} style={{transform:`translate(${translate}px)`}}>
                {      props.tabLists && props.tabLists.map((tab,i) => ( 
                        <div className='flex'>
                          <div className={`${(!props.NonewWin  && tab.active)? 'bg-topbar bg-sub-windows' : 'text-white fill-white '}  flex items-center  rounded-t no-dragable px-3 space-x-2.5 group`} key={tab.id} >
                            <button className={`${tab.main && 'cursor-default menubar'}`} onClick={() => {(props.setTab && !tab.main) && props.setTab(tab.id)}}>
                              <TopBarTitle
                                NonewWin={props.NonewWin}
                                title={tab.title}
                                icon={tab.icon}
                            
                              />

                            </button>
                          {(props.removeTab && !tab.main) && (
                            <button className={`w-[20px] flex items-center justify-center h-max my-auto p-1 bg-hover-transparent rounded`} onClick={() => props.removeTab && props.removeTab(tab.id)}>
                                <XIcon className={`opacity-0 group-hover:opacity-[1] ${tab.active && '!opacity-[1]'} icon-mini`}/>
 
                            </button>
 
                          )}

                           
                          </div>
                          <div className='w-[1px] h-[15px] mx-4 my-auto bg-white opacity-[0.2]'></div>
                          {i === props.tabLists!.length - 1 && (

                            !props.NonewWin && props.topbarTitle && props.createTab && (
                              <button className='w-[45px] h-full text-white fill-white rounded-t flex justify-center items-center no-dragable' onClick={() => props.createTab && props.createTab({title: props.topbarTitle!.title, icon: props.topbarTitle!.icon})}>
                                <Icon className='icon-mini' name='PlusIcon'/>
                              </button>

                            )
                          )}
                        </div>
                      ))}


            </div>
          )}
         {ShowArrows && (
          <>
            <div className='absolute right-0 top-0'>
              <button className='border border-white bg-skin-fill-inverted w-[22px] flex items-center justify-center bar-h disabled:opacity-20 no-dragable' 
              onClick={() => handleHorizantalScroll( -50)
              }
              disabled={ArrowDisabled.right}
              >
                <ArrowRightIcon className='icon-mini fill-skin-inverted'/>
              </button>
            </div>
            <div className='absolute left-[-8px] top-0'>
              <button className='border border-white bg-skin-fill-inverted w-[22px] flex items-center justify-center bar-h disabled:opacity-20 no-dragable' 
              onClick={() => handleHorizantalScroll( 50)
              }
              disabled={ArrowDisabled.left}
              >
                <ArrowLeftIcon className='icon-mini fill-skin-inverted'/>
              </button>
            </div>
          </>

         )}
        

        </div>
          <div className="flex space-x-3 bar-h menubar items-center px-1.5  text-white fill-white">
              
          
              <TopBarIcons
                    actions={{
                        close: props.close 
                    
                    }}
                    main={props.main}
                  
                />
            </div>

        </div>
    </div>
  )
}
