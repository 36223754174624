import { getAuth, onAuthStateChanged, reload } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Outlet, OutletProps, useNavigate } from 'react-router-dom'
import { auth } from '../utils/firebase'
import { useAuthContext } from '../context/AuthContext'
import { fetchInt } from '../utils/api/fetchInt'
import { getAuthUser } from '../services/api/request'

export default function CheckAuthRoute(props:OutletProps) {
    const [isLoaded, setisLoaded] = useState(false)
    const { setUser } = useAuthContext()
    useEffect(() => {
      console.log('check auth')
      const listen = onAuthStateChanged(auth, async user => {
        if(user){
          
            fetchInt(getAuthUser).then(res => res.json()).then(data => {
              setUser({...data.user,id:user.uid, name: user.displayName!, email:user.email!, picture: user.photoURL!, emailVerified: user.emailVerified  })
              console.log(data.user,'user')
  
            }).catch(err => console.log(err)).finally(() => setisLoaded(true))

          
         
        }else{
          setUser(null)
          setisLoaded(true)
        }
      
        console.log(user) 
      })
      return listen
    }, [])

    
    
    
  
    if(!isLoaded){
      return <p>...loading.</p>
    }
    
    return <Outlet {...props}/>
   
}
