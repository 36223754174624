import React, { useEffect, useState } from 'react'
import StepsButtons from '../../../components/stepsButtons';

import { Form } from 'antd';
import { Organization, ProData } from '../../../../types';
import IndividualForm from '../../../components/form/Individual';
import OrgForm from '../../../components/form/OrgForm';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext';


export default function OrganizationPage() {
  const [form] = Form.useForm()
  const { organizationData, setorganization, profile, setCurrentStep } = useSignupContext()
  const navigate = useNavigate()
  const {user} = useAuthContext()

  useEffect(() => {
    
    setCurrentStep(5)
  }, []);
  const nextStep = () => {
    navigate('/connect/signup/steps/6')
  }
  const prevStep = () => {
    
    navigate('/connect/signup/steps/2')
  }

  const submit = (values:any) => {
    const { city, country, thematics, interests,organizationDuns, organizationName, organizationActivity, organizationActivityCode, organizationAddressLine1, organizationAddressLine2, organizationCity, organizationCountry, organizationEmail, organizationLinkedin, organizationPhone, organizationRaisonSocial,organizationLogo, organizationZipCode, organizationWebsite} = values
    const submittedData:Organization= {
      organizationActivity,
      organizationActivityCode,
      organizationCountry,
      organizationAddressLine1,
      organizationCity,
      organizationAddressLine2: organizationAddressLine2?? '',
      organizationDuns,
      organizationEmail,
      organizationLinkedin,
      organizationLogo : organizationLogo??'',
      organizationRaisonSocial,
      organizationName,
      organizationPhone,
      organizationWebsite,
      organizationZipCode,
      adminCountry: country,
      adminCity: city,
      adminThematics: [thematics],
      adminIntrests: interests ? interests : [],
    }
    setorganization(submittedData)
    nextStep()

      
  }
  
  return (
    <div className='flex-1 h-full flex flex-col space-y-2'>
       <div className="flex-1 rounded p-4 flex-flex-col">
            <div>
                <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">{profile.name}: Create your account
                </p>
                
            </div>
            <div className="flex-1 flex items-center h-full">
                <Form 
                form={form} 
                initialValues={
                  organizationData ? {
                    organizationActivity : organizationData.organizationActivity,
                    organizationActivityCode : organizationData.organizationActivityCode,
                    organizationCountry : organizationData.organizationCountry,
                    organizationAddressLine1 : organizationData.organizationAddressLine1,
                    organizationCity : organizationData.organizationCity,
                    organizationAddressLine2: organizationData.organizationAddressLine2?? '',
                    organizationDuns : organizationData.organizationDuns,
                    organizationEmail: organizationData.organizationEmail,
                    organizationLinkedin : organizationData.organizationLinkedin,
                    organizationLogo: organizationData.organizationLogo,
                    organizationRaisonSocial: organizationData.organizationRaisonSocial,
                    organizationName: organizationData.organizationName,
                    organizationPhone: organizationData.organizationPhone,
                    organizationWebsite: organizationData.organizationWebsite,
                    organizationZipCode : organizationData.organizationZipCode,
                    country: organizationData.adminCountry,
                    city: organizationData.adminCity,
                    thematics: organizationData.adminThematics ? organizationData.adminThematics[0] : '',
                    adminIntrests: organizationData.adminIntrests
                } : undefined} 
                className='space-y-10' 
                onFinish={submit}
                >
                  <div className="flex-1 flex">
                    <div className="w-[223px]">
                    <div className=" ml-[41px]">
                        <p className="font-bold text-[16px]">You:</p>
                        <p>
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque atque tempore animi at soluta corporis.
                        </p>
                      </div>
                    </div>
                    <IndividualForm/>
                  
                    <div className="w-[170px]"></div>

                  </div>
                  <div className="flex-1 flex">
                    <div className="w-[223px]">
                    <div className=" ml-[41px]">
                        <p className="font-bold text-[16px]">Your organization:</p>
                        <p>
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque atque tempore animi at soluta corporis.
                        </p>
                      </div>
                    </div>
                    <div className='w-max'>
                      
                      <div className="flex space-x-8">
                        <OrgForm/>

                      </div>
                    </div>
                  
                    {/* <div className="w-[170px]"></div> */}

                  </div>

                </Form>


            </div>

            </div>
        <StepsButtons
            prevStep={prevStep}
            // disable={!data?.name || !data.raisonSocial || !data.duns || !data.activity || !data.activityCode || !data.webResources?.website || !data.address || !data.address.city || !data.address.country || !data.address.zipcode || !data.address.line1 || !data.email}
            disable={false}
            nextStep={() => form.submit()}
            // submit
        />
    
    </div>
  )
}
