import React from 'react'
import { IconOptions } from "../../../types"

export default function Teddra(props:IconOptions) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 10920 10920"
    {...props}
  >
    <path
      d="M0 10920h10920V0H0z"
      style={{
        fill: "none",
      }}
    />
    <circle
      cx={5460}
      cy={5460}
      r={4880}
      style={{
        fill: "#0095eb",
      }}
    />
    <path
      d="M3920 4730c-80-210 340-60 540-290l270-540c-150-690 310-700-60-1010-1120-940-1800-380-2120 440-50 120-10 280 10 390 10 40-180 190-240 470-200 820 320 1200 450 960 120-210-120-1180-90-1140 180 230 500 600 560 630 310 120 10 450 10 440l-720 1060-720 830 430 240-670 1430 690 330 90-160-210-320 690-900 350 40-280 1480 840-10-20-150-300-180 380-1010 640-60-140-1720 1070-1200c-150-180-10-60-200-190-220 180-440 350-660 520l-110 50-250-10c10 0-140-210-230-420zm2970-2420s0 400 60 520c70 110 50 440-200 560-240 110-240 170-120 280 80 80 80 250-40 240-60 0-260-140-290-170-110-70-320 230-90 350 230 110 560-60 610 390 50 440 40 550 50 680 10 120-380-30-600-170-340-350-540-830-540-1360 0-480 170-920 450-1260l710-60zm1690 170c-210 60-560 160-610 260-70 150-320 260-320 260s190 40 80 160c-100 110 210 260-80 200-280-60-110 210 10 270 130 60 340 0 360-90 30-80 140-410 610-150 140 80 30 150 20 200-30 120-330 170-480 130-210-70-620 350-210 650 400 310 600-10 480 420-20 70 310-90 470-350 80-220 120-450 120-700 0-480-160-920-450-1260zm-1170-670c1100 0 1940 740 1940 1840S8260 5460 7160 5460s-1790-710-1790-1810 940-1840 2040-1840z"
      style={{
        fill: "#fefefe",
      }}
    />
  </svg>
  )
}
