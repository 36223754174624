import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PublishContextProvider } from './context/publishContext'
import Publishayout from './Layout'
import EditLink from './pages/new/editLink'
import PublishIn from './pages/new/publish'
import Servers from './pages/new/publish/servers'
import Drives from './pages/new/publish/Drives'
import Directories from './pages/new/publish/directories'
import Folders from './pages/new/publish/Folders'
import { PublishinContextProvider } from './pages/new/publish/context/publishInContext'
import PrivateRoute from '../../components/PrivateRoute'
// import EditLink from './pages/editLink'



export default function PublishNavigation() {

  return (
    <PublishContextProvider>
      <Routes>
        <Route element={<PrivateRoute/>}>
          <Route element={<Publishayout/>}>
              
          
            
                <Route path='/edit/link' element={<EditLink/>}/> 
                <Route 
                  element={
                    <PublishinContextProvider>
                      <PublishIn/>
                    </PublishinContextProvider>
                  }
                >
                  <Route path='/publishin' element={<></>}/> 
                  <Route path='/publishin/:networkId' element={<Servers/>}/> 
                  <Route element={<Servers/>}>
                    <Route path='/publishin/:networkId/:serverId' element={<Drives/>}/>
                    <Route element={<Drives/>}>
                      <Route path='/publishin/:networkId/:serverId/:driveId' element={<Directories/>}/>
                      <Route element={<Directories/>}>
                        <Route path='/publishin/:networkId/:serverId/:driveId/:directoryId' element={<Folders/>}/>
                      </Route>
                    </Route>
                  </Route>

                </Route>
          



              <Route path='/*' element={<></>}></Route>

          </Route>

        </Route>

      </Routes>
    </PublishContextProvider>

  )
}
