import React, { useEffect, useState } from 'react'
import { VolumeSearch } from '../../../../types'
import Input from '../../../../components/shared/input'
import Results from './results'
import GeoTree from './GeoTree'
import useSearchLocations from '../../../hooks/useSearchLocations'

type Props = {
    selectedKey : string,
    domainId:string,
    setResultsLength:any,
    handleSearchSelection : (doc:VolumeSearch) => void,
}
export default function Search({domainId, selectedKey, setResultsLength,handleSearchSelection}: Props) {
    const [query, setquery] = useState('')
    const [searchValue, setsearchValue] = useState('');
    const [selectedParent, setselectedParent] = useState<{value:undefined |string, id:string}>({value:undefined, id:''});
    const {searchResults, resultsLength} = useSearchLocations({
        query, 
        sort: selectedParent.id ? 'depth:asc':'created_at:desc,depth:asc',
        filter: `${selectedParent.id ? `&& roots:=${selectedParent.id}` : ''} && domainsIds:=${domainId}`
    })

    useEffect(() => {
        
        setResultsLength(resultsLength)
    }, [resultsLength]);
        


      useEffect(() => {
        
        if(searchValue.length > 1){
            setquery(searchValue)
        }else{
            setquery('')
        }
      }, [searchValue]);
  return (
    <div className='pt-2 space-y-3'>
       <div className="flex space-x-2 px-5">
            <GeoTree
                selectedVolume={selectedParent}
                setselectedVolume={(id, value) => setselectedParent({id, value})}
                domainId={domainId}
            />
            <Input
                className='w-full border border-main input-h rounded'
                value={searchValue}
                onChange={e => setsearchValue(e.target.value)}
                placeholder='Search a drive, diretcory,...'
            />

        </div>
        <div className="space-y-2">
            <p className='text-group px-5'>{query.length === 0 ? 'Recently added' : `Your search for ${query} :`}</p>
            <div className='h-[calc(100vh-282.5px)] overflow-auto px-5 '>
                <Results
                    selectedKey={selectedKey}
                    handleServerSelection={handleSearchSelection}
                    results={searchResults}
                    query={query}
                
                /> 

            </div>
        </div>
    </div>
  )
}
