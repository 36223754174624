import { Form,notification,Select, Spin} from 'antd'
import { useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import RichText from './RichText'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePublishContext } from '../../../context/publishContext'
import { storageUrl } from '../../../../../services/api/constants'
import { fetchInt } from '../../../../../utils/api/fetchInt'
import { updatePublication } from '../../../../../services/api/request/publications'
import useNavigateTo from '../../../../hooks/useNvigateTo'



export default function EditForm({url, initDescription, edit, seterrors, errors}: {url:string, initDescription:string, edit:boolean, seterrors:any, errors:any}) {
  const form = Form.useFormInstance()
  const { type:selectedData, active, InitId, editData } = usePublishContext()
  const editorRef = useRef<any>()
  const {goTo} = useNavigateTo()
  const location = useLocation()
  const [loading, setloading] = useState(false);


  const handleNext = (e:any) => {
    e.preventDefault()
    const requiredValues = [{name: 'title', type:'string'}, {name: 'description', type:'string'}, {name: 'url', type:'string'}, {name: 'keywords', type:'array'}]
    requiredValues.forEach(value => {
      if(!form.getFieldValue(value.name) || (value.type=== 'array' && form.getFieldValue(value.name)?.length === 0)){
        seterrors((prev:any) => ({...prev, [value.name] : `${value.name} is required`}))
      }
    })
    if(Object.keys(errors).length === 0){
      goTo(`/publish/publishin`, {state: location.state})

    }
  }

  const Update = async () => {
    if(!InitId || !selectedData){
      notification.error({message:'Something went very wrong!'})
      return
    }
    const insertedData = {
      title: {
        [editData.local]: editData.title
      },
      keywords: {
        [editData.local]: editData.keywords
      },
      publicationType: {
        title: selectedData.name_singular, id: selectedData.id, iconUrl: selectedData.icon.src, groupe:selectedData.groupe
      },
      description : {
        [editData.local]: editData.description
      },
      url: editData.url,
      local:editData.local,
      status: 'published'
    }

 
  setloading(true)
  const req = await fetchInt(updatePublication(InitId), {
    method:'PATCH',
    body: JSON.stringify(insertedData),
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
  }).finally(() => setloading(false))
  const res = await req.json()
  if(res.success){
    notification.success({message:'Publication updated succussfully'})
    goTo('/publish/published',{state:location.state})
  }else{
    notification.error({message:res.message})
  }
}
    return (
      
      <div className='relative pr-5 space-y-2' >
         <Form.Item
                    
            name={`id`} 
            hidden
          >
                

            </Form.Item> 
            <div className="md:grid grid-cols-7 gap-4 items-center">
                  <p className="md:text-end font-bold col-span-2">
                    Language
                  </p>
                  <div className="col-span-5">
                  <Form.Item
                          className="group"
                          style={{marginBottom: 0}}
                          initialValue={'en'}
                          name={'local'}
                          required
                    >
                      <Select
                          className=" rounded language transition-none w-[100px] border border-main  "
                            defaultValue={'en'}
                            style={{
                              height:'var(--input-height)'
                            }}
                            options={[
                              {
                                value: 'en',
                                label: 'English',
                              },
                              {
                                value: 'fr',
                                label: 'French',
                              }
                            ]}
                          />
                      
                    </Form.Item>
                  
                         
                             
                  </div>
            
              </div>
              {selectedData && (
                <div className="md:grid grid-cols-7 gap-4 items-center">
                    <p className="md:text-end font-bold col-span-2">
                    Type
                    </p>
                    <div className="col-span-5">
                    <Form.Item
                          validateStatus={errors?.[`publicationType`] && "error"}
                          help={errors?.[`publicationType`] && errors?.[`publicationType`]}
                          className="group"
                          style={{marginBottom: 0}}
                          initialValue={{title: selectedData.name_singular, id: selectedData.id, iconUrl: selectedData.icon.src, groupe:selectedData.groupe}}
                          name={'publicationType'}
                          required
                    >
                        <div className="flex space-x-2 border border-main  border-opacity-20 rounded input-h items-center px-3 bg-skin-fill-muted text-skin-muted fill-skin-muted">
                            <ReactSVG className='icon-sm' src={`${storageUrl}${selectedData?.icon.src}`}/>
                            <p className='rounded flex-1'>{selectedData?.name_singular?.en}</p>
                        </div>
                              
                      </Form.Item>
                          
                              
                    </div>
                </div>

              )}
              <div className="md:grid grid-cols-7 gap-4 items-center">
                <p className="md:text-end font-bold col-span-2">Url</p>
                <div className="col-span-5">
                  <Form.Item
                    validateStatus={errors?.url && "error"}
                    help={errors?.url && errors?.url}
                    className="group"
                    style={{marginBottom: 0}}
                    name="url"
                      initialValue={url}
                      required
                  >
                  
                      
                        <input
                          type="text"
                          placeholder={'URL de la page web'}
                          autoComplete="off"
                          className="bg-skin-fill-muted focus:border border border-main border-opacity-20 rounded input-h w-full placeholder:text-muted"
                          disabled
                        />
                    
                  </Form.Item>
                </div>
                
              </div>
              <div className="md:grid grid-cols-7 gap-4 items-center">
                <p className="md:text-end font-bold col-span-2">Title </p>
                <div className="col-span-5"> 
                  <Form.Item
                      validateStatus={errors?.title && "error"}
                      help={errors?.title && errors?.title}
                      className="group"
                      style={{marginBottom: 0}}
                      name={`title`}
                      required
                    >
                      <input
                      type="text"
                      placeholder={'Link title'}
                      
                      autoComplete="off"
                      className=" border border-main border-opacity-20 rounded input-h w-full"
                      />

                    </Form.Item>
                            
                </div>
              </div>
              <div className="md:grid grid-cols-7 gap-4">
                <p className="md:text-end font-bold col-span-2  pt-[10px]">
                  Description
                </p>
                <div className="col-span-5">
                  <Form.Item
                      validateStatus={errors?.description && "error"}
                      help={errors?.description  && errors?.description }
                      className="group"
                      style={{marginBottom: 0}}
                      name={'description'}
                      required
                    >
                       
                      <RichText initialValue={initDescription} disabled={false} editorRef={editorRef} onChange={value => form.setFieldValue('description', value)}/>
                    </Form.Item>
                </div>
              </div>
              <div className="md:grid grid-cols-7 gap-4 ">
            
                <p className="md:text-end font-bold col-span-2  pt-[10px]">
                  Keywords
                </p>
                <div className="col-span-5"> 
                  <Form.Item
                      validateStatus={errors?.keywords && "error"}
                      help={errors?.keywords  && errors?.keywords }
                      className="group"
                      style={{marginBottom: 0}}
                      name={`keywords`}
                      required
                    >
                    <Select
                        className=" rounded transition-none w-full rightpage  border-opacity-20 "
                        mode="tags"
                        open={false}
                        style={{width: "100%", background:'#fff'}}
                        placeholder={'Insert a maximum of 8 keywords'}
                        maxLength={8}
                        // value={keywords}
                        
                        // onDeselect={(e) => setkeywords((prev:string[] ) => (prev.filter(item => item !== e)))}
                        // onSelect={(e) => setkeywords((prev:string[]) => ([...prev, e]))}
                    />       
 
                    </Form.Item>
                </div>
              </div>
              <div className='md:grid grid-cols-7'>
                <div className='col-span-7 '>
                  {InitId ? (
                    <button type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted' disabled={loading} onClick={Update}>Edit</button>
                  ) : (
                    <button type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted' disabled={!active.publish} onClick={handleNext}>Next</button>

                  )}


                </div>
              </div>
      

      </div>
  )
}
