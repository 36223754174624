import React from 'react'

type Props = {
  url: string,

}
export default function UrlMode({url}: Props) {  
 
  return (
   
        
        <div className='w-full h-full'>
          {/* <WebviewUrl
            url={url}
            partition='details'
            zoom={0.4}
          /> */}
          <iframe src={url} className='w-full h-full'></iframe>
        </div>

      
 
  )
}
