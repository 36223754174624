import React, { useEffect, useState } from 'react'

import { PartnerType } from '../../types'
import { getVolumCollaborators } from '../../services/api/request'

export default function useGetPartners({setloading, id, coll, suffix} : {setloading:any, id:string, coll:string, suffix?:string}) {
    const [partners, setpartners] = useState<null | PartnerType[]>(null)
    const [sponsors, setsponsors] = useState<null | PartnerType[]>(null)


    useEffect(() => {
        if( id && coll){
            setloading(true)
            fetch(getVolumCollaborators({volumId: id, volumColl:coll})).then((res) => res.json()).then(data => {
                setpartners(data.data?.partners)
                if(data.data?.partners?.length > 0){
                  // handleSelectPartner(data.data?.partners[0])
                }else{
                  if(data.data?.sponsors?.length > 0){
                      // handleSelectPartner(data.data?.sponsors[0])
                  }
              }
                setsponsors(data.data?.sponsors)
                
            }).catch(err => console.log(err)).finally(() => setloading(false))
  
        }
    }, [id, coll])
  return {
    sponsors,
    partners
  }
}
