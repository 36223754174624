import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.396 7.99997C16.614 7.99897 16.79 7.82197 16.79 7.60497C16.79 7.38697 16.613 7.21097 16.395 7.21097C16.177 7.21097 16 7.38697 16 7.60497C16 7.82297 16.177 7.99997 16.395 7.99997"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.396 3.77002C16.603 3.76902 16.77 3.60102 16.77 3.39402C16.77 3.18702 16.602 3.02002 16.395 3.02002C16.188 3.02002 16.02 3.18802 16.02 3.39502C16.02 3.60202 16.188 3.77002 16.396 3.77002"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.98 7.60398C20.98 7.39698 20.812 7.22998 20.605 7.22998C20.398 7.22998 20.231 7.39798 20.231 7.60498C20.231 7.81198 20.399 7.97998 20.606 7.97998C20.812 7.97998 20.98 7.81198 20.98 7.60398"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 21V3C7.029 3 3 7.029 3 12C3 16.971 7.029 21 12 21C16.971 21 21 16.971 21 12H12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
