import React, { useEffect, useState } from 'react'
import { searchDatacenters } from '../../../../services/search/search';
import { VolumeSearch } from '../../../../types';
import Domain from './Domain';
type Props = {
  openDomain : (name:string) => void
}
export default function PopularDomains({ openDomain } : Props) {
    const [domains, setdomains] = useState<{document:VolumeSearch}[]>([]);

    useEffect(() => {
         
       (async () => {
            const res:any = await searchDatacenters({q: '*', filter: 'status:enabled && popular:true'})
            setdomains(res.hits)
            
       })()
    }, []);
  return (
    <div className='flex flex-wrap w-full'>
        {domains.map(domain => (
            <Domain
                key={domain.document.id}
                domain={domain.document}
                onSelect={(domain) => openDomain(domain.title.en)}
                active={false}
            />
        ))}
    </div>
  )
}
