import React, { useEffect, useState } from 'react'
import { getActiveGroupsWithChilds } from '../services/api/request/publications';


export default function useGetPubsTypes() {
    const [loading, setloading] = useState(false);
    const [publicationsTypes, setpublicationsTypes] = useState<any>([]);
    
    useEffect(() => {
        (async() => {
            setloading(true)
            if(!publicationsTypes || publicationsTypes?.length === 0){
                const data = await fetch(getActiveGroupsWithChilds).then(res => res.json())
                setpublicationsTypes(data.data)
            }
            setloading(false)
        })()  
      
    }, []) 
  return {
    loading,
    publicationsTypes
  }
}
