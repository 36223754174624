import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'


import TreeLayout from './Layout';
import { iconType, Server } from '../../../../types';
import { getClusterTree, getTree } from '../../../../services/api/request';
import { storageUrl } from '../../../../services/api/constants';
import useSearchLocations from '../../../hooks/useSearchLocations';



// const reRenderTree = (tree:any) => {
//     return tree.map((node:Server) => {
//         if (node.id === id) {
//             return {
//                 ...node,
//                 children:data,
//             };
//         }
//         if (node.children) {
//             return {
//                 ...node,
//                 children: loopThrough(node.children, id, data)
//             };
//         }
//         return node;
//     });
// }
const loopThrough = (tree:any, id:string, data:any) => {
  return tree.map((node:Server) => {
      if (node.id === id) {
          return {
              ...node,
              children:data,
          };
      }
      if (node.children) {
          return {
              ...node,
              children: loopThrough(node.children, id, data)
          };
      }
      return node;
  });
}
const generateIds = (tree:any[], id:string) => {
    return tree.map(el => {
        el.path = `/${id}${el.path}`
        if(el.children){
            generateIds(el.children, id)
        }
        return el
    })
}


type Props = {

    defaultExpandedKeys?:any[],
    serverId:string|undefined
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    containerRef:any,
    openAction : ({title, icon,key, id}:{title:string, icon:iconType, id:string, key:string}) => void,
    datacenter: Server | null, 
    settree: (data:Server[] | null) => void,
    tree: Server[] | null,
    setResultsLength:any
}


export default function TreeComponentWorld({ handleLocationSelection, selectedKey,containerRef, openAction, datacenter, tree, settree, serverId,setResultsLength}: Props) {

    const [loading, setloading] = useState(false);
    const {resultsLength} = useSearchLocations({
        query: '*', 
        filter: `&& roots:=${datacenter?.id}`,
        limit:1
      })
  
      useEffect(() => {
        
        setResultsLength(resultsLength)
      }, [resultsLength]);





  useEffect(() => {
    if(datacenter && tree?.length === 0){
        settree([])
        setloading(true)
        fetch(getTree({id: datacenter.id})).then(res => res.json()).then(data =>{
             settree(data.data)
            //  setexpandedKeys(['0', '1', '2', '3', '4', '5'])
            //  setautoExpandParent(false)
        }).catch(e => console.log(e)).finally(() => setloading(false))
    }
    }, [datacenter?.id]);
    const updateTree:any = async( data:Server) =>{  
        if(data.children) return
        const  children = await fetch(getClusterTree({id:data.id})).then(res => res.json())
    
     
        const newTree =  loopThrough(tree, data.id, children.data)
        settree(newTree)
    }
    useEffect(() => {
        
        if(serverId && tree && tree.length >0){
            (async () => {
                const  children = await fetch(getClusterTree({id:serverId})).then(res => res.json())
        
         
                const newTree =  loopThrough(tree, serverId, children.data)
                settree(newTree)

            })()
        }
    }, [serverId, tree?.length]);
  return (
    <>
  
        <Spin
            spinning={loading}
        >
            {tree && tree?.length > 0 && (

                 <TreeLayout
                    tree={
                        tree ? [
                            {
                                title:datacenter?.title, 
                                iconUrl:`${storageUrl}${(datacenter as any)?.icon?.src}`,
                                iconType: 'image',
                                id: '/',
                                key:'/',
                                path: '/',
                                // isLeaf:true,
                                children: tree
                            },
                            // ...generateBreadcrumbs(tree, null)
                        ] : []
                    }
                    updateTree={updateTree}
                    handleLocationSelection={handleLocationSelection}
                    selectedKey={selectedKey}
                    openAction={openAction}
                    containerRef={containerRef}
                 
                 />
                
                )}

        </Spin>
          
    
    </>
  )
}
