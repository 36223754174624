import React from 'react'

import { useSelectionHandlers } from '../../../hooks/useSelectionHandler'
import { Highlight, Publication } from '../../../../types'
import Publications from '../../../../components/shared/publications'
import { displayModes } from '../../../context/UIContext'



type Props = {
    suffix: string,
    publications: {document:Publication, highlight:Highlight}[],
    searchon:boolean,
    totalHits: { pubs: number | null, webresources:number | null, partnerPubs?:number|null} ,
    partnerId?:string,
    barActivatedFields: { author:boolean, date:boolean},
    displayMode: displayModes,
    setRecheckPin: () => void

}
const PartnerPublications = React.memo(({ suffix, publications, searchon, totalHits, partnerId, displayMode, setRecheckPin, barActivatedFields } : Props) => {
    const { handleSelectPublication } = useSelectionHandlers(suffix)
    return (
    <div className={`space-y-2 ${partnerId && 'mb-10'}`}>
        <p className="text-groupe">
        
            {searchon ? `${totalHits.partnerPubs} articles found` : `Partner's recently added articles`}
       
        </p>
        <div className='ml-[-5px] h-full'>
        
            {(publications && publications?.length >0) ? (
            <>
         
             
                <Publications barActivatedFields={barActivatedFields} displayMode={displayMode} setRecheckPin={setRecheckPin} publications={publications} handlePublicationSelection={(item:{document:Publication, highlight?:Highlight}) => handleSelectPublication(item)} />
                {/* {
                    partnerId && totalHits.partnerPubs && totalHits.partnerPubs> 7 && (
                        <button className='underline mt-3' onClick={() => handleSelectSeeAllPartnerPublications(partnerId)}>see all articles</button>

                    )
                } */}
           
            </>
            ) :( 
            <p>No publication</p>
            )}
        
        </div>
    </div>
  )
}
)

export default PartnerPublications