
import { useParams } from "react-router-dom";
import OneLineItem from "./format/OneLine";
import TwoLineItem from "./format/TwoLines";
import Blocks from "./format/Block";
import { Highlight, Publication, TypeMenuItem } from "../../../types";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

type Props ={
  publications:{document:Publication, highlight?:Highlight}[],
  handlePublicationSelection: (item:{document:Publication, highlight?:Highlight}) => void,
  displayMode : 'OneLine' | 'TwoLines' | 'Blocks' | 'Thumb',
  setRecheckPin: ()=> void,
  extraActionsItems?:(item:Publication) => TypeMenuItem[],
  barActivatedFields: { author:boolean, date: boolean}
}

export default function Publications({ publications, handlePublicationSelection, displayMode, setRecheckPin, extraActionsItems, barActivatedFields } : Props) {
  const params = useParams()

  const renderDate = (date:number) => {
    const formatedDate = new Date(date * 1000).toLocaleDateString("en-US")
    return `${formatedDate}`
  } 
    return (
        <div className={`menu-publication relative w-full -ml-[1px] `}>
          {(displayMode === 'OneLine') && (
            <div>
               {publications && publications?.length > 0 && publications.map((item:any) => (
                    <OneLineItem
                    item={item}
                    active={item.document?.id && (params?.publicationId === item.document?.id || params?.webpageId === item.document?.id)}
                    renderDate={renderDate}
                    handlePublicationSelection={handlePublicationSelection}
                    setRecheckPin={setRecheckPin}
                    extraActionsItems={extraActionsItems}
                    barActivatedFields={barActivatedFields}
                  />
                 
                
                  
              ))}
            </div>
          )}
         {(displayMode === 'TwoLines') && (
            <div className="flex flex-wrap">
                {publications && publications?.length > 0 && publications.map((item:any) => (
                      <TwoLineItem
                        item={item}
                        active={item.document?.id && (params?.publicationId === item.document?.id || params?.webpageId === item.document?.id)}
                        renderDate={renderDate}
                        handlePublicationSelection={handlePublicationSelection}
                        setRecheckPin={setRecheckPin}
                        extraActionsItems={extraActionsItems}

                      />  
                ))}

            </div>

         )}

        {(displayMode === 'Blocks') && (
          <div className="pr-2">
             <ResponsiveMasonry
             columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
         >

              <Masonry gutter={'17px'}>

                    {publications && publications?.length > 0 && publications.map((item:any) => (
                          <Blocks
                            item={item}
                            active={item.document?.id && (params?.publicationId === item.document?.id || params?.webpageId === item.document?.id)}
                            renderDate={renderDate}
                            handlePublicationSelection={handlePublicationSelection}
                          />  
                    ))}
              </Masonry>


            </ResponsiveMasonry>
          </div>

         )}
           
         
        </div>
    
  )
}