import { IconOptions } from "../../../types"

export default function ViewAsListIcon({ className }:IconOptions) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        fill="currentColor"
        className={className}
      >
        <path
          fillRule="evenodd"
          d="M43.2,14.4H21.6a4.81,4.81,0,0,1-4.8-4.8V4.8A4.8,4.8,0,0,1,21.6,0H43.2A4.8,4.8,0,0,1,48,4.8V9.6a4.81,4.81,0,0,1-4.8,4.8ZM21.6,4.8h0V9.6H43.19V4.8Z"
        />
        <path
          fillRule="evenodd"
          d="M43.2,31.2H21.6a4.81,4.81,0,0,1-4.8-4.8V21.6a4.8,4.8,0,0,1,4.8-4.8H43.2A4.8,4.8,0,0,1,48,21.6v4.8a4.81,4.81,0,0,1-4.8,4.8ZM21.6,21.6h0v4.8H43.19V21.6Z"
        />
        <path
          fillRule="evenodd"
          d="M43.2,48H21.6a4.81,4.81,0,0,1-4.8-4.8V38.4a4.8,4.8,0,0,1,4.8-4.8H43.2A4.8,4.8,0,0,1,48,38.4v4.8A4.81,4.81,0,0,1,43.2,48ZM21.6,38.4h0v4.8H43.19V38.4Z"
        />
        <path
          fillRule="evenodd"
          d="M9.6,14.4H4.8A4.81,4.81,0,0,1,0,9.6V4.8A4.8,4.8,0,0,1,4.8,0H9.6a4.8,4.8,0,0,1,4.8,4.8V9.6a4.81,4.81,0,0,1-4.8,4.8ZM4.8,4.8h0V9.6H9.59V4.8Z"
        />
        <path
          fillRule="evenodd"
          d="M9.6,31.2H4.8A4.81,4.81,0,0,1,0,26.4V21.6a4.8,4.8,0,0,1,4.8-4.8H9.6a4.8,4.8,0,0,1,4.8,4.8v4.8a4.81,4.81,0,0,1-4.8,4.8ZM4.8,21.6h0v4.8H9.59V21.6Z"
        />
        <path
          fillRule="evenodd"
          d="M9.6,48H4.8A4.81,4.81,0,0,1,0,43.2V38.4a4.8,4.8,0,0,1,4.8-4.8H9.6a4.8,4.8,0,0,1,4.8,4.8v4.8A4.81,4.81,0,0,1,9.6,48ZM4.8,38.4h0v4.8H9.59V38.4Z"
        />
      </svg>
    );
  }
  