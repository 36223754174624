import * as React from "react"
import { IconOptions } from "../../../../types"

const MyContacts = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={100}
    height={100}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 2940 2940"
    {...props}
  >
    <defs>
      <style>
        {".fil0,.fil18{fill:none;fill-rule:nonzero}.fil18{fill:inherit}"}
      </style>
    </defs>
    <g id="Plan_x0020_1">
      <path id="_2091690086992" d="M2940 2940H0V0h2940z" className="fil0" />
      <path id="_2091690086176" d="M2940 2940H0V0h2940z" className="fil0" />
      <g id="_2091690089536">
        <path
          d="M1930 2590H350c-140 0-260-120-260-260V1010h260v1320h1580v260z"
          className="fil18"
        />
        <path
          d="M2590 2060H880c-150 0-260-110-260-260V620c0-150 110-270 260-270h1710c150 0 260 120 260 270v1180c0 150-110 260-260 260zM880 620v1180h1710V620H880z"
          className="fil18"
        />
        <path
          d="M2460 1140h-530V880h530zM2460 1670h-530v-260h530zM1010 1670c0-240 150-400 400-400 240 0 390 160 390 400h-790zM1670 1010c0 150-120 260-260 260-150 0-270-110-270-260 0-140 120-260 270-260 140 0 260 120 260 260z"
          className="fil18"
        />
      </g>
    </g>
  </svg>
)
export default MyContacts
