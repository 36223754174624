import React, { useEffect } from 'react'
import useFetchDomain from './hooks/useFetchDomain'
import { Spin } from 'antd'
import { Outlet } from 'react-router-dom'
import { useAppContext } from './context/AppContext'

export default function GlobalLayout() {

    const { domain, loading } = useFetchDomain()
    const { setDomain } = useAppContext()
    useEffect(() => {
      
      document.title = 'Qorddu'
    }, []);

    useEffect(() => {
        
       setDomain(domain)
    }, [domain]);
  return (
    <Spin spinning={loading}>
        <Outlet/>
    </Spin>
  )
}
