import React, { useEffect, useState } from 'react'
import WithDivider from '../../../../../components/shared/WithDivider'
import Network from './Network'
import { Server } from '../../../../../types';
import { getNodeDomains } from '../../../../../services/api/request';
import { heightWindow } from '..';
import { Dropdown } from 'antd';
import DomainDropdown from './DomainDropdown';

type Props = {
    selectedNode : Server | null,
    openDomain: (name:string) => void
}
export default function Domains({selectedNode, openDomain}: Props) {
    const [domains, setdomains] = useState<Server[]>([]);
    const [selectedDomain, setselectedDomain] = useState('');


    useEffect(() => {
      if(selectedNode){
        fetch(getNodeDomains({univerId: selectedNode.parentId, nodeId: selectedNode.id, featuredDomain: selectedNode.featuredDomain!})).then(res => res.json()).then(data => setdomains(data.data)).catch(err => console.log(err))
  
  
      }else{
        setdomains([])
      }
    }, [selectedNode]);
  return (
    <div className="space-y-2">
        <WithDivider>
            <>
            <p className='text-small'>Domains</p>
            </>
        </WithDivider>
        <div className={`${heightWindow} overflow-auto`}>
            {domains?.map((domain,i) => (
                <Dropdown
                    key={domain.id}
                    trigger={['click']}
                    onVisibleChange={visible => setselectedDomain(visible ? domain.id : '')}
                    overlay={<DomainDropdown openDomain={() => openDomain(domain.title)}/>}
                >
                        <button className='w-full'>
                            <Network withDropdown={false} network={{server:domain, color: '#fff', textColor: '#000', border:true}} active={domain.id === selectedDomain} onSelect={() => {}} setRevalidate={() => {}} />

                        </button>
                </Dropdown>
            

            ))}
            
        </div>
    </div>
  )
}
