import { Dropdown, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { useMydeskContext } from './context/MydeskContext'
import useSetBreadcrumb from './useSetBreadcrumb'

import Sidebar from './components/sidebar'
import WithSidebar from '../components/shared/Layouts/WithSidebar'
import TopBar from '../components/shared/bars/TopBar'
import Icon from '../components/icons/Icon'
import { useAppContext } from '../context/AppContext'
import { useAuthContext } from '../context/AuthContext'
import { signOut } from 'firebase/auth'
import { auth } from '../utils/firebase'
import { fetchInt } from '../utils/api/fetchInt'
import { getUserSubscriptions, updateUser } from '../services/api/request'
import Tutorial from './components/Tutorial'


export default function MydeskLayout() {
    const { breadcrum, disabled, query, setQuery, setUserCurrentSubscription } = useMydeskContext()
    const { domain } = useAppContext()
    const {user} = useAuthContext()
    const [sideBarWidth, setsideBarWidth] = useState(300);
    const [hidePanel, sethidePanel] = useState(false);
    const navigate = useNavigate()
    useSetBreadcrumb({datacenter: domain ? {name:domain.title, id:domain.id} : null, type: null})
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const [startTutorial, setstartTutorial] = useState(false);

    const logout = () => {
        signOut(auth).then(() => {
            

        }).catch((err) => console.log(err))
    }
    const handleLocationSelection = (item:any) => {
        navigate(`/mydesk${item.key}`)
    }


    useEffect(() => {
        
       if(!user?.firstTimeAccess){
        setstartTutorial(true)
        fetchInt(updateUser, {
            method: 'POST', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify({firstTimeAccess:true})
        }).then(res => res.json()).then(() =>{}).catch(err => console.log(err))
       }
    }, [user?.id]);

    useEffect(() => {
        fetchInt(getUserSubscriptions(true)).then(res => res.json()).then(data => setUserCurrentSubscription(data.data)).catch(err => console.log(err))
        
    }, []);
    return (

        <div className="flex flex-col h-full teddra-rounded rounded-t-none overflow-hidden ">
            <div className="bar-h flex justify-between  px-[29px] items-center">
                <button className='flex space-x-3 items-center' onClick={() => navigate('/')}>
                    <Icon name='TeddraLogo' className='icon-sm'/>
                    <p className='font-bold'>Qorddu</p>
                </button>
                <p className='font-bold'>{domain?.title}</p>
                {user?.id && (
                    <Dropdown
                        onVisibleChange={visible => setdropdownVisible(visible)}
                        destroyPopupOnHide
                        trigger={['click']}
                        overlay={<div className='bg-sub-windows rounded shadow w-[150px] '>
                            <Link to={'/mydesk'} target='_blank'className='border-b border-main w-full px-4 py-2 bg-hover-transparent block !text-inherit'>
                                Open mydesk
                            </Link>
                            <button className='block w-full text-start py-2 bg-hover-transparent px-4' onClick={logout}>
                                Logout
                            </button>
                        </div>}
                    >
                        <button id='profile' className={`flex space-x-2 items-center cursor-pointer px-3 py-0.5 rounded no-dragable profile ${dropdownVisible ? 'bg-skin-fill-inverted-main text-skin-inverted' : 'bg-hover-transparent'}`} >
                            
                                
                            {user?.picture ? <div className='bg-white  w-[25px] h-[30px] rounded overflow-hidden border p-[1px]'>
                            <img className='w-full h-full object-cover rounded-[4px]' src={user?.picture}/> 
                                </div> : (
                                    <div className='w-[25px] h-[30px] flex items-center justify-center' ><Icon name='UserIcon' className='w-full h-full '/></div>

                            )}
                                <p className='max-w-[100px] truncate text-[11px] font-light'>{user?.name}</p>

                        </button>
                    </Dropdown>
                )}
            </div>
            <TopBar
                
                path={{
                    onCncelSearch:()=>{},
                    Breadcrumb:breadcrum,
                    search:{disabled:disabled.query, value:query, onChange:e => setQuery(e.target.value)},
                    hidePanel : {open: hidePanel, disabled: false},
                    setHidePanel: (open:boolean) => sethidePanel(open)
                }} 
               
            />
            <div className="flex-1  flex flex-col" id='test'>
                <WithSidebar
                setWidth={setsideBarWidth}
                sidebar={<Sidebar containerHeight='height-with-1bars' handleLocationSelection={handleLocationSelection}/>}
                hideSidebar={false}

                >
                <div className={`flex flex-1 flex-col transition-all bg-sub-windows z-100 relative ${hidePanel && `shadow-sidebar-opened  `}`} style={{marginLeft: hidePanel ? -sideBarWidth+30 : 0}}>

                <div className={`flex-1 pl-8 pt-5`}>
                <Outlet/>
                </div>

                </div>
                </WithSidebar>

                 
            </div>
            {!user?.currentSubscription?.id && (
            <div className="absolute bottom-0 left-0 w-full bar-h-mini bg-red-700 text-center text-white flex items-center justify-center">
                <Link to={'/connect/signup/steps/1'} className='underline text-inherit'>Please continue your subscribtion</Link>
            </div>

            )}
            {/* <Tutorial/> */}
        </div>

  )
}
