import { iconType } from "../../../types"
import { RenderIcon } from "../icon"

type Props =   {
    active?:boolean,
    suffix?:{icon:iconType, size:'sm' | 'mini', onClick?:() => void},
    prefix?:{icon:iconType, size:'sm' | 'mini'},
    children:JSX.Element,
    buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>,
    mode?: 'tab' | 'button',className?:string,
}
export const ButtonLg = (props:Props) => {
    return (
        <button className={`disabled:text-skin-muted px-3 flex justify-between items-center  bg-hover-transparent rounded cursor-pointer input-h no-dragable ${props.active ?  props.mode !== 'tab' ? 'bg-skin-fill-inverted text-skin-inverted fill-skin-inverted' : 'active-mode' : 'bg-hover-transparent'} no-dragable  disabled:opacity-20`} {...props.buttonProps}>
            <div className="flex space-x-2 items-center flex-1">
                {props.prefix && (
                    <RenderIcon icon={props.prefix.icon} iconSize={props.prefix.size === 'sm' ? 'icon-sm' : 'icon-mini'}/>
                )}
                <p>
                    {props.children}
                </p>

            </div>
            {props.suffix && (
                <button onClick={props.suffix.onClick}>

                    <RenderIcon icon={props.suffix.icon} iconSize={props.suffix.size === 'sm' ? 'icon-sm' : 'icon-mini'}/>
                </button>
            )}
          
        </button>
    )
}