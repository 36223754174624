// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import { Marker, NavigationControl} from 'react-map-gl';
import Map from 'react-map-gl';
import { useLocation } from 'react-router-dom';
import { Popover } from 'antd';
import ObjectMd from '../ObjectMd';


const markers = [
    {
        longitude:'2.4536288930766945',
        latitude: '47.0772932125064765',
        title: 'France',
        traffic: '33%',
        visits: '14K',
        requests:'1,2M'
    },
    {
        longitude:'-102.32092271140587',
        latitude: '41.813550332104526',
        title: 'USA',
        traffic: '15%',
        visits: '8K',
        requests:'590K'
    },
    {
        longitude:'53.118281821737284',
        latitude: '23.499728788500235',
        title: 'UEA',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    },
    {
        longitude:'-3.411857904384036',
        latitude: '39.68254147487251',
        title: 'Spain',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    },
    {
        longitude:'-8.114006163705632',
        latitude: '39.64871313872702',
        title: 'Portugal',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    },
    {
        longitude:'7.706305736815614',
        latitude: '46.9283933700329',
        title: 'Suisse',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    },
    {
        longitude:'5.421149879807249',
        latitude: '50.68834685109662',
        title: 'Belgium',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    },
    {
        longitude:'12.276618370033125',
        latitude: '42.884695864704526',
        title: 'Italy',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    },
    {
        longitude:'2.628350593',
        latitude: '32.12636396226329',
        title: 'Algeria',
        traffic: '5%',
        visits: '1,3K',
        requests:'80K'
    }
]
// 47.077293212506476, 2.4536288930766945
// 41.813550332104526, -102.32092271140587
// 23.499728788500235, 53.118281821737284
export default function MapComp() {
    const mapRef = useRef<any>()
    const location = useLocation()
    const [viewState, setViewState] = React.useState({
      longitude: 5.309707110111349,
      latitude:40.7380930190072,
      zoom:2.79,
 
    });
   
    useEffect(() => {
        setViewState(prev => ({...prev , longitude:markers[0].longitude, latitude:markers[0].latitude}))
      
    }, [])
    
 
    return (
            <>
                 
             
    
         <div className='w-full h-full overflow-hidden relative'>
           
            <Map
                
            
                {...viewState}
                onMove={evt => {setViewState(prev =>( {...evt.viewState,zoom:prev.zoom }))}}
                mapboxAccessToken={'pk.eyJ1IjoicmFuaWExNiIsImEiOiJjbGJ0N2F5dnAxNGkwM29zMzMyc3lsOThpIn0.PoH4AkKSGF8HnrO5j9PS0A'}
                ref={instance => mapRef.current = instance}
                sty582443le={{width: '100%', height: '100%'}}
                mapStyle={'mapbox://styles/rania16/clmm4fek901um01r48zjdf6id'}
                renderWorldCopies={false}
                
                // projection='mercator'
            > 
              <div className="mt-4">
               {/* <NavigationControl  /> */}

               {markers.map(marker => (
                    <Marker key={marker.title} latitude={marker?.latitude} longitude={marker?.longitude} anchor="center"  pitchAlignment='map' rotationAlignment='map'    >
                        <Popover
                        // className='popover-black'
                        // overlayClassName='popover-black'
                        trigger={'click'}
                            content={
                                <div className=' w-[700px] px-8 py-3 pb-8 rounded-[12px] text-black bg-white'>
                                    <p className="font-bold text-[20px]">{marker.title}</p>
                                    <div className='grid grid-cols-3 gap-x-4 mt-6'>
                                        <ObjectMd
                                            title={marker.traffic}
                                            icon={{type: 'icon', src: 'Pie'}}
                                            description={`of global traffic  from ${marker.title}`}
                                        />
                                        <ObjectMd
                                            title={marker.requests}
                                            icon={{type: 'icon', src: 'SearchIcon'}}
                                            description={`Requests form ${marker.title}`}
                                        />
                                        <ObjectMd
                                            title={marker.visits}
                                            icon={{type: 'icon', src: 'UserIcon'}}
                                            description={`Visits form ${marker.title}`}
                                        />
                                    

                                    </div>
                                </div>
                            }
                        >

                        <button className='relative w-[20px] h-[20px] flex flex-col items-center group' >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-[100px] h-[100px] text-bg-selected   `}>
                        <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                        </svg>


                        

                        </button>

                        </Popover>
                    </Marker>  

               )) }
            </div>
  
              

             


        
              
            
            </Map>
         </div>

     
   



     
 
          
         
   
            
            </>


             

    );
  
 
};

