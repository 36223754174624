import { Route, Routes } from 'react-router-dom'

import FoldersLayout from './Layout'
import ServerLocationLayout from './pages/serverHome'
import PartnersNetwork from './pages/partnersNetwork'
import PublicationDetailsPage from './pages/publications/details'
import AllPublications from './pages/publications/all'
import PartnerDetailsUrl from './pages/partnerDetails'
import VolumeDetailsPage from './pages/volumeDetails'


import PublicDrive from './pages/drives/public'
import AllPublicationsPublicDrive from './pages/drives/public/all'
import OwnerDetailsUrl from './pages/ownerDetails'
import PrivateVolume from './pages/volumes/private'
import AllPublicationsPrivateVolume from './pages/volumes/private/all'
import WorldLocationlayout from './pages/world'
import DatacenterLocationLayout from './pages/datacenters'
import Website from './pages/website'
import Group from './pages/group'
import WorldPartners from './pages/world/partners'
import { QfsContextProvider } from './context/qfsContext'
import { SearchContextProvider } from './context/searchContext'
import { UIContextProvider } from './context/UIContext'



export default function FilesNavigation() {

  
  return (
    <QfsContextProvider>
      <SearchContextProvider>
        <UIContextProvider>

          <Routes>
          

              {/** FOLDERS **/}
              <Route element={
                
                  <FoldersLayout/>
              
              }>
            
              
                {/* <Route path='/:desqId/specializeds/:coll/:id/node' element={Locationlayout/>}/>
                <Route path='/:desqId/specializeds/:coll/:id/server' element={Locationlayout/>}/> */}
                  <Route element={<AllPublications suffix='server/' home/>}>
                    <Route path='/:serverId/server/publications/all' element={<></>}/>
                    <Route path='/:serverId/server/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>

                    
                  </Route>
                  <Route element={<DatacenterLocationLayout/>}>
                    <Route path='/Datacenters/:id' element={<></>}/>
                    <Route path='/Datacenters/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                    <Route path='/Datacenters/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                  </Route>
                    
                  <Route element={<ServerLocationLayout/>}>
                    <Route path='/LocalClusters/:id' element={<></>}/>
                    <Route path='/LocalClusters/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/LocalClusters/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/LocalClusters/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/LocalClusters/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/LocalClusters/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='LocalClusters'/>}>
                    <Route path='/LocalClusters/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/LocalClusters/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='LocalClusters'/>}>
                    <Route path='/LocalClusters/:id/group/:group' element={<></>}/>
                    <Route path='/LocalClusters/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>




                  <Route element={<AllPublicationsPublicDrive/>}>
                    <Route path='/:serverId/RegionalServers/:id/publications/all' element={<></>}/>
                    <Route path='/:serverId/RegionalServers/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<PublicDrive coll='RegionalServers' />}>
                    <Route path='/:serverId/RegionalServers/:id' element={<></>}/>
                    <Route path='/:serverId/RegionalServers/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/RegionalServers/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/RegionalServers/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/RegionalServers/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:serverId/RegionalServers/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='RegionalServers'/>}>
                    <Route path='/:serverId/RegionalServers/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/RegionalServers/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='RegionalServers'/>}>
                    <Route path='/:serverId/RegionalServers/:id/group/:group' element={<></>}/>
                    <Route path='/:serverId/RegionalServers/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>


                  <Route element={<AllPublicationsPublicDrive/>}>
                    <Route path='/:serverId/ThematicServers/:id/publications/all' element={<></>}/>
                    <Route path='/:serverId/ThematicServers/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<PublicDrive coll='ThematicServers' />}>
                    <Route path='/:serverId/ThematicServers/:id' element={<></>}/>
                    <Route path='/:serverId/ThematicServers/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/ThematicServers/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/ThematicServers/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/ThematicServers/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:serverId/ThematicServers/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='ThematicServers'/>}>
                    <Route path='/:serverId/ThematicServers/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/ThematicServers/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='ThematicServers'/>}>
                    <Route path='/:serverId/ThematicServers/:id/group/:group' element={<></>}/>
                    <Route path='/:serverId/ThematicServers/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>


                  <Route element={<AllPublicationsPublicDrive/>}>
                    <Route path='/:serverId/DepartmentDrive/:id/publications/all' element={<></>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<PublicDrive coll='DepartmentDrive' />}>
                    <Route path='/:serverId/DepartmentDrive/:id' element={<></>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='DepartmentDrive'/>}>
                    <Route path='/:serverId/DepartmentDrive/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='DepartmentDrive'/>}>
                    <Route path='/:serverId/DepartmentDrive/:id/group/:group' element={<></>}/>
                    <Route path='/:serverId/DepartmentDrive/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  
            
              
                  <Route element={<AllPublicationsPrivateVolume/>}>
                    <Route path='/:serverId/:coll/:id/publications/all' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>

                    
                  </Route>
                  <Route element={<PrivateVolume/>}>
                    <Route path='/:serverId/:coll/:id' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/owner/:ownerId' element={<OwnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix=':coll'/>}>
                    <Route path='/:serverId/:coll/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix=':coll'/>}>
                    <Route path='/:serverId/:coll/:id/group/:group' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>



                  <Route element={<WorldLocationlayout/>}>
                    <Route path='/partners' element={<WorldPartners/>}/>
                    <Route path='/volumes/:volumeId' element={<VolumeDetailsPage/>}/>

                    <Route path='/*' element={<></>}/>

                  </Route>



                  {/**SERVER**/}
                  <Route element={<AllPublications suffix='server/' home/>}>
                    <Route path='/:desqId/:serverId/server/publications/all' element={<></>}/>
                    <Route path='/:desqId/:serverId/server/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>

                    
                  </Route>
                  <Route element={<ServerLocationLayout/>}>
                    <Route path='/:desqId/LocalClusters/:serverId/' element={<></>}/>
                    <Route path='/:desqId/LocalClusters/:serverId/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/LocalClusters/:serverId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/LocalClusters/:serverId/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/LocalClusters/:serverId/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='LocalClusters'/>}>
                    <Route path='/:desqId/LocalClusters/:serverId/webresources/:websiteId' element={<></>}/>
                    <Route path='/:desqId/LocalClusters/:serverId/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='LocalClusters'/>}>
                    <Route path='/:desqId/LocalClusters/:serverId/group/:group' element={<></>}/>
                    <Route path='/:desqId/LocalClusters/:serverId/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>

                
              
                  {/**PARTNERS NETWORK**/}
                  <Route element={<PartnersNetwork/>}>
                    <Route path='/:desqId/:serverId/partners' element={<></>}/>
                    <Route path='/:desqId/:serverId/partners/partners/:partnerId' element={<PartnerDetailsUrl/>}/>

                  </Route>
                
                  {/**PUBLIC DRIVES**/}
                  <Route element={<AllPublicationsPublicDrive/>}>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/publications/all' element={<></>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>

                    
                  </Route>
                  <Route element={<PublicDrive coll='RegionalServers' />}>
                    <Route path='/:desqId/:serverId/RegionalServers/:id' element={<></>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='RegionalServers'/>}>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='RegionalServers'/>}>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/group/:group' element={<></>}/>
                    <Route path='/:desqId/:serverId/RegionalServers/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>



                  {/**PUBLIC DRIVES**/}
                  <Route element={<AllPublicationsPublicDrive/>}>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/publications/all' element={<></>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>

                    
                  </Route>
                  <Route element={<PublicDrive coll='ThematicServers' />}>
                    <Route path='/:desqId/:serverId/ThematicServers/:id' element={<></>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix='ThematicServers'/>}>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix='ThematicServers'/>}>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/group/:group' element={<></>}/>
                    <Route path='/:desqId/:serverId/ThematicServers/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                
                  {/** PRIVATE VOLUME */}
                  <Route element={<AllPublicationsPrivateVolume/>}>
                    <Route path='/:desqId/:serverId/:coll/:id/publications/all' element={<></>}/>
                    <Route path='/:desqId/:serverId/:coll/:id/all/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    
                  </Route>

                

                  <Route element={<PrivateVolume/>}>
                    <Route path='/:desqId/:serverId/:coll/:id' element={<></>}/>
                    <Route path='/:desqId/:serverId/:coll/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/:coll/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:desqId/:serverId/:coll/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>

                    <Route path='/:desqId/:serverId/:coll/:id/owner/:ownerId' element={<OwnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website suffix=':coll'/>}>
                    <Route path='/:desqId/:serverId/:coll/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:desqId/:serverId/:coll/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Group suffix=':coll'/>}>
                    <Route path='/:desqId/:serverId/:coll/:id/group/:group' element={<></>}/>
                    <Route path='/:desqId/:serverId/:coll/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>

                  
                  <Route path='/*' element={<></>}/>



              </Route>


        





          </Routes>
        </UIContextProvider>
      </SearchContextProvider>

    </QfsContextProvider>
  )
}
