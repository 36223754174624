import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2397 14.2016L11.6608 12.3392L9.79843 11.7603C9.6437 11.7122 9.5297 11.5804 9.50436 11.4204C9.47903 11.2603 9.54674 11.0998 9.67904 11.0062L11.2714 9.88015L11.2464 7.93005C11.2443 7.76802 11.3344 7.61886 11.4788 7.5453C11.6232 7.47173 11.7968 7.48652 11.9267 7.58345L13.4896 8.74984L15.3366 8.12353C15.49 8.07149 15.6597 8.11108 15.7743 8.22566C15.8889 8.34024 15.9285 8.50993 15.8764 8.66339L15.2501 10.5104L16.4165 12.0734C16.5135 12.2032 16.5283 12.3769 16.4547 12.5213C16.3811 12.6656 16.232 12.7557 16.0699 12.7537L14.1199 12.7287L12.9938 14.321C12.9002 14.4533 12.7397 14.521 12.5796 14.4956C12.4196 14.4703 12.2878 14.3563 12.2397 14.2016V14.2016Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99834 16.0017L9.49896 14.501"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={12}
      cy={12}
      r={9.00375}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
