import { Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getVolumOwner } from '../../../services/api/request'


export default function OwnerDetailsUrl() {
    const location = useLocation()
    const [website, setwebsite] = useState<null | string>(null)
    const [loading, setloading] = useState(false)
    const params = useParams()
    
    useEffect(() => {
     if(location.state?.url){
        setwebsite(location.state.url)
     }else{
        if(params.id){
            setloading(true)
            fetch(getVolumOwner({volumId:params.id, volumColl:params.coll!})).then(res => res.json()).then(data => setwebsite(data.owner?.website)).finally(() => setloading(false))
        }
     }
    }, [location.state, params.id])
    

    return (
    <div className='h-full w-full'>
        <Spin spinning={loading}>
            {/* {website && (

                <WebviewUrl partition='ownerDetails' zoom={0.4} url={website} />
            )} */}
            <iframe src={website!} className='w-full h-full'></iframe>
        </Spin>
    
    </div>
  )
}
