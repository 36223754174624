

// @ts-nocheck
import Typesense from "typesense"
import axios from 'axios'

const TYPESENSE_CONFIG:any = {
  
    nodes : [
      {
          host: process.env.REACT_APP_TYPESENSE_HOST,
          port:process.env.REACT_APP_TYPESENSE_PORT,
          protocol:process.env.REACT_APP_TYPESENSE_PROTOCOL
      }
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_KEY
}

export const search = new Typesense.Client(TYPESENSE_CONFIG)

export function searchLocations({q, filter, limit, sort}: {q:string, filter?:string, limit?:number, sort?:string}){
  return search.collections('locations').documents().search({
    'q': q,
    'query_by'  : 'title.en,title.fr, description.en, description.fr',
    'sort_by':sort ? sort : 'title.en:asc',
    'filter_by': `status:enabled ${filter ?? ''}`,
    'text_match_type': 'max_weight',
    'prioritize_token_position':true,
    'exclude_fields':'pinned_sources',
    'limit':limit ? limit : 100,
    query_by_weights: '50,50,20,20',
    "prioritize_num_matching_fields":false,
    "include_fields": "$organizations(*)"
    
 
  })
}

export function searchChildLocations({parentId}: {parentId:string}){
  return search.collections('locations').documents().search({
    'q': '*',
    'query_by'  : '',
    'sort_by':'title.en:asc',
    'filter_by': `status:enabled && parentId:${parentId}`,
    'prioritize_token_position':true,
    'exclude_fields':'pinned_sources'

 
  })
}
let cancelTokenPubs:any
export function searchPublications({q, volumeId,mode='roots', page, offset, type, groupBy, limit, sponsored, extraFilter}: {q:string, volumeId:string, page?: number, offset?:number, type?:{type: 'groupe' | 'type', value:string}, mode?:'roots' | 'volume' , groupBy?:string, limit?:number, sponsored?:boolean, extraFilter?:string}){
  if (cancelTokenPubs) {
    cancelTokenPubs.cancel("Operation canceled due to new request.");
  }

  cancelTokenPubs = axios.CancelToken.source();
  return search.collections('publications_link').documents().search({
    'q': q,
    'query_by'  : 'url,title.en, title.fr,description.en, description.fr, keywords.en, keywords.fr',
    'filter_by': `status:published && ${mode === 'volume' ? `(volume:${volumeId} || volumes:=${volumeId})` : `roots:=${volumeId}`} ${(type?.value && type.type === 'type') ? `&& publicationType.id:${type.value}` : ''} ${(type?.value && type.type === 'groupe') ? `&& publicationGroupe.id:${type.value}` : ''} ${sponsored ? '&& sponsored:true' : ''} ${extraFilter ?? ''}`,
    'group_by' : groupBy?groupBy:'',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,

    facet_by :'publicationType.id',
    'group_limit': 7,
    'prioritize_token_position':true,
    // "sort_by": groupBy ? 'created_at:desc' : 'created_at:desc',
    query_by_weights: '10,7,7,2,2,3,3',
    "prioritize_num_matching_fields":false,
    'prioritize_exact_match':false,
    'text_match_type': 'max_weight',
    num_typos: 1,
    'exclude_fields':'pinned_sources',
    snippet_threshold	: 500

  },{
    cancelToken: cancelTokenPubs.token,
  })

}
let cancelTokenWebresources:any
export function searchWebResources({q, volumeId, groupBy, groupLimit, websiteId, limit, offset, page, mode='roots'}: {q:string, volumeId:string, groupBy?:string, groupLimit?:number, websiteId?:string, limit?:number, page?:number, offset?:number,mode?:'roots' | 'volume'  }){
  if (cancelTokenWebresources) {
    cancelTokenWebresources.cancel("Operation canceled due to new request.");
  }

  cancelTokenWebresources = axios.CancelToken.source();

  return search.collections('web_resources').documents().search({
    'q': q,
    'query_by'  : 'url,title.en, title.fr,description.en, description.fr, keywords.en, keywords.fr',
    'filter_by': `status:published && ${mode === 'volume' ? `(volume:${volumeId} || volumes:=${volumeId})` : `roots:=${volumeId}`} ${websiteId ? `&& main_website:${websiteId}` : ''}`,
    'group_by' : groupBy ? groupBy: '',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,
    'group_limit': groupLimit ? groupLimit : 10,
    // 'text_match_type': 'max_score',
    'prioritize_token_position':true,
    "sort_by": 'created_at:desc',
    'exclude_fields':'pinned_sources',
    query_by_weights: '10,7,7,2,2,3,3',
    "prioritize_num_matching_fields":false,
    'text_match_type': 'max_weight',
    num_typos: 1,
    "include_fields": "$publications_link(*)",
    snippet_threshold	: 500
  
  },{
    cancelTokenWebresources: cancelTokenPubs.token,
  })

}
export function searchUsersPublications({q, userId, page, offset, type, groupBy, limit, sponsored, status}: {status:'published' | 'draft' | 'trash' ,q:string, userId:string, page?: number, offset?:number, type?:{type: 'groupe' | 'type', value:string}, groupBy?:string, limit?:number, sponsored?:boolean}){
  return search.collections('pinned_resources').documents().search({
    'q': q,
    'query_by'  : 'title.en, title.fr, description.en, description.fr, keywords.en, keywords.fr',
    'filter_by': `status:${status} && user.id:=${userId} ${(type?.value && type.type === 'type') ? `&& publicationType.id:${type.value}` : ''} ${(type?.value && type.type === 'groupe') ? `&& publicationGroupe.id:${type.value}` : ''} ${sponsored ? '&& sponsored:true' : ''}`,
    'group_by' : groupBy?groupBy:'',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,
    facet_by :'publicationType.id',
    'group_limit': 7,
    'text_match_type': 'max_score',
    'prioritize_token_position':true,
    "sort_by": groupBy ? '_group_found:asc' : 'created_at:desc',
    'exclude_fields':'pinned_sources',
    snippet_threshold	: 500,
    num_typos:1

  })

}
export function getPinnedDomains({userId}: {userId:string}){
  return search.collections('pinned_domains').documents().search({
    q:"",
    query_by: 'userId',
    include_fields: "$domains(*)"
  })
}

 
export const serverStatistics = ({id}: {id:string}) => {
  let searchRequests:any = {
    'searches': [
      {
        'collection': 'publications_link',
        'filter_by': `status:published && roots:=${id}`,
        
      },
      {
        'collection': 'locations',
        'filter_by': `status:enabled && roots:${id}`,
      }
    ]
  }

  let commonSearchParams =  {
    "q":'*',
}
  return search.multiSearch.perform(searchRequests,commonSearchParams)
}



export function searchDatacenters({q,page, offset, limit, filter, sort}: {q:string, page?: number, offset?:number, limit?:number, filter?:string , sort?:string}){
  return search.collections('domains').documents().search({
    'q': q,
    'query_by'  : 'title.en, title.fr',
    'filter_by': filter ? filter : `status:enabled`,
    limit:limit ? limit : 200,
    page : page? page:1,
    offset: offset?offset:0,
    'sort_by':sort ??'title.en:asc',
    'text_match_type': 'max_score',
    'prioritize_token_position':true

  })

}

export function searchLocalServers({q, datacenterId}: {q:string, datacenterId:string}){
  return search.collections('locations').documents().search({
    'q': q,
    'query_by'  : 'title.en',
    // 'sort_by':'depth:asc',
    'filter_by': `status:enabled && datacenterId:${datacenterId} && geoVolume:true`,
    'text_match_type': 'max_score',
    'prioritize_token_position':true
 
  })

}

export const Homestatistiques = ({id}: {id:string}) => {
  let searchRequests:any = {
    'searches': [
      {
        'collection': 'publications_link',
        'filter_by': `status:published && datacenterId:${id}`
      },
      {
        'collection': 'locations',
        'filter_by': `status:enabled && domainsIds:${id}`,
      
      },
     
    ]
  }

  let commonSearchParams =  {
    "q":'*'
}
  return search.multiSearch.perform(searchRequests,commonSearchParams)
}



export const getPinnedResources = ({userId, dcId}: {userId:string, dcId:string}) => {
  let searchRequests:any = {
    'searches': [
      {
        'collection': 'publications_link',
        'filter_by': `status:published && pinned_sources.${userId}.id:${userId} && pinned_sources.${userId}.shortcut:true && datacenterIds:=${dcId}`,
        'limit':10
        
      },
      {
        'collection': 'locations',
        'filter_by': `status:enabled && pinned_sources.${userId}.id:${userId} && pinned_sources.${userId}.shortcut:true && roots:=${dcId}`,
        'limit':10
        
      },
      {
        'collection': 'publications_link',
        'filter_by': `status:published && pinned_sources.${userId}.id:${userId} && datacenterIds:=${dcId}`,
        'limit':10
        
      },
      {
        'collection': 'locations',
        'filter_by': `status:enabled && pinned_sources.${userId}.id:${userId} && roots:=${dcId}`,
        'limit':10
      }
    ]
  }

  let commonSearchParams =  {
    "q":'*',
    'sort_by':`pinned_sources.${userId}.created_at:desc`,

}
  return search.multiSearch.perform(searchRequests,commonSearchParams)
}
export const getPinnedVolumes = ({userId, dcId, limit, q, offset, page}: {userId:string, dcId:string, limit:number, q:string, page?: number, offset?:number}) => {
  return search.collections('locations').documents().search({
    'q': q,
    'query_by'  : 'title.en, title.fr, description.en, description.fr, keywords.en, keywords.fr',
    'sort_by': `pinned_sources.${userId}.created_at:desc`,
    'filter_by': `status:enabled && pinned_sources.${userId}.id:${userId} && roots:=${dcId}`,
    'limit': limit,
    page : page? page:1,
    offset: offset?offset:0,
    'group_limit': 7,
    'text_match_type': 'max_score',
    'prioritize_token_position':true,
    'exclude_fields':'pinned_sources'
 
  })
}
export const getPinnedPublications = ({userId, dcId, limit, q, type, groupBy, offset, page}: {userId:string, dcId:string, limit:number, q:string, page?: number, offset?:number, type?:{type: 'groupe' | 'type', value:string}, groupBy?:string}) => {
  return search.collections('publications_link').documents().search({
    'q': q,
    'query_by'  : 'title.en, title.fr, description.en, description.fr, keywords.en, keywords.fr',
    'sort_by': groupBy ? '_group_found:asc' : `pinned_sources.${userId}.created_at:desc`,
    'filter_by': `status:published && pinned_sources.${userId}.id:${userId} && datacenterIds:=${dcId} ${(type?.value && type.type === 'type') ? `&& publicationType.id:${type.value}` : ''} ${(type?.value && type.type === 'groupe') ? `&& publicationGroupe.id:${type.value}` : ''}`,
    'limit': limit,
    page : page? page:1,
    offset: offset?offset:0,
    'group_limit': 7,
    'text_match_type': 'max_score',
    'prioritize_token_position':true,
    'group_by' : groupBy?groupBy:'',

    'exclude_fields':'pinned_sources'
 
  })
}

export const publishedIn = ({ids}: {ids:string[]}) => {

  return search.collections('locations').documents().search({
    'q': '*',
    'query_by'  : 'title.en, title.fr',
    'filter_by': `id:[${ids}]`,
  

  })
}

export const getPublication = ({id}:{id:string}) => {
  return search.collections('publications_link').documents(id).retrieve()
}
export const getWebPage = ({id}:{id:string}) => {
  return search.collections('web_resources').documents(id).retrieve()
}
export const getVolume = ({id}:{id:string}) => {
  return search.collections('locations').documents(id).retrieve()
}
export const getServer = ({ids, datacenterIds}: {ids:string[], datacenterIds:string[]}) => {

  return search.collections('locations').documents().search({
    'q': '*',
    'query_by'  : 'title.en, title.fr',
    'filter_by': `id:[${ids}] && cfs_type:localServers && datacenterId:${datacenterIds}`,
  

  })
}

export function searchByCountry({country}: {country:string}){
  return search.collections('locations').documents().search({
    'q': '*',
    'query_by'  : 'title.en',
    'sort_by':'title.en:asc',
    'filter_by': `status:enabled && title.en:${country}`,
    'text_match_type': 'max_score',
    'prioritize_token_position':true,
    'exclude_fields':'pinned_sources',
    'limit':200,
    'include_fields': 'id'

 
  })
}


export const multiSearchByCountry = ({ids, query}: {ids:string[], query:string}) =>{
  let searchRequests:any = {
    'searches': [
      {
        'collection': 'publications_link',
        'filter_by': `status:published`,
        'facet_by':'datacenterIds',
        'query_by'  : 'title.en, title.fr, description.en, description.fr, keywords.en, keywords.fr',

      },
      {
        'collection': 'locations',
        'filter_by': `status:enabled && cfs_type:!=localServers`,
        'exclude_fields':'pinned_sources',
        'facet_by':'datacenterId, cfs_type',
        'query_by'  : 'title.en, breadcrumbs.en, breadcrumbs.fr, description.en, description.fr, title.fr',

      }
    ]
  }

  let commonSearchParams =  {
    "filter_by":`roots:=[${ids}]`,
    "q": query,
    'max_facet_values':50,
 
    "limit":7,
    'query_by'  : 'title.en, title.fr',



}
  return search.multiSearch.perform(searchRequests,commonSearchParams)
}

export const searchVolumesByCountry = ({ids, query,limit, page, offset}: {ids:string[], query:string, limit:number, page:number, offset:number}) => {
  return search.collections('locations').documents().search({
    "q": query,
    'max_facet_values':50,
    'filter_by': `status:enabled && cfs_type:!=localServers && roots:=[${ids}]`,
    'exclude_fields':'pinned_sources',
    'facet_by':'datacenterId, cfs_type',
    'query_by'  : 'title.en, breadcrumbs.en, breadcrumbs.fr, description.en, description.fr, title.fr',
    'limit': limit,
    page : page? page:1,
    offset: offset?offset:0,
 
  })
}

export const searchPublicationsByCountry = ({ids, query,limit, page, offset, type, groupBy}: {ids:string[], query:string, limit:number, page:number, offset:number, type?:{type: 'groupe' | 'type', value:string}, groupBy?:string}) => {
  return search.collections('publications_link').documents().search({
    "q": query,
    'max_facet_values':50,
    'filter_by': `status:published && roots:=[${ids}] ${(type?.value && type.type === 'type') ? `&& publicationType.id:${type.value}` : ''} ${(type?.value && type.type === 'groupe') ? `&& publicationGroupe.id:${type.value}` : ''}`,
    'facet_by':'datacenterIds',
    'query_by'  : 'title.en, keywords.en, keywords.fr, description.en, description.fr, title.fr',
    'limit': (limit && limit > 0) ? limit :15,
    'group_by' : groupBy?groupBy:'',
    page : page? page:1,
    offset: offset?offset:0,
    "sort_by": groupBy ? '_group_found:asc' : 'created_at:desc',

  })
}