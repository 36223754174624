import React from 'react'
import TitleBar from './TitleBar'
import PathBar from './Pathbar'
import { TopBarprops } from './types'
import ActionsBar from './actionsBar'
import { Link } from 'react-router-dom'
import Icon from '../../../icons/Icon'

export default function TopBar(props: TopBarprops) {
  return (
    <div className={`${props.title?.childWin && 'modal-border border-b px-1.5'}`}>
      
        {props.title && (
            <div className="">
                <TitleBar {...props.title}/>
            </div>
        )}
        {props.path && (
            <div className=" bg-sub-windows">
                <PathBar {...props.path}/>
            </div>
        )}
        {/* {props.actions && (
            <div className="border-b border-main bg-sub-windows">
                <ActionsBar {...props.actions}/>
            </div>
        )} */}
    </div>
  )
}
