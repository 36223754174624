import React, { useEffect, useState } from 'react'
import { getVolumOwner } from '../../services/api/request'
import { OwnerType } from '../../types'

export default function useGetOwner({setloading, id, coll, suffix} : {setloading:any, id:string, coll:string, suffix:string}) {
    const [owner, setowner] = useState<undefined | OwnerType>(undefined)

    // const handleSelectOwner = (item:OwnerType) => {
    //     navigate(`/specializeds/${datacenter?.id}/specializeds/${coll}/${id}/${suffix ? suffix : ''}owner/${item.id}`, {state: {url:item.website, item}})
    // }
    useEffect(() => { 
        if( id && coll){
            setloading(true)
            fetch(getVolumOwner({volumId: id, volumColl:coll})).then((res) => res.json()).then((data:any) => {
                setowner(data?.data)
                if(data?.owner){
                    // handleSelectOwner(data.owner)
                }
            }).catch(err => console.log(err)).finally(() => setloading(false))

        }
    }, [id, coll])


  return {
   owner
  }
}
