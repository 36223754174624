import {Link, Outlet} from 'react-router-dom'

import Footer from './components/Footer'

import PathBarContainer from './GlobalLayout/PathBarContainer'
import SidebarContainer from './GlobalLayout/SidebarContainer'
import QfsActionsBar from './GlobalLayout/QfsActionsBar'
import NavigateWindow from './components/NavigateWindow'
import SplashScreen from './GlobalLayout/SplashScreen'
import TopBar from './GlobalLayout/TopBar'
import WelcomSplashScreen from './GlobalLayout/WelcomSplashScreen'




export default function FoldersLayout() {
   
  return (
   
          
            <div className='flex-1  flex flex-col h-full overflow-hidden bg-sub-window'>
              
                <TopBar/>
                <PathBarContainer/>
              
              
                <div className="flex-1  flex flex-col">
                        <SidebarContainer
                        >
                            <>
                                    <QfsActionsBar/>

                                <div className={`flex-1`}>
                                    <Outlet/>
                                </div>
                                <div className='border-t border-main flex items-center bg-topbar'>
                                    <div className="flex items-center w-full justify-between bg-black text-white pr-5">
                                        <Footer />
                                        <NavigateWindow/>
                                    </div>
                                </div>
                            
                            </>
                                
                        </SidebarContainer>
                    
                </div>
              

            
                {/* <div className='absolute bottom-0 right-0'>
                    <div className="bg-skin-fill-inverted text-skin-inverted w-[100px] h-[40px]"></div>
                </div> */}
                <SplashScreen/>
                <WelcomSplashScreen/>
              </div>
            

     
  )
}
