import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6142 20.813C14.4341 20.9931 14.1899 21.0944 13.9352 21.0944H12V19.1592C12 18.9045 12.1013 18.6602 12.2814 18.4802L18.7823 11.9744C19.0923 11.6644 19.5128 11.4902 19.9512 11.4902C20.3895 11.4902 20.81 11.6644 21.12 11.9744V11.9744C21.4302 12.2843 21.6045 12.7047 21.6045 13.1432C21.6045 13.5817 21.4302 14.0022 21.12 14.312L14.6142 20.813Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3833 14.3799L18.7171 16.7137"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3728 15.0576L21.2371 15.922V15.922C21.6921 16.3779 21.6921 17.1161 21.2371 17.5719L19.6611 19.148"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.198 9.56907H12.9604"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.198 13.4109H11.0396"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.11885 21.0938H6.23765C4.6464 21.0938 3.35645 19.8039 3.35645 18.2126V6.68784C3.35645 5.0966 4.6464 3.80664 6.23765 3.80664H17.7624C19.3537 3.80664 20.6436 5.0966 20.6436 6.68784V8.60864"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
