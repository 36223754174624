import { Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Icon from '../../components/icons/Icon';

import Login from '../pages/Login';

export default function LoginLayout() {
   
  
    
  return (
   
       

        <div className=' h-full flex flex-col justify-center relative overflow-hidden '>
            <div className="h-full  w-full px-[100px] relative">
                    <div className="max-[1600px]:w-[45%] w-[43%] flex justify-between items-center relative mt-[50px] max-[1600px]:ml-[20px] ml-[112px]">
                        <div className="flex space-x-2 items-center">
                            <Icon name='TeddraLogo' className='w-[65px] h-[65px]'/>
                            <p className='font-bold text-[20px]'>Qorddu</p>

                        </div>
                    </div>

                <div className=" w-[50%] mr-[123px] py-5 max-[1600px]:ml-[20px] ml-[112px]">
                        <div className=''>
                          
                          
                            <div className="flex w-full">
                                <div className='max-w-[70%] w-full mt-[80px] space-y-10'>
                                    {/* <Collapse defaultActiveKey={['login']}>
                                        <Panel key={'login'} header={<p className='text-group'>Connect</p>}>
                                            <Login/>
                                        </Panel>
                                    </Collapse> */}
                                    <p className="text-[20px] font-bold">Connect</p>
                                    <Login/>
                                    <div>
                                        <div className='max-w-[70%] text-center text-[20px] fornt-bold space-y-4'>
                                            <p className='text-[20px] fornt-bold'>
                                                You don't have an account?  
                                              
                                            </p>
                                            <Link to="/connect/signup/steps/1" className='w-[150px] rounded-full py-2 border border-gray-500 block mx-auto'> Signup</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                
                </div>
                
            </div>
            <div className='absolute top-[-99px] max-[1600px]:right-[-150px] right-[-214px] h-[calc(100%+140px)] w-[57%]'>
                 <div className="bg-skin-fill-muted h-full w-full -rotate-[8deg] img-login"></div>                   
            </div>
        

        </div>
     
        
  )
}
