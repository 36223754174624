import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import LocationgGlobalLayout from '../../components/locationLayout/layout'
import useNavigateTo from '../../hooks/useNavigateTo'
import { ReactSVG } from 'react-svg'

import useGetPublications from '../../hooks/useGetPublications'
import { Publication, VolumeSearch } from '../../../types'
import { searchLocations } from '../../../services/search/search'
import Collapse, { Panel } from '../../../components/shared/Collapse'
import Publications from '../../../components/shared/publications'
import ObjectWithDropdown from '../../../components/shared/object/objectWithIcon/Withdrpdown'
import ReactSvg from '../../../components/shared/ReactSvg'
import { storageUrl } from '../../../services/api/constants'
import Icon from '../../../components/icons/Icon'
import ResourceMenu from '../../../components/shared/menu/ResourceMenu'
import { useUIContext } from '../../context/UIContext'
import { useAppContext } from '../../../context/AppContext'
import useSearchLocations from '../../hooks/useSearchLocations'

export default function WorldLocationlayout() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { goTo } = useNavigateTo()
    const location = useLocation()

    const { displayMode, barActivatedFields} = useUIContext()
    const { domain } = useAppContext()

    const [searchParams] = useSearchParams()
    const { publications } = useGetPublications({id: domain?.id!, sponsored: true, groupBy: !!searchParams.get('grouped')})
    const { searchResults:recentlyDeployedClusters } = useSearchLocations({
      query:'*',
      filter: `&& cfs_type:=LocalClusters && roots:=${domain?.id}`,
      sort:'created_at:asc, title.en:asc',
      limit: 7
    })
  
   
    const handlePublicationSelection = (item: {document:Publication}) => {
        goTo(`/publications/${item.document.id}`, {state: {url:item.document.url, item: item.document}})
    }

  return (

   <LocationgGlobalLayout 
          id={domain?.id!}
          collection='Domains'
          loading={loading}
          selectedKey={'/'}
          disabled={{filter:false, new:false, display:false,query:false}}
          publicationBar={false}          
          
        
   >
     <div className="pl-[11px] pt-[14px] overflow-auto height-explorer" >
      
          <Collapse defaultActiveKey={['about', 'deployed', 'pubs']} className='h-full flex flex-col'>
          <>
          {/* {partners && partners.length > 0 && (
            partners.map((continent, i) => (

              <Panel header={<p className='text-groupe'>{continent.title}</p>} key={`${i}`}>
                <div className='flex flex-wrap pl-[11px] gap-12'>
                  {continent.children.map(part => (
                    <div key={part.id} className={`${part.id === params.partnerId ? ' ' :'bg-hover-transparent'} w-max  rounded cursor-pointer`} onClick={() => handleSelectPartner(part)}>
                      <PartnerWithWebsite active={params?.partnerId === part.id} partner={part} country={`-${part.volums?.[0]?.volum_name?.en}`}/>
                    </div>
                  ))}
                  

            
                  
                </div>

              </Panel>
            ))

          )} */}
       
          <Panel key={'about'} header={<p className='text-groupe'>About</p>}>
            <div className='pl-[10px]'>
            
              <div className="flex">
               <ObjectWithDropdown
                  title={domain?.title!}
                  overlay={<></>}
                  icon={<div className='relative w-full h-full '>
                      <ReactSvg src={`${storageUrl}${(domain as any)?.icon!.src}`} className='w-full h-full'
                        
                      />
                      
                    
                  </div>}
                  id={''}
                  description={<p className='truncate'>{domain?.description ? (domain as any).description :'Lorem ipsum dolor sit amet consectetur, adipisicing elit'}</p>
                    
                
                      
                  }
                  active={location.pathname.includes('stats')}
                  onSelect={() => {}}
                />
                <ObjectWithDropdown
                  title={'Partners network'}
                  overlay={<></>}
                  icon={<div className='relative w-full h-full '>
                      <Icon name={'Document'} className='w-full h-full'
                        
                      />
                      
                    
                  </div>}
                  id={''}
                  description={<p className='truncate'>All {domain?.title} partners</p>
                    
                
                      
                  }
                  active={location.pathname.includes('partners')}
                  onSelect={() => goTo('/partners', {})}
                />

              </div>
            </div>
          </Panel>
          <Panel header={<p className='text-groupe'>Recently deployed</p>} key={'deployed'}>
          <div className='flex flex-wrap pl-[10px]'>
              {recentlyDeployedClusters && recentlyDeployedClusters.map(cluster => (
                  <ObjectWithDropdown
                          title={cluster.document.title.en}
                          overlay={<ResourceMenu 
                            id={cluster.document.id}
                            onSuccess={() => {}}
                            type='locations'
                            items={{
                              shortcut:true
                            }}
                          />}
                          icon={<div className='relative w-full h-full '>
                              <ReactSVG src={`${storageUrl}${cluster.document.iconUrl}`} className='w-full h-full'
                                beforeInjection={(svg) => {
                                  svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
                                }}
                              />
                          
                            
                          </div>}
                          key={cluster.document.id}
                          id={cluster.document.id!}
                          description={<p className='truncate'>{cluster.document.breadcrumbs?.[0]?.en!}</p>
                            
                        
                              
                          }
                          active={cluster.document.id === params.volumeId}
                          onSelect={() => {
                            console.log(cluster.document)
                            goTo(`/volumes/${cluster.document.id}`,{ state: cluster.document})
                          }}
                      /> 

              ))}

          </div>
          </Panel>
          {publications && publications?.length > 0 && (
          <Panel header={<p className='text-groupe'>Paid publications</p>} key={'pubs'}>
            <Publications barActivatedFields={barActivatedFields} publications={publications} handlePublicationSelection={handlePublicationSelection} displayMode={displayMode} setRecheckPin={() => {}}/>
          </Panel>

          )}
          
          </>
      </Collapse>
      

      </div>
 </LocationgGlobalLayout>
  )
}
