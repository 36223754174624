import React from 'react'
import Icon from '../../components/icons/Icon'

type Props = {
    title:string,
    description:string,
    direction: 'up' | 'down'
}
export default function Arrows({ title, description, direction } : Props) {
  return (
    <div className='flex space-x-1'>
        <div className='w-[70px] h-[70px]'>
            <Icon className={`w-full h-full ${direction === 'up' ? 'text-green-500 rotate-[80deg]' : 'text-red-500 rotate-[180deg]'}`} name='Cursor'/>
        </div>
        <div className='font-light'>
          <p className="text-[22px] font-bold leading-[22px]">{title}</p>
            <p>{description}</p>
        </div>
    </div>
  )
}
