import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Server } from "../../types";


type States = {
  currentLocation: Server|null,

}
type Functions = {
  setCurrentLocation: (location:Server|null) => void,

}
type Props = States & Functions
const StatsContext = createContext<Props>({} as Props);

export const useStatsContext = () => useContext(StatsContext);

export const StatsContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    currentLocation:null
   
  })
  

  const value = useMemo(() => {
    

   
  

    const setCurrentLocation = (location:Server|null)=> {
      setState((prev) => ({
        ...prev,
        currentLocation:location,
       
      }))
    }
   
    return {
      ...state,
      setCurrentLocation
    }
  }, [state])

  return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>;
};