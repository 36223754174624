import React, { useEffect, useState } from 'react'
import StepsButtons from '../../../components/stepsButtons';

import { Form } from 'antd';
import { Organization, ProData } from '../../../../types';
import IndividualForm from '../../../components/form/Individual';
import OrgForm from '../../../components/form/OrgForm';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';


export default function Pro() {
  const [form] = Form.useForm()
  const { clientData, profile, setCurrentStep , setclientData} = useSignupContext()
  const navigate = useNavigate()

  useEffect(() => {
    
    setCurrentStep(5)
  }, []);
  const nextStep = () => {
    navigate('/connect/signup/steps/6')
  }
  const prevStep = () => {
    navigate('/connect/signup/steps/4')
  }

  const submit = (values:any) => {
    const { city, country, thematics, interests} = values
    const submittedData:ProData= {
  
      adminCountry: country,
      adminCity: city,
      adminThematics: [thematics],
      adminIntrests: interests ? interests : [],
    }
    setclientData(submittedData)
    nextStep()

      
  }
  
  return (
    <div className='flex-1 h-full flex flex-col space-y-2'>
       <div className="flex-1 rounded p-4 flex-flex-col">
            <div>
                <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">{profile.name}: Create your account
                </p>
                
            </div>
            <div className="flex-1 flex items-center h-full">
                <Form 
                form={form} 
                initialValues={
                  clientData ? {
                  
                    country: clientData.adminCountry,
                    city: clientData.adminCity,
                    thematics: clientData.adminThematics ? clientData.adminThematics[0] : '',
                    adminIntrests: clientData.adminIntrests
                } : undefined} 
                className='space-y-10' 
                onFinish={submit}
                >
                  <div className="flex-1 flex">
                    <div className="w-[223px]">
                    <div className=" ml-[41px]">
                        <p className="font-bold text-[16px]">You:</p>
                        <p>
                          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque atque tempore animi at soluta corporis.
                        </p>
                      </div>
                    </div>
                    <IndividualForm/>
                  
                    <div className="w-[170px]"></div>

                  </div>
               

                </Form>


            </div>

            </div>
        <StepsButtons
            prevStep={prevStep}
            // disable={!data?.name || !data.raisonSocial || !data.duns || !data.activity || !data.activityCode || !data.webResources?.website || !data.address || !data.address.city || !data.address.country || !data.address.zipcode || !data.address.line1 || !data.email}
            disable={false}
            nextStep={() => form.submit()}
            // submit
        />
    
    </div>
  )
}
