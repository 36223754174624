import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.99 21.99" {...props}>
    <defs>
      <style>
        {
          ".editpub-3{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:1.7px}"
        }
      </style>
    </defs>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          d="M13.59 1.27v3a.9.9 0 0 0 .9.91h3.07"
          style={{
            fillRule: "evenodd",
          }}
        />
        <path d="M4.74 10.3h6.65M4.74 13.01h4.77" className="editpub-3" />
        <path
          d="M17.56 10.11V5.6a1.36 1.36 0 0 0-.39-1l-3-3a1.31 1.31 0 0 0-.95-.4H4.43a1.63 1.63 0 0 0-1.64 1.71V18a1.34 1.34 0 0 0 1.35 1.35h4.68"
          style={{
            strokeLinecap: "round",
            fill: "none",
            stroke: "currentColor",
            strokeMiterlimit: 10,
            strokeWidth: "1.7px",
            fillRule: "evenodd",
          }}
        />
        <path
          d="M19.16 14.46A1.6 1.6 0 0 0 18 13.19a1.68 1.68 0 0 0-1.77.46c-1.26 1.26-2.5 2.54-3.79 3.76a2.37 2.37 0 0 0-.82 1.86v1.09c0 .31 0 .3.26.29h1.89a.46.46 0 0 0 .35-.14c1.54-1.55 3.1-3.09 4.63-4.64a1.49 1.49 0 0 0 .41-1.41Z"
          className="editpub-3"
        />
      </g>
    </g>
  </svg>
)
export default SvgComponent
