import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import LocationLayout from '../../components/locationLayout'
import useGetPublications from '../../hooks/useGetPublications'
import { useSearchContext } from '../../context/searchContext'
import { useUIContext } from '../../context/UIContext'

export default function Group({suffix}: {suffix:string}) {
    const {  groupBy, setSelectedPubType, selectedPubType } = useSearchContext()
    const params = useParams()
    const { disabled } = useUIContext()
    const [group, setgroup] = useState<string | undefined>(undefined);
    const { publications, isLoaded } = useGetPublications({id: params.id ? params.id : params.serverId!!, groupBy: false})
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setloading] = useState(false);

    
    useEffect(() => {
        console.log('searchpa',searchParams.get('grouped') )
        if(searchParams.get('grouped')){
            searchParams.set('grouped', '')
            setSearchParams(searchParams)

        }
        if(params.group){
            setSelectedPubType({
                type: 'groupe',
                value: params.group,
                icon: '',
                title:''
            })
            const groupName = params.group.split('_').join(' ')
            setgroup(groupName)
        }
    }, [params.group]);
    useEffect(() => {
        setloading(true)
        if(!searchParams.get('grouped') && selectedPubType.value){
            setloading(false)
        }
    }, [searchParams.toString(), selectedPubType.value]);
 



    
  return (
    <LocationLayout
      partners={null}
      sponsors={null}
      publications={publications}
      webResources={undefined}
      groupBy={groupBy}
      availableLocations={null}
      suffix={`${!params.serverId ? '' : `${params.serverId}/`}${suffix === ':coll' ? params.coll : suffix}/${params.id ? params.id : params.serverId!}/group/${params.group}/`}
      layoutProps={{
        selectedKey: params.id!,
        loading:(!isLoaded || loading),
        publicationBar:true,
        disabled:{...disabled, new:false},
        collection:suffix === ':coll' ? params.coll! : suffix,
        id:params.id!
      }}
      all={group}
      isLoaded={(isLoaded && !loading)}


    
    />
  )
}
