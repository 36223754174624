import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'


export default function useNavigateTo() {
    const navigate  = useNavigate()
    const params = useParams()

    const goTo = (to:string,props:any) => navigate(`/mydesk${to}`, props )
  return {
    goTo
  }
}
