import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'

import useGetPublications from '../../hooks/useGetPublications'
import useGetPartners from '../../hooks/useGetPartners'
import LocationLayout from '../../components/locationLayout'
import useGetWebResources from '../../hooks/useGetWebResources'
import { useSearchContext } from '../../context/searchContext'
import useSearchLocations from '../../hooks/useSearchLocations'

export default function ServerLocationLayout() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { groupBy, selectedPubType } = useSearchContext()
    const [searchParams] = useSearchParams();
    
    const { webresources, isLoaded:isLoaded2 } = useGetWebResources({id:params.id!, groupBy:true})
    const { searchResults } = useSearchLocations({query: '*', filter: `&& roots:=${params.id} && id:!=${params.id}`, sort: 'created_at:desc', limit: 10})
    
    const { partners, sponsors} = useGetPartners({id:params.id!, suffix:'', coll:'LocalClusters', setloading:setloading})
    const { publications, isLoaded } = useGetPublications({id:params.id!, limit: 11, groupBy: !!searchParams.get('grouped'), extraFilter: (partners?.[0] && !selectedPubType.value) ? `&& roots:!=${partners[0].id}` : ''})
    const { publications:partnerPublications, isLoaded:isLoaded3 } = useGetPublications({id:partners?.[0]?.id!, limit: 7, groupBy: false, partner:true})

    
  return (
    <>
    <LocationLayout
         layoutProps={
           {
             loading,
             selectedKey: params.id!,
             disabled:{filter:false, new:false, display:false,query:false},
             publicationBar:true,
             collection: 'LocalClusters',
             id: params.id!
           }
           
         }
         groupBy={groupBy}
         allowSearch={true}
         publications={publications}
         sponsors={sponsors}
         partners={partners}
         availableLocations={searchResults}
         suffix={`LocalClusters/${params.id}/`}
         home
         webResources={webresources}
         isLoaded={isLoaded && isLoaded2 && isLoaded3}
         partnerPublications={partnerPublications}
         partnerId={partners?.[0]?.id}
         
         
    />
    </>
  )
}
