import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Spin, TreeSelect } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { Server } from '../../../../types'
import { getGeographicTree, getTree } from '../../../../services/api/request'


export const mapTreeIcon = (tree:any[]) => {
    return tree.map(item => {
        item.icon = <ReactSVG src={item.iconUrl} className='icon-sm' />
        item.value = item.title
        item.key = item.title

        if(item.children) mapTreeIcon(item.children)
        return item
    })
}
type Props = {
    domainId:string,
    selectedVolume: {value:string | undefined, id:string},
    setselectedVolume: (id:string, value:string|undefined) => void

}
export default function GeoTree({ domainId, selectedVolume, setselectedVolume } : Props) {
    const [volumes, setvolumes] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
    const [searchValue, setsearchValue] = useState('');
    useEffect(() => {
        setloading(true)
        fetch(getTree({id:domainId, geo:true})).then(res => res.json()).then(data => setvolumes(data.data)).catch(err => console.log(err)).finally(() => setloading(false))
    }, []);
  return (
    <Spin spinning={loading}>
        <TreeSelect
            treeData={volumes ? mapTreeIcon(volumes) : []} 
            value={selectedVolume.value??undefined}
            className='max-w-[100px] w-full input-h rounded border border-main'
            switcherIcon={(props : any) =>
                props.expanded ? (
                    <ChevronDownIcon className='w-3.5 h-3.5' />
                ) : (
                    <ChevronRightIcon className='w-3.5 h-3.5' />
                )
            }
            dropdownMatchSelectWidth={400}
            suffixIcon={<ChevronDownIcon className='w-3.5 h-3.5'/>}
            treeNodeFilterProp='title'
            allowClear
            placeholder='World'
            
            onSelect={(value,node) => {
                setselectedVolume(node.id, node.title)
                setsearchValue('')
            }}
            onClear={() => {
                setselectedVolume('', undefined)
                setsearchValue('')
            }}
            showSearch
            listHeight={500}
            // onClear={() => setCountry(null)}
            searchValue={searchValue}
            treeIcon
            dropdownRender={(node) => (
                <div className='mx-2 space-y-3 flex flex-col min-h-[500px]'>
                    <div className="border-b border-main">
                        <input autoFocus type="text" className='bar-h w-full !border-none' placeholder='Search' value={searchValue} onChange={e => setsearchValue(e.target.value)} />

                    </div>
                    <div className="flex-1">
                        {node}

                    </div>
                </div>
            )}
        />

    </Spin>
  )
}
