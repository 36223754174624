import React from 'react'

import { TopbarTitleProps } from '../types'
import ReactSvg from '../../../ReactSvg'
import Icon from '../../../../icons/Icon'


export default function TopBarTitle({icon, title, generic, maxWidth, NonewWin}: TopbarTitleProps) {
  
  return (
    <div className={`flex items-center h-full  space-x-3 `}>
        <div className={`relative icon-md`}>
        {icon?.type === 'image' && <ReactSvg src={icon.src} className={`w-full h-full rounded-full object-cover `} /> }
        {icon?.type === 'logo' && <img src={icon.src} className={`w-full h-full rounded-full object-cover `} /> }
        {icon?.type === 'icon' && <Icon name={icon?.src} className='w-full h-full' /> }

        </div>      
        <p className={` truncate max-w-[200px]  ${maxWidth && maxWidth}`}>{title}</p>
    </div>
  )
}
