import React from 'react'
import Icon from '../../../components/icons/Icon'
import ReactSvg from '../../../components/shared/ReactSvg'
import useGetPubsTypes from '../../../hooks/useGetPubsTypes'
import { PublicationTypes } from '../../../types'
import { storageUrl } from '../../../services/api/constants'
import ObjectMd from '../ObjectMd'
import ObjectSm from '../objectSm'
import Arrows from '../Arrows'

export default function PublicationsContents() {

    const { loading, publicationsTypes } = useGetPubsTypes()
  return (
    <div className='space-y-12'>
        <div className="flex space-x-16">
          
            <ObjectMd
                icon={{type:'icon', src:'Document'}}
                title={'35K'}
                description={'Publications'}
            
            />
              <Arrows
                title='25%'
                description='Growth rate'
                direction='up'
            />

        </div>
        <div className="grid w-max gap-6 grid-cols-4 pl-2">
            {publicationsTypes?.[0]?.children?.map((type:PublicationTypes) => (
                <ObjectSm
                    key={type.id}
                    icon={{type:'image', src: `${storageUrl}${type.icon.src}`}}
                    title={type.name_plural.en}
                    description='45'
                
                />
            ))}
           
         
        </div>
    </div>
  )
}
