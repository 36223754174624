import { Form, Input, message, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import SelectCountry from '../SelectCountry'
import SelectCity from '../SelectCity'
import { v4 as uuid } from 'uuid'
import UploadFormImage from '../../../components/shared/upload/UploadImage'


export default function OrgForm() {
    const [selectedCountry, setselectedCountry] = useState<undefined | string>(undefined);
    const [form] = Form.useForm()

  return (
    <div className="space-y-8">
        <div className="space-y-2">
        <p className="font-bold">Identity</p>
        <div className="flex space-x-4">
            <div className='w-[250px] space-y-2'>
            <Form.Item
                name={'organizationName'}
                rules={[{required:true}]}
            >
                
                <Input placeholder='Name' className='rounded border border-gray-500'/>

            </Form.Item>

            <div className="flex space-x-1">
                <Form.Item
                    name={'organizationActivity'}
                    rules={[{required:true}]}
                >
                    <Input placeholder='activity' className='rounded border border-gray-500' />
                </Form.Item>
                <Form.Item
                    name={'organizationActivityCode'}
                    rules={[{required:true}]}
                >
                    <Input placeholder='activityCode' className='rounded border border-gray-500' type='number'/>
                </Form.Item>

            </div>
            <div className='flex space-x-1'>
                <Form.Item
                    name={'organizationRaisonSocial'}
                    rules={[{required:true}]}
                >
                    <Input placeholder='Raison sociale' className='rounded border border-gray-500'/>
                </Form.Item>
                <Form.Item
                name={'organizationDuns'}
                rules={[{required:true}]}
                >
                    <Input placeholder='duns' className='rounded border border-gray-500' type='number'/>
                </Form.Item>

            </div>
            <Form.Item
                name={'organizationWebsite'}
                rules={[{ required: true }, { type: 'url'}, { type: 'string', min: 6 }]}
            >
                <Input placeholder='website' className='rounded border border-gray-500' type='url' />
            </Form.Item>
            <Form.Item 
                name={'organizationLinkedin'}
                rules={[{ required: true }, { type: 'url'}, { type: 'string', min: 6 }]}
            >
                <Input placeholder='linkedin' type='url' className='rounded border border-gray-500'/>
            </Form.Item>

            </div>
            <div className="w-[220px] h-[220px] border border-gray-500 rounded">
                <UploadFormImage
                
                    name='organizationLogo'
                    uploadText='Upload your logo'
                    maxSize={3}
                    storageUrl={`organizations/${uuid()}`}
                    reset={false}
                    // rules={[{required:true, message: 'Please upload a logo'}]}
                    listType="picture-card"
                    uploadUrl="uploads"
                />

            </div> 
      
         

        </div>
        
        </div>
        <div className="flex space-x-4">
        <div className='space-y-2 w-[250px]'>
            <p className="font-bold">Adress</p>
            <Form.Item
                name={'organizationAddressLine1'}
                rules={[{ required: true }, { type: 'string', min: 6 }]}
            >
                <Input placeholder='line1' className='rounded border border-gray-500'/>
            </Form.Item>
            <Form.Item
                name={'organizationAddressLine2'}
            >
                <Input placeholder='line2' className='rounded border border-gray-500'/>
            </Form.Item>
            <div className="flex space-x-1">
                <Form.Item
                    name={'organizationCountry'}
                    rules={[{required:true, message: 'Please select a country'}]}
                    className='w-1/2'
                >
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.organizationCountry !== curValues.organizationCountry}

                    >
                        {({ getFieldValue, setFieldsValue }) => (
            
                            <SelectCountry
                                selectedCountry={{id: selectedCountry, value: getFieldValue('organizationCountry')}}
                                setselectedCountry={(id:string, value:string | null) => {
                                setselectedCountry(id)
                            
                                setFieldsValue({ organizationCountry: value, organizationCity: undefined})

                                }}
                            />
                        )}

                    </Form.Item>

                </Form.Item>
                <Form.Item
                    name={'organizationCity'}
                    rules={[{required:true, message: 'Please select a city'}]}
                    className='w-1/2 '
                >

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.organizationCity !== curValues.organizationCity}
                    >
                        {({ getFieldValue, setFieldsValue }) => (
                            
                        
                            <SelectCity
                                selectedCountry={selectedCountry}
                                selectedRegion={getFieldValue('organizationCity')}
                                setselectedRegion={(value:string | null) => {
                                    setFieldsValue({ organizationCity: value})
                                }}
                            />
                            )}

                    </Form.Item>

                </Form.Item>
            

            </div>
            <Form.Item
                name={'organizationZipCode'}
                rules={[{ required: true }]}
            >
                <Input placeholder='postalCode' className='rounded border border-gray-500' type='number'/>
            </Form.Item>
        
        </div>

        <div className="space-y-2 w-[250px] ">
            <p className="font-bold">Contact info</p>
            <Form.Item
                name={'organizationEmail'}
                rules={[{ required: true }, { type: 'email'}]}
            >
                <Input placeholder='email' className='rounded border border-gray-500' type='email'/>
            </Form.Item>
            <Form.Item
                name={'organizationPhone'}
                rules={[{ required: true }]}
            >
                <Input placeholder='phone' className='rounded border border-gray-500' type='number'/>
            </Form.Item>
        </div>

        </div>
    </div>

  )
}
