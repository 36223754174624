import React, { useEffect, useState } from 'react'

import { searchWebResources } from '../../services/search/search'
import { Highlight, Publication } from '../../types'
import { useSearchContext } from '../context/searchContext'

export default function useGetWebResources({id, websiteId, groupBy, mode}:{id:string, websiteId?:string, groupBy:boolean, mode?:'roots' |'volume'}) {
    const [webresources, setwebresources] =  useState<undefined | {document:Publication, highlight:Highlight}[]>(undefined)
    const { query, setTotalHits, pagination, setGroupBy, selectedPubType, setPaginate } = useSearchContext()
    const [newRequest, setnewRequest] = useState(0)
    const [isLoaded, setisLoaded] = useState(true);
    useEffect(() => {
        (async()=>{ 

            if(id && !selectedPubType.value ){
                let limit =7
                if(groupBy && !query){
                  limit =2
                }
                if(!groupBy){
                  limit = pagination.limit
                }
                const res:any = await searchWebResources({q:query, volumeId:id, groupBy:groupBy ? 'main_website': undefined, websiteId, groupLimit:5, limit, offset: pagination.offset, page: pagination.page , mode})
                if(res.grouped_hits){
                    setGroupBy({webresources:true})
                    setwebresources(res?.grouped_hits)
                    setTotalHits({webresources: res.found_docs})
                }else{
                    setGroupBy({webresources:false})
                    setwebresources(res?.hits)
                    setTotalHits({webresources: res.found})
                }
                
                if(!groupBy){
                    setTotalHits({pubs:null})
                }

            }
        })()
      }, [query, id, pagination.offset, pagination.page, newRequest, selectedPubType.value, mode])
      useEffect(() => {
        setisLoaded(false)
        setTimeout(() => {
            setnewRequest(prev => prev+1)
            setisLoaded(true)
        }, 200);
      }, [pagination.limit, groupBy, websiteId])
      useEffect(() => {
        
        setPaginate({page: 1, offset:0})
      }, [groupBy]);
  return {
    webresources,
    isLoaded
  }
}
