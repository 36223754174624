import { getAuth, sendEmailVerification } from 'firebase/auth'
import React, { useState } from 'react'

import { notification, Spin } from 'antd'
import Icon from './icons/Icon';
import { useAuthContext } from '../context/AuthContext';


export default function ValidateEmail() {
    const [message, setmessage] = useState({title: '', text: '', showMessage: false, icon:''});
    const [loading, setloading] = useState(false);
    const { user } = useAuthContext()
    const resendEmail = () => {
        const auth = getAuth().currentUser
        setloading(true)
        if(auth){
            sendEmailVerification(auth).then(() => {
                setmessage({title: 'Verify Email', text: 'check your email to activate your account', showMessage: true, icon:'EmailSent'})
            }).catch(() => {
                notification.open({
                    message: `Something went very wrong`,
                    type:'error'
                  })
            })

        }
        setloading(false)
    }
    const onClose = () => {
        setmessage({title: '', text: '', showMessage: false, icon:''})
    }
  return (
    <div className="flex items-center justify-center flex-col h-full space-y-6">
    <div className="text-center">
        <p className="text-[22px]">Please verify your email</p>
        <p>In order to access to this module you need to activate your account first</p>
    </div>
    <Icon name={'Warning'} className=" w-[100px] h-[100px] fill-transparent"/>
    <div className="space-x-2 flex" >
        <Spin spinning={loading}>
            <button className="bg-skin-fill-inverted text-skin-inverted px-4 py-1.5 rounded block mx-auto " onClick={resendEmail} disabled={loading}>Resend verification email to {user?.email}</button>
        </Spin>
    </div>
    {/* <NotificationModal visible={message.showMessage} onClose={onClose} text={message.text} icon={message.icon} title={message.title} action={{title: 'Resend email', handler: () => resendEmail()}}/> */}

</div>
  )
}
