import React from 'react'
import WithDivider from '../../../../../components/shared/WithDivider'
import { Server } from '../../../../../types'
import Network from './Network'
import { heightWindow } from '..'

type Props = {
    nodes: Server[] | undefined,
    selectedNode:Server | null,
    setselectedNode:any
}
export default function Nodes({nodes, selectedNode, setselectedNode}:Props) {
  return (
    <div className="space-y-2">
        <WithDivider>
            <>
            <p className='text-small'>Nodes</p>
            </>
        </WithDivider>
        <div className={`${heightWindow} overflow-auto pb-5`}>
            {nodes?.map((node,i) => (
                <Network withDropdown={false} key={node.id} network={{server:node, color: '#fff', textColor: '#000', border:true}} active={node.id === selectedNode?.id} onSelect={(node:Server) => {setselectedNode(node)}} setRevalidate={() => {}} />
            // <Network withDropdown key={domain.id} network={{server:domain, color: 'rgb(162 205 249)', textColor: '#fff'}} active={domain.id === selectedNetworkId} onSelect={onSelect} setRevalidate={setRevalidate} />
            

            ))}
            
        </div>
    </div>
  )
}
