import * as React from "react"
import { IconOptions } from "../../../types"
const HideTree = (props: IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
   
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 3320 3320"
    {...props}
  >
    <path
      d="M3320 3320H0V0h3320z"
      style={{
        fill: "none",
        fillRule: "nonzero",
      }}
    />
    <path
      d="m2170 1110-250-260h640V580h-640l250-260-200-190-580 580 580 590 200-190zm20 410h-800v1530h1660V1800h-790l-70-280zm580 1250H1660v-970h310l70 280h730v690zM340 420c-80 0-140 60-140 130 0 80 60 140 140 140s140-60 140-140c0-70-60-130-140-130zm0 550c-80 0-140 60-140 140 0 70 60 140 140 140s140-70 140-140c0-80-60-140-140-140zm0 550c-80 0-140 70-140 140 0 80 60 140 140 140s140-60 140-140c0-70-60-140-140-140zm0 560c-80 0-140 60-140 140 0 70 60 130 140 130s140-60 140-130c0-80-60-140-140-140zm0 550c-80 0-140 60-140 140s60 140 140 140 140-60 140-140-60-140-140-140zm550 0c-70 0-130 60-130 140s60 140 130 140c80 0 140-60 140-140s-60-140-140-140zm0-1660c-70 0-130 60-130 140 0 70 60 140 130 140 80 0 140-70 140-140 0-80-60-140-140-140z"
      style={{
        fillRule: "nonzero",
      }}
    />
  </svg>
)
export default HideTree
