import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { iconType, PublicationTypes, Server } from '../../../../types'
import { storageUrl } from '../../../../services/api/constants'
import { getClusterTree } from '../../../../services/api/request'
import OneLine from '../../../../components/shared/object/oneLine'
import ResourceMenu from '../../../../components/shared/menu/ResourceMenu'
import Icon from '../../../../components/icons/Icon'
import useFetchVolum from '../../../../hooks/useFetchVolum'
import TreeLayout from './Layout'
import useSearchLocations from '../../../hooks/useSearchLocations'



type Props = {
    handleLocationSelection: (node:Server) => void, 
    selectedKey:string,
    containerRef:any,
    openAction : ({title,id, icon,key}:{title:string, icon:iconType,id: string, key:string}) => void,
    serverId: string | undefined,
    datacenter: Server | null, 
    settree: (data:any) => void,
    tree: any,
    setResultsLength:any
}
 


export default function TreeComponent({handleLocationSelection, selectedKey,containerRef, openAction, datacenter, serverId, tree, settree, setResultsLength}: Props) {

    const [searchbyregion, setsearchbyregion] = useState<null | any>(null)
    const { volume:server } = useFetchVolum(serverId!, 'LocalClusters')
    const [loading, setloading] = useState(false)
    const {resultsLength} = useSearchLocations({
      query: '*', 
      filter: `&& roots:=${serverId}`,
      limit:1
    })

    useEffect(() => {
      
      setResultsLength(resultsLength)
    }, [resultsLength]);


    

  
   
    useEffect(() => {
      (async () => {
        if(!serverId) return
        setloading(true)
        fetch(getClusterTree({id:serverId})).then(res => res.json()).then(data => {
          
            setsearchbyregion(
              data.data
            )
            console.log(data.data)
        }).finally(() => setloading(false))
      })()
    }, [serverId])

    useEffect(() => {
      settree( 
        [{
          ...server,
          key:server?.id,
          id: server?.id,
          title:server?.title,
          iconType:'icon',
          iconUrl:'Datacenter',
          path:`/LocalClusters/${server?.id}`,
          children: searchbyregion  
          // children: [
          //   {
          //     key:'/recent',
          //     title:'Recently deployed',
          //     iconType:'icon',
          //     depth:0,
          //     id:'/recent',
          //     status:'enabled',
          //     iconUrl: 'Server',
          //     cfs_type:'',
          //     path:'/recent',
              
          //   },
          
          //   ...!noType ? [
              
          //     {
          //       title: 'Search by type', 
          //       key:  `/types`,
          //       id:`/types`,
          //       path:`/types`, 
          //       iconUrl: 'Server',
          //       iconType:'icon',
          //       cfs_type:'',
          //       depth:0,
          //       status:'enabled',
          //       children: searchBytype?searchBytype.sort((a,b) => {
          //         if(a.title < b.title){
          //             return -1
          //         }
          //         return 1
                  
          //     }):[]
          //     },
          //   ]: [],
          //   ...searchbyregion? [searchbyregion] : []
          // ]
        },
       ]
      )
      console.log('regenerate tree')
    }, [server?.id, searchbyregion])
    

  return (
         
          <Spin spinning={loading}>
            {server && datacenter && (
                <TreeLayout
                  tree={tree}
                  openAction={openAction}
                  handleLocationSelection={handleLocationSelection}
                  selectedKey={selectedKey}
                  containerRef={containerRef}
                />

            )}
          </Spin>
        

      
  )
}
