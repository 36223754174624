import { useLocation, useNavigate } from 'react-router-dom'
import { usePublishContext } from '../context/publishContext'
import useNavigateTo from '../../hooks/useNvigateTo'


export default function Header() {
    const {goTo} = useNavigateTo()
    const { format, active, InitId, type } = usePublishContext()
    const location = useLocation()
    const handleNavigation = (page: string) => {
      goTo(`/publish/${page}`, {state: location.state})
    }
    const isActive = (page:string) => { 
      return location?.pathname?.split('/')?.includes(page)
    }
    return (
      <div className="border-b border-main bg-white">
        <div className='bar-h flex items-center space-x-9 pl-[43px]'>
            <button disabled={!type} className={`${isActive('edit') && 'active-mode'} disabled:text-skin-muted`} onClick={() => handleNavigation(`edit/${format}`)}>Edit</button>
            <button disabled={true} className={`${isActive('translate') && 'active-mode'} disabled:text-skin-muted`} onClick={() => handleNavigation('translate')}>Translate</button>
            <button disabled={true} className={`${isActive('view') && 'active-mode'} disabled:text-skin-muted`} onClick={() => handleNavigation('view')}>View</button>

            <button disabled={!active.publish || !!InitId || !type} className={`${isActive('publishin') && 'active-mode'} disabled:text-skin-muted`} onClick={() => handleNavigation('publishin')}>Publish</button>

        </div>

      </div>

  )
}
