import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { ButtonLg } from "../Buttons/ButtonLg"
import { Breadcrumb } from "../bars/TopBar/types"
import { useState } from "react"



export const BreadcrumbComp = (props:Breadcrumb) => {
    const navigate = useNavigate()
    const [open, setopen] = useState(false);
    return (
        <div className={`w-full input-h rounded border border-main flex justify-between bg-sub-windows `}>
            <div className="flex space-x-0.5 items-center flex-1  rounded  py-0.5">
                {props.routes.map((route,i) => (
                    <>
                    {!route.action ? (
                        <>
                            <ButtonLg
                                key={i}
                                buttonProps={{
                                    onClick:() => (route.path && !route.disabled) ? navigate(route.path) : {},
                                    disabled: props.disabled
                                 
                                }}
                                prefix={route.icon && {
                                    icon: route.icon,
                                    size:'sm'
                                }}
                                
                                
                                
                            >
                                <p className={`${(i===props.routes.length -1) && 'font-bold'} ellipsis-1`}>{route.name}</p>
                            </ButtonLg>
                        
                            <ChevronRightIcon className="w-3.5 h-3.5"/>
                        
                        </>
                    ) : (
                        <>
                            <button className="flex space-x-0.5 items-center disabled:opacity-20" onClick={() => setopen(true)} disabled={(route.disabled || props.disabled)}>

                                <ButtonLg
                                    key={i}
                                    buttonProps={{
                                        onClick:() => route.path ? navigate(route.path) : {},
                                    }}
                                    prefix={route.icon && {
                                        icon: route.icon,
                                        size:'sm'
                                    }}
                                    
                                >
                                    <p className={`${(i===props.routes.length -1) && 'font-bold'} ellipsis-1`}>{route.name}</p>
                                </ButtonLg>
                            
                                <ChevronRightIcon className="w-3.5 h-3.5"/>
                            </button>
                            {route.action({open, setopen})}
                        </>

                    )}
                    
                    </>

                ))}
                
            </div>
        </div>
    )
}