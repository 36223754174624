import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../types";

type States = {
  user: null | User
}
type Functions = {
  setUser: (data:null | User) => void

}
type Props = States & Functions
const AuthContext = createContext<Props>({} as Props);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children } : { children : JSX.Element}) => {
  const params = useParams()
  const [state, setState] = useState<States>({
    user: null
   
  })
  

  const value = useMemo(() => {
    

    const setUser =(data:User | null) => {
      setState((prev) => ({
        ...prev,
        user:data
      }))
    }
    return {
      ...state,
      setUser

     
    }
  }, [state])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};