import * as React from "react"
import { IconOptions } from "../../../types"
const LinkIcon = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 710 710"
    {...props}
  >

    <g id="Plan_x0020_1">
      <path
        id="_2258022935632"
        d="M710 710H0V0h710z"
        style={{
          fill: "none",
          fillRule: "nonzero",
        }}
      />
      <path
        d="M300 360 160 220l-40 40 60 70H20v60h160l-60 70 40 40z"
        className="fil17"
      />
      <path
        d="M460 60H180c-30 0-60 30-60 60v70h60v-70h240v120h110v350H180v-60h-60v60c0 40 30 60 60 60h350c40 0 60-20 60-60V200L460 60z"
        className="fil17"
      />
    </g>
  </svg>
)
export default LinkIcon
