import * as React from "react"
import { IconOptions } from "../../../types"
const TeddraLogoTaskBar = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"

    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 1760 1590"
    {...props}
  >
    <path
      d="M1320 0c250 0 440 170 440 420 0 240-250 410-490 410-250 0-410-170-410-410 0-250 210-420 460-420zm270 150c-50 10-130 40-140 60-20 30-70 60-70 60s40 10 20 40c-30 20 40 50-20 40-70-10-30 50 0 60 30 20 80 0 80-20 10-20 30-90 140-30 30 20 10 30 0 40 0 30-70 40-100 30-50-10-140 80-50 150s130 0 110 100c-10 10 70-30 100-80 20-50 30-110 30-160 0-110-40-210-100-290zm-380-40s0 90 10 120 10 100-50 130c-50 20-50 40-20 60 20 20 20 60-10 60-20 0-60-40-70-40-20-20-70 50-20 80 50 20 130-20 140 80s10 130 10 160-80-10-140-40c-70-80-120-190-120-310 0-110 40-210 100-280l170-20zM530 590c-20-40 80-10 130-60l60-120c-40-160 70-160-20-230C450-40 290 90 220 280c-10 20 0 60 0 90 10 0-40 40-50 100-40 190 70 280 100 220 30-50-20-270-20-260 40 50 110 140 130 140 70 30 0 110 0 100L220 910 60 1100l90 60L0 1480l160 80 20-40-50-70 160-210 80 10-70 340h190v-40l-70-40 90-230 140-10-30-390 240-270c-30-40 0-20-40-50L670 680l-30 10h-50s-40-50-60-100z"
     
    />
  </svg>
)
export default TeddraLogoTaskBar
