import React, { useEffect, useState } from 'react'
import { searchDatacenters } from '../../../../services/search/search';
import { VolumeSearch } from '../../../../types';
import Domain from './Domain';
import {heightWindow} from './index'

type Props = {
  openDomain : (name:string) => void,
  value:string
}
export default function Search({value, openDomain}: Props) {
    const [domains, setdomains] = useState<{document:VolumeSearch}[]>([]);

    useEffect(() => {
        
       (async () => {
            const res:any = await searchDatacenters({q: value})
            setdomains(res.hits)
       })()
    }, [value]);
  return (
    <div className={`flex flex-wrap w-full overflow-hidden ${heightWindow}`}>
        {domains.map(domain => (
            <Domain
                key={domain.document.id}
                domain={domain.document}
                onSelect={(domain) => openDomain(domain.title.en)}
                active={false}
            />
        ))}
    </div>
  )
}
