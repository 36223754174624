import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Server } from '../../../../../types';
import ServerWithCheck from '../../../components/ServerWithCheck';
import { getClusterTree, getServerTree } from '../../../../../services/api/request';
import useNavigateTo from '../../../../hooks/useNvigateTo';

export default function Drives() {
    const [regionalDrives, setregionalDrives] = useState<Server[]>([]);
    const [specDrives, setspecDrives] = useState<Server[]>([]);

    const [loading, setloading] = useState(false);
    const params = useParams()
    const {goTo} = useNavigateTo()
    const location = useLocation()
    useEffect(() => {
            setloading(true)
          
            fetch(getClusterTree({id:params.serverId!})).then(res =>res.json()).then(data =>  {
                setregionalDrives(data.data?.[0]?.children)
                setspecDrives(data.data?.[1]?.children)
            }).finally(() => setloading(false))
           
        
      }, [params.serverId])
  return (

    <div className='grid grid-cols-3'>
        <div className='overflow-auto height-publishin pl-3'>
            <Spin spinning={loading}>
                <div className='space-y-7'>
                <div className="space-y-2">
                        <p className="text-group">Regional drives</p>
                        <div className='-ml-2'>
                            {regionalDrives && regionalDrives.filter(drive => !drive.media).map(drive => (
                                <ServerWithCheck item={drive} path={`/${location.state?.network}/${location.state?.server}/${drive.title}`} key={drive.id} active={drive.id === params.driveId} clickFn={() => goTo(`/publish/publishin/${params.networkId}/${params.serverId}/${drive.id}`, {state: {...location.state,drive:drive.title ,directories:drive.children}})}/>
                            ))}
                        </div>

                    </div>
                    {specDrives?.length > 0 && (
                        <div className="space-y-2">
                            <p className="text-group">Thematics drives</p>
                            <div className='-ml-2'>
                                {specDrives?.map(drive => (
                                    <ServerWithCheck path={`/${location.state?.network}/${location.state?.server}/${drive.title}`} item={drive} key={drive.id} active={drive.id === params.driveId} clickFn={() => goTo(`/publish/publishin/${params.networkId}/${params.serverId}/${drive.id}`, {state:{...location.state,drive:drive.title,directories:drive.children}})}/>
                                ))}
                            </div>

                        </div>

                    )}
                    
                </div>
       

            </Spin>
        </div>
        <div className="col-span-2">
            <Outlet/>
        </div>
    </div>
  )
}
