import React, { useRef, useState } from 'react'
import Header from './Header'
import TreeComponent from './tree/TreeComponent'
import TreeComponentWorld from './tree/TreeComponentWorld'
import { iconType, Server, VolumeSearch } from '../../../types'
import Search from './search'
import Icon from '../../../components/icons/Icon'
import StatsBar from './Stats'


type Props = {
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    handleSearchSelection : (doc:VolumeSearch) => void,
    openAction: ({title, icon,key, id}:{title:string, icon:iconType, key:string, id:string}) => void,
    datacenter: Server | null, 
    settree: (data:Server[] | null) => void,
    tree: Server[] | null,
    setserverTree: (data:Server[] | null) => void,
    serverTree: Server[] | null,
    serverId:string|undefined
}


export default function Sidebar({handleLocationSelection, selectedKey,handleSearchSelection, openAction, datacenter, settree, tree , serverId, serverTree, setserverTree}: Props) {

    const [mode, setmode] = useState<'search' | 'globalTree' | 'localTree'>('globalTree');
    const containerRef = useRef<any>()
    const [resultsLength, setresultsLength] = useState(0);
   

    
    
 
  
    
  return (
    <div className='modules h-full  '>
      <div className='mr-[9px]'>
        <Header mode={mode} setmode={setmode} serverId={serverId}/>

      </div>
        <div className="space-y-4 overflow-auto height-sidebar  border-r border-main h-full pb-layout mr-[9px]" ref={containerRef}>
         
          <div className="pl-[8px]">
            {mode === 'search' && (
              <div className='space-y-2'>
            
                {/* <SearchResults value={searchValue} selectedKey={selectedKey} handleSearchSelection={handleSearchSelection} results={searchResults}/> */}
                <Search
                  selectedKey={selectedKey}
                  domainId={datacenter?.id!}
                  setResultsLength={setresultsLength}
                  handleSearchSelection={handleSearchSelection}
                />
              </div>

            )
            }
            {mode === 'localTree' && (
                <TreeComponent
                handleLocationSelection={handleLocationSelection}
                selectedKey={selectedKey}
                containerRef={containerRef}
                openAction={openAction}
                serverId={serverId}
                datacenter={datacenter}
                settree={setserverTree}
                tree={serverTree}
                setResultsLength={setresultsLength}
              />
            )}
            {mode === 'globalTree' && (
              <TreeComponentWorld
              handleLocationSelection={handleLocationSelection}
              selectedKey={selectedKey}
              containerRef={containerRef}
              openAction={openAction}
              serverId={serverId}
              // server={server}
              datacenter={datacenter}
              settree={settree}
              tree={tree}
              setResultsLength={setresultsLength}
              // noType={noType}
            />
            )}
        

          </div>
        

        </div>
        <div className="mr-[9px]">
          <button className='bar-h border-t border-main py-2 px-5 flex items-center border-r space-x-3 w-full'>
              <Icon name='LocalTree' className='icon-md'/>
              <p>Create a new volume</p>
          </button>

        </div>
        <StatsBar volumes={resultsLength} />
        
      
    </div>
  )
}
