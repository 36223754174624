import { useEffect, useState } from "react";
import { getDomain } from "../services/api/request";
import { Server } from "../types";

const useFetchDomain = (
  
  
  ) => {
    const [hostname, sethostname] = useState('');
    const [domain, setdomain] = useState<null |Server>(null);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        
        sethostname(window.location.hostname)
    }, []);
    useEffect(() => {
      const fetchVolum = async () => {
        if (!hostname) {
          setdomain(null);
          return;
        }
  
        setloading(true);
        try {
          const response = await fetch(getDomain({ subdomain:hostname }));
          const data = await response.json();
          setdomain({ ...data.data, title: data.data.title.en, description: data.data.description?.en });
        } catch (error) {
        //   console.error(`Failed to fetch ${volumColl}:`, error);
          // Optionally set error state here
        } finally {
          setloading(false);
        }
      };
  
      fetchVolum();
    }, [hostname]);

    return {
      domain, loading
    }
  };

  export default useFetchDomain