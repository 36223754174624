import React, { useEffect, useState } from 'react'
import { Server, ServerWithHomeDesq } from '../../../../../types';
import { getAllDomains, getNodeDomains } from '../../../../../services/api/request';
import { Spin } from 'antd';
import WithDivider from '../../../../../components/shared/WithDivider';
import Network from './Network';
import { heightWindow } from '..';


type Props = {
    selectedUniver: Server | null,
    setselectedUniver: any
}
export default function Univers({ selectedUniver, setselectedUniver} : Props) {

    const [loading, setloading] = useState(false);
    const [univers, setunivers] = useState<ServerWithHomeDesq[] | null>(null);



  

    
    useEffect(() => {
        if(!univers){
            setloading(true)
            fetch(getAllDomains).then(res => res.json()).then(data => setunivers(data.data)).finally(() => setloading(false))
           
        }
    }, [])

 
      
  return (

        
            <Spin spinning={loading}>
          
    
                {univers && univers.length > 0 && (
                    <div className='space-y-2 '>
                        <WithDivider>
                        <>
                            <p className='text-small'>Univers</p>
                        </>
                        </WithDivider>
                        <div className={`${heightWindow} overflow-auto`}>
                        {univers.map((univer,i) => (
                            <>
                            {univer.children && univer.children.length > 0 && (
                            
                                
                            <Network withDropdown={false} key={univer.id} network={{server:univer, color: '#fff', textColor: '#000', border:true}} active={univer.id === selectedUniver?.id} onSelect={(node:Server) => {setselectedUniver(node)}} setRevalidate={() => {}} />
                        

                            

                            )}
                            
                            </>
                            ))}
                        
                        

                        </div>
                    </div>
                ) }

           

           

            </Spin>

   
  )
}
