import { Dropdown, Menu } from "antd";

import { useParams } from "react-router-dom";
import Collapse, { Panel } from "../../../../../components/shared/Collapse";
import Publications from "../../../../../components/shared/publications";
import { useUIContext } from "../../../../context/UIContext";

type Props ={
  publications:any,
  handlePublicationSelection: (item:any) => void,
  author:boolean,
  extraPanel:undefined | {element:JSX.Element, title:any},
  handleSelectGroupedPublication: (value:string) => void
}

export default function GroupedPublications({ publications, handlePublicationSelection, extraPanel, handleSelectGroupedPublication } : Props) {
    const { barActivatedFields, displayMode } = useUIContext()
  
    return (
        <div className="">
            <Collapse defaultActiveKey={['0','1','2','3']}>
            <>
            
                {publications && publications?.length > 0 && publications.map((group:any,index:number) => (
                <Panel 
                className='relative' 
                header={
                    <p className='text-groupe'>{(group.group_key?.[1] === 'Newspaper' ? 'News' :group.group_key?.[1] ) } ({group.found})</p>
                } 
                key={`${index}`}
                >
                    <div className="pl-[22px]">
                        <Publications barActivatedFields={barActivatedFields} setRecheckPin={() => {}} publications={group.hits} handlePublicationSelection={handlePublicationSelection} displayMode={displayMode}/>
                 
                        {group.found > group.hits?.length && (
                            <button className="bg-skin-fill-muted px-3 py-1.5 rounded mt-2" onClick={() => handleSelectGroupedPublication(group.group_key?.[0]) }>See all...</button>
                            )}

                    </div>
                </Panel>
                

                ))}
                {extraPanel && (
                    <Panel 
                        className='relative' 
                        header={
                            <p className='text-groupe'>{extraPanel.title}</p>
                        } 
                        key={`extra`}
                    >
                        <div className="pl-[22px]">
                            {extraPanel.element}

                        </div>
                    </Panel>
                )}
            </>
            
            </Collapse>

        </div>
           
         
    
  )
}