import React from 'react'
import ReactSvg from '../../../components/shared/ReactSvg'
import { Server } from '../../../types'
import Icon from '../../../components/icons/Icon'


type Props = {
    item: Server ,
    active: boolean,
    clickFn:() => void, 
    search?:boolean
}
export default function SingleServerLine({item, active, clickFn, search}: Props) {
  return (
    <div className={`flex space-x-3 cursor-pointer px-1.5 py-1 rounded items-center ${active ? 'bg-skin-fill-inverted text-skin-inverted fill-skin-inverted' : 'bg-hover-transparent'}`} onClick={clickFn}>
      {item.iconUrl ? (
        <ReactSvg src={`${item.iconUrl}`} className='icon-sm' />

      ) : (
        <Icon name='Document' className='icon-sm'/>
      )}
     
        <p className='truncate'>{item.title}</p>
    
    </div>
  )
}
