import React, { useEffect, useMemo } from 'react'
import { Highlight, OwnerType, PartnerType, Publication, VolumeSearch } from '../../../types'

import { useHandleLimits } from '../../hooks/useHandleLimits'
import PartnersAndSponsors from './Partners'
import Owner from './Owner'
import AvailableLocations from './AavilableLocations'
import PublicationsLink from './publications/PublicationsLink'
import WebResources from './publications/WebResources'
import { useUIContext } from '../../context/UIContext'
import { useSearchContext } from '../../context/searchContext'
import PartnerPublications from './publications/PartnerPublications'

type Props = {
    availableLocations: {document :VolumeSearch}[] | null,
    suffix: string,
    publications: undefined | {document:Publication, highlight:Highlight}[],
    webResources: undefined | {document:Publication, highlight:Highlight}[],
    partnerPublications?:{document:Publication, highlight:Highlight}[] ,
    isLoaded:boolean,
    sponsors: PartnerType[] | null
    partners : PartnerType[] | null,
    mainSites?:PartnerType[],
    allowSearch: boolean,
    groupBy?:{
      webresources:boolean,
      paidPubs: boolean
    },
    owner?:OwnerType | undefined,
    showApps?:boolean,
    all?:string,
    home?:boolean,
    partnerId?:string
}


export default function ResourcesLayout({partners, sponsors, availableLocations, suffix,webResources, publications, allowSearch, groupBy, owner, showApps, all, isLoaded, partnerPublications, partnerId} : Props) {
    const { displayMode, barActivatedFields, setAll} = useUIContext()
    const {query, selectedPubType, totalHits,  } = useSearchContext()
    useHandleLimits({displayMode, disabled: !all && !selectedPubType.value})
    useEffect(() => {
      if(all){
        
        setAll(all)
      }else{
       
        setAll('')
      }
    }, [all]);
   
    const isSearchActive =  !!query || !!selectedPubType.value
    const showPartnersAndSponsors = (!isSearchActive || !allowSearch) && ((partners && partners?.length>0) || (sponsors && sponsors?.length > 0))
    const showOwner = (!isSearchActive || !allowSearch) && owner?.website
    const showAvailableLocations = (!isSearchActive  || !allowSearch)&& (
      availableLocations && availableLocations.length > 0)
    const showPublications = isLoaded && allowSearch && publications && publications.length>0
    const showWebResources = isLoaded && webResources && webResources.length>0 && !selectedPubType.value
    const showPartnerPublications = isLoaded && allowSearch && partnerPublications && partnerPublications.length>0 && (!selectedPubType.value || all)
    return (
        <div className="pl-[24px] pt-[14px] overflow-auto height-explorer space-y-[60px]" >
             
            {showPartnersAndSponsors && (
              <PartnersAndSponsors
                partners={partners}
                sponsors={sponsors}
                suffix={suffix}
              />


            )}
    
            
            {showOwner && (
                <Owner
                  owner={owner}
                  suffix={suffix}
                />


            )}
            
            {showAvailableLocations && (
                <AvailableLocations
                  availableLocations={availableLocations}
                  setRecheckPin={() => {}}
                  suffix={suffix}
                />


              )
            }
            <div>
            {showPartnerPublications && (
          
                <PartnerPublications
                  publications={partnerPublications}
                  totalHits={totalHits}
                  searchon={isSearchActive}
                  suffix={suffix}
                  partnerId={partnerId}
                  setRecheckPin={() => {}}
                  displayMode={displayMode}
                  barActivatedFields={barActivatedFields}
                />


            )}
            {showPublications && (
          
                <PublicationsLink
                  publications={publications}
                  webResources={webResources}
                  groupBy={groupBy}
                  totalHits={totalHits}
                  searchon={isSearchActive}
                  selectedPubType={selectedPubType}
                  barActivatedFields={barActivatedFields}
                  setRecheckPin={() => {}}
                  displayMode={displayMode}
                  suffix={suffix}
                />
    
  
            )}
            {showWebResources && (
              <WebResources
                webResources={webResources}
                searchon={isSearchActive}
                barActivatedFields={barActivatedFields}
                setRecheckPin={() => {}}
                displayMode={displayMode}
                suffix={suffix}
                groupBy={groupBy}
                totalHits={totalHits}
              />

              )}
            
            </div>
          

      

          
  
        </div>


    )
}
