// src/components/PieChart.js
import { ArcElement, CategoryScale } from "chart.js";
import React, { useState } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
const data = {
    labels: ['Red', 'Orange', 'Blue'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
        {
          label: 'Popularity of colours',
          data: [55, 23, 96],
          // you can set indiviual colors for each bar
          backgroundColor: [
            'rgba(255, 255, 255, 0.6)',
            'rgba(255, 255, 255, 0.6)',
            'rgba(255, 255, 255, 0.6)'
          ],
          borderWidth: 1,
        }
    ]
}
export const Data = [
    {
      id: 1,
      country: 'Individual',
      visits: 80000,
      requests: 823
    },
    {
      id: 2,
      country: 'Entreprise',
      visits: 45677,
      requests: 345
    },
    {
      id: 3,
      country: 'Higher Education',
      visits: 78888,
      requests: 555
    },
    {
      id: 4,
      country: 'Community',
      visits: 90000,
      requests: 4555
    },
    {
      id: 5,
      country: 'Brands',
      visits: 4300,
      requests: 234
    }
  ];
  Chart.register(CategoryScale);
  Chart.register(ArcElement);
function PieChart() {
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.country), 
        datasets: [
          {
            label: "Subscriptions ",
            data: Data.map((data) => data.visits),
            backgroundColor: [
              "rgba(75,192,192,1)",
            //   &quot;#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0"
            ],
            borderColor: "black",
            borderWidth: 2
          }
        ]
      })
  return (
    <div className="chart-container max-w-[450px]">
      <Doughnut
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Subscriptions"
            }
          }
        }}
      />
    </div>
  );
}
export default PieChart;