import * as React from "react"
import { IconOptions } from "../../../types"
const AddNew = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 183500 183500"
    {...props}
  >
    <defs>
      <style>{".fil1{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <g id="_1675188893968">
        <path
          d="M183500 183500H0V0h183500z"
          style={{
            fill: "none",
            fillRule: "nonzero",
          }}
        />
        <path
          d="M22940 38230h38230V22940H22940zM22940 84100h38230V68810H22940zM22940 129980h38230v-15290H22940zM107040 30580c0 4230-3420 7650-7650 7650-4220 0-7640-3420-7640-7650 0-4220 3420-7640 7640-7640 4230 0 7650 3420 7650 7640zM129980 30580c0 4230-3420 7650-7650 7650-4220 0-7640-3420-7640-7650 0-4220 3420-7640 7640-7640 4230 0 7650 3420 7650 7640zM107040 76460c0 4220-3420 7640-7650 7640-4220 0-7640-3420-7640-7640s3420-7650 7640-7650c4230 0 7650 3430 7650 7650zM129980 76460c0 4220-3420 7640-7650 7640-4220 0-7640-3420-7640-7640s3420-7650 7640-7650c4230 0 7650 3430 7650 7650zM107040 122330c0 4230-3420 7650-7650 7650-4220 0-7640-3420-7640-7650 0-4220 3420-7640 7640-7640 4230 0 7650 3420 7650 7640z"
          className="fil1"
        />
        <path
          d="M107040 137620H15290v-30580h137630V15290c0-8430-6860-15290-15300-15290H15290C6860 0 0 6860 0 15290v122330c0 8430 6860 15300 15290 15300h91750v-15300zM15290 15290h122330v30590H15290V15290zm0 45880h122330l-10 30580H15290V61170zM183500 145270h-22940v-22940h-15290v22940h-22940v15290h22940v22940h15290v-22940h22940z"
          className="fil1"
        />
      </g>
    </g>
  </svg>
)
export default AddNew
