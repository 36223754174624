import React, { useState } from 'react'
import useGetPubsTypes from '../../../hooks/useGetPubsTypes'
import { Dropdown, Spin } from 'antd'
import { ButtonLg } from '../../../components/shared/Buttons/ButtonLg'
import TypesDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/TypesDropdown'

export default function Types() {
    const { loading, publicationsTypes } = useGetPubsTypes()
    const [selectedPubType, setselectedPubType] = useState<any>({value:'', icon: '', type: ''});
  return (
    <div className='h-max'>
        <Spin spinning={loading}>
            <Dropdown 
                    overlay={<TypesDropdown selectedType={selectedPubType} selectAction={node => setselectedPubType({ value: node.id, icon: node.iconUrl, type:'type', title: node.name_plural.en })} typesList={publicationsTypes}/>} trigger={['click']} disabled={loading}
                    onVisibleChange={visible => {}}
                    className='w-full'
                    >
                        {/* <Spin spinning={loading}> */}
                        <button>
                            <ButtonLg
                                suffix={{
                                    icon:{type:'icon', src: selectedPubType?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                                    size: 'mini',
                                    onClick:() => selectedPubType.value ? setselectedPubType({value:'', icon: '', type:'type', title: ''}) : {}
                                }}
                                prefix={{
                                    icon:selectedPubType?.value ? {type:'image', src: selectedPubType?.icon} : {type:'icon', src: 'FilterIcon'},
                                    size: 'sm'
                                }}
                                buttonProps={{
                                    disabled:loading,
                                    className:'border flex px-4 py-2 rounded space-x-3 items-center w-full'

                                }}
                            >{selectedPubType?.value ? selectedPubType.title : <p className='italic'>All types</p>}</ButtonLg>

                        </button>
                    

                    </Dropdown>
                    
        </Spin>

    </div>
  )
}
