import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.99 21.99" {...props}>
    <g data-name="Calque 2">
      <path
        d="M17.85 11.75 10 19.64a4.61 4.61 0 0 1-6.52-6.53L14.65 1.9A3.08 3.08 0 0 1 19 6.25L7.78 17.47m0 0h0m5.84-10.19-8 8a1.54 1.54 0 0 0 2.17 2.18"
        data-name="Calque 1"
        style={{
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.7px",
        }}
      />
    </g>
  </svg>
)
export default SvgComponent
