
type stats = {
    volumes:number,
}
export default function StatsBar({volumes}:stats) {
  return (
    <div className='border-t border-main bg-topbar bg-black text-white'>
       <div className="pl-5 flex space-x-3 items-center px-2 bar-h-mini ">
            <p className="text-xs pr-2  py-0 cursor-context-menu">{volumes} Volumes</p>
        </div>

    </div>
  )
}
