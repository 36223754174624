import { IconOptions } from "../../../types"

export default function ViewAsGridIcon({ className }:IconOptions) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        fill="currentColor"
        className={className}
      >
        <path
          fillRule="evenodd"
          d="M16.8,21.6H4.8A4.8,4.8,0,0,1,0,16.8V4.8A4.8,4.8,0,0,1,4.8,0h12a4.8,4.8,0,0,1,4.8,4.8v12a4.8,4.8,0,0,1-4.8,4.8ZM4.8,4.8h0v12h12V4.8Z"
        />
        <path
          fillRule="evenodd"
          d="M43.2,21.6h-12a4.8,4.8,0,0,1-4.8-4.8V4.8A4.8,4.8,0,0,1,31.2,0h12A4.8,4.8,0,0,1,48,4.8v12a4.8,4.8,0,0,1-4.8,4.8ZM31.2,4.8h0v12h12V4.8Z"
        />
        <path
          fillRule="evenodd"
          d="M16.8,48H4.8A4.8,4.8,0,0,1,0,43.2v-12a4.8,4.8,0,0,1,4.8-4.8h12a4.8,4.8,0,0,1,4.8,4.8v12A4.8,4.8,0,0,1,16.8,48ZM4.8,31.2h0v12h12v-12Z"
        />
        <path
          fillRule="evenodd"
          d="M43.2,48h-12a4.8,4.8,0,0,1-4.8-4.8v-12a4.8,4.8,0,0,1,4.8-4.8h12A4.8,4.8,0,0,1,48,31.2v12A4.8,4.8,0,0,1,43.2,48Zm-12-16.8h0v12h12v-12Z"
        />
      </svg>
    );
  }
  