import React from 'react'
import WebResourcesGroup from './grouped/webResources'
import { useSelectionHandlers } from '../../../hooks/useSelectionHandler'
import { Highlight, Publication } from '../../../../types'
import Publications from '../../../../components/shared/publications'
import { displayModes } from '../../../context/UIContext'

type Props = {
    suffix: string,
    webResources:{document:Publication, highlight:Highlight}[],
    groupBy: {webresources: boolean, paidPubs: boolean} | undefined,
    searchon:boolean,
    totalHits: { pubs: number | null, webresources:number | null} ,
    barActivatedFields: { author:boolean, date:boolean},
    displayMode: displayModes,
    setRecheckPin: () => void

}
const WebResources = React.memo(({ groupBy, searchon, totalHits, webResources, barActivatedFields, displayMode, setRecheckPin, suffix} : Props) => {
    const { handleSelectPublication, handleSelectGroupedWebresources} = useSelectionHandlers(suffix)
    
  return (
    <div className={`space-y-2 ${!(groupBy?.paidPubs || !groupBy?.webresources || !searchon) && 'mt-10'}`}>
    {(groupBy?.paidPubs || !groupBy?.webresources || !searchon) ? <></>:<p className="text-groupe">{searchon ? ` ${totalHits.webresources} webpage found` : 'Latest web resources'}</p> }
     <div className='-ml-[20px]'>
       {
         groupBy?.webresources ? (
           <>
           {!groupBy.paidPubs && (

           <WebResourcesGroup searchOn={searchon} handleSelectGroupedWebresources={handleSelectGroupedWebresources} publications={webResources} handlePublicationSelection={(item, type?:'website') => handleSelectPublication(item, type ??'webresource')} author={false}/> 
           )}
           </>

         ): (
           <div className='pl-[15px]'>

             <Publications barActivatedFields={barActivatedFields}  publications={webResources} handlePublicationSelection={(item) => handleSelectPublication(item, 'webresource')} displayMode={displayMode} setRecheckPin={setRecheckPin}/>
           </div>
         )
       }

     </div>
   </div>
  )
}
)
export default WebResources