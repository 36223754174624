import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

type Props ={
    initialValue: string,
    editorRef: any,
    disabled?:boolean,
    onChange:(e:string) => void
}
export default function RichText({initialValue,editorRef, disabled, onChange}: Props) {
 
 
  return (
   
    <>
      <Editor
          disabled={disabled ? disabled : false}
          apiKey='rjzld716ffyoisfn1qwat6okgz74a1x7616oox72n0k2dlae'
          onInit={(evt, editor) => editorRef.current = editor}
          value={initialValue}
          onEditorChange={e => onChange(e)}
          
          init={{
          height: 250,
          menubar: false,
          plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
          ],
          toolbar: 'undo redo | ' +
              'bold italic ' +
              '| bullist numlist | ',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          file_picker_types: 'file image media',
          directionality : "ltr"
          }}
      />

      

    </>

        

  );
}