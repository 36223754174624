import React, { useState } from 'react'
import NewDomains from './NewDomains';
import PopularDomains from './PopularDomains';
import Search from './Search';
import AllDomains from './all';
import { ChevronLeftIcon } from '@heroicons/react/solid';

export const heightWindow = 'h-[calc(800px-114px)]'
export default function Domains() {
    const [searchValue, setsearchValue] = useState('');
    const [allDomains, setallDomains] = useState(false);

    const openDomain = (name:string) => {
        window.open(`https://${name.toUpperCase().split(' ').join('-')}.qorddu.com`, '_blank')
    }
  return (
    <div className='w-full'>
        <div className="px-7 flex items-center justify-between w-full py-3.5 border-b border-main">
            {allDomains ? (
                <button className="flex space-x-2 items-center" onClick={() => setallDomains(false)}>
                    <ChevronLeftIcon className='icon-sm'/>
                    <p className='text-[20px] font-bold'>All domains</p>
                </button>
            ) : (
                <p className='text-[20px] font-bold'>Navigate in Qorddu</p>

            )}
            <input value={searchValue} type="text" className='w-full max-w-[200px] rounded' placeholder='Search a domain' onChange={e => setsearchValue(e.target.value)}/>
        </div>
        <div className="space-y-7 mt-7 px-7">
            {searchValue ? (
                <Search openDomain={openDomain} value={searchValue}/>
            ) : (
                
                <>
                    {allDomains ? (
                        <AllDomains openDomain={openDomain}/>
                    ) : (
                        <div className={`space-y-7 overflow-auto ${heightWindow}`}>
                            <div className='space-y-3'>
                                <p className='text-groupe'>New domains</p>
                                <NewDomains openDomain={openDomain}/>
                            </div>
                            <div className='space-y-3'>
                                <p className='text-groupe'>Popular domains</p>
                                <PopularDomains openDomain={openDomain}/>
                            </div>
                            <div>
                                <button className='px-4 py-1.5 bg-skin-fill-inverted text-skin-inverted rounded' onClick={() => setallDomains(true)}>See all domains</button>
                            </div>
                            

                        </div>
                    )}
                </>
            )}

        </div>
    </div>
  )
}
