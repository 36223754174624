import * as React from "react"
import { IconOptions } from "../../../types"
const Install = (props:IconOptions) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"

    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 1790 1790"
    {...props}
  >
    <defs>
      <style>{".fil12{fill-rule:nonzero}"}</style>
    </defs>
    <g id="Plan_x0020_1">
      <g id="_2558002089632">
        <path
          d="M1790 1790H0V0h1790z"
          style={{
            fill: "none",
            fillRule: "nonzero",
          }}
        />
        <path
          d="M1490 1050H300c-80 0-150 60-150 150v290c0 90 70 150 150 150h1190c90 0 150-60 150-150v-290c0-90-60-150-150-150zm0 440H300v-290h1190v290zM1250 580l-110-110-170 170V150H820v490L650 470 540 580l360 350z"
          className="fil12"
        />
        <path
          d="M1120 1420c40 0 80-30 80-80 0-40-40-70-80-70s-70 30-70 70c0 50 30 80 70 80zM1340 1420c50 0 80-30 80-80 0-40-30-70-80-70-40 0-70 30-70 70 0 50 30 80 70 80z"
          className="fil12"
        />
      </g>
    </g>
  </svg>
)
export default Install
